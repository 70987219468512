import React, { useRef } from "react";
import { useWindowVirtualizer } from "@tanstack/react-virtual";
import { Table } from "reactstrap";
import { ProjectListTableRowSkeleton } from "objs/_ConfiguratorObjs/ProjectList/SubComponents/ProjectListTable/ProjectListTableRowSkeleton";

export const VirtualizedTable = ({
  columns,
  list,
  renderRow,
  renderNoData,
  isLoading,
  skeletonCount,
  rowHeight = 68,
  overscan = 7,
  accessId,
}) => {
  const listRef = useRef();

  const rowVirtualizer = useWindowVirtualizer({
    count: !isLoading ? list.length : skeletonCount,
    scrollMargin: listRef.current?.offsetTop ?? 0,
    estimateSize: () => rowHeight,
    overscan,
  });

  return (
    <Table className="mb-4">
      <thead>
        <tr>
          {columns.map((column, index) => (
            <th
              key={index}
              style={{ width: column.width }}
              data-testid={column.dataTestid}
            >
              {column.label}
            </th>
          ))}
        </tr>
      </thead>
      <tbody
        ref={listRef}
        style={{
          height: `${rowVirtualizer.getTotalSize()}px`,
          width: "100%",
          position: "relative",
        }}
      >
        {rowVirtualizer.getVirtualItems().length > 0
          ? rowVirtualizer.getVirtualItems().map((virtualItem, index) => (
              <tr
                key={
                  accessId && list[virtualItem.index]
                    ? accessId(list[virtualItem.index])
                    : index
                }
                style={{
                  position: "absolute",
                  display: "flex",
                  width: "100%",
                  height: `${rowHeight}px`,
                  transform: `translateY(${
                    virtualItem.start - rowVirtualizer.options.scrollMargin
                  }px)`,
                }}
              >
                {isLoading ? (
                  <ProjectListTableRowSkeleton />
                ) : (
                  renderRow(list[virtualItem.index])
                )}
              </tr>
            ))
          : renderNoData()}
      </tbody>
    </Table>
  );
};
