import React, { useState } from "react";
import * as Scrivito from "scrivito";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import NiceModal from "@ebay/nice-modal-react";
import cx from "classnames";
import { translate } from "utils";
import { items } from "variables";
import {
  useFetchScrivitoDocumentsForProduct,
  useUpdatedItemsForProductDownload,
} from "hooks";
import { ProductReportsDownloadButtonsBlock } from "../../ProductReportsDownloadButtonsBlock";
import { ModalCheckboxesBlock } from "./ModalCheckboxesBlock";
import s from "./ProjectDownloadModal.module.scss";

export const SingleProductDownloadModal = NiceModal.create(
  Scrivito.connect(({ modalSubtitle, orderCode, presentation }) => {
    const modal = NiceModal.useModal();
    if (!orderCode) {
      return null;
    }

    const { documents } = useFetchScrivitoDocumentsForProduct({
      seriesId: orderCode?.seriesId,
    });

    const [itemsData, setItemsData] = useState(items);

    useUpdatedItemsForProductDownload({
      documents,
      presentation,
      setItemsData,
    });

    return (
      <Modal
        isOpen={modal.visible}
        centered={true}
        size="xl"
        toggle={modal.remove}
        scrollable={true}
      >
        <ModalHeader
          toggle={modal.remove}
          tag="div"
          className="d-flex align-items-start pb-0"
        >
          <h3 className="text-uppercase my-xxl-1 lh-32">
            {translate("DOWNLOAD_OPTIONS")}
          </h3>
          <h4 className="mb-0 lh-24">{modalSubtitle}</h4>
        </ModalHeader>
        <ModalBody
          className={cx("d-flex row pb-8 pt-xs-6 ", s.modalBodyScrollbar)}
        >
          <ModalCheckboxesBlock
            itemsData={itemsData}
            setItemsData={setItemsData}
            blockTitle=""
            showProducts={false}
            seriesId={orderCode?.seriesId.toString()}
          />
        </ModalBody>
        <ModalFooter className="pt-0">
          <ProductReportsDownloadButtonsBlock
            itemsData={itemsData}
            setItemsData={setItemsData}
            documents={documents}
            orderCode={orderCode}
          />
        </ModalFooter>
      </Modal>
    );
  }),
);
