import * as Scrivito from "scrivito";
import cx from "classnames";
import React, { useCallback, useState } from "react";
import { Icon } from "components/Icon/Icon";
import { translate } from "utils";
import { useWizardContext } from "providers";
import s from "./LeftSidebar.module.scss";

export const SidebarToggle = Scrivito.connect(
  ({
    setIsOpened,
    isOpened,
    isFirstSidebar,
    text,
    onLeft = false,
    color = "primary",
  }) => {
    const { firstToggleButton, secondToggleButton } = useWizardContext();
    const [offsetHeight, setOffsetHeight] = useState(0);

    const handleToggle = () => {
      setIsOpened(!isOpened);
      handleButtonPosition(!isOpened);
    };

    const handleButtonPosition = useCallback(
      (isSidebarOpened) => {
        if (!isFirstSidebar && firstToggleButton?.current && !isSidebarOpened) {
          setOffsetHeight(firstToggleButton.current.offsetHeight);
        } else {
          setOffsetHeight(0);
        }
      },
      [firstToggleButton, isFirstSidebar],
    );

    return (
      <div
        className={cx(s.toggleBtnContainer, {
          [s.toggleBtnContainerLeft]: onLeft,
          [s.toggleBtnContainerRight]: !onLeft,
          "bg-blue-300": color === "primary",
          "bg-gray-700": color === "secondary",
        })}
      >
        <div
          className={cx(
            "position-absolute d-flex px-2 py-3 text-white cursor-pointer gap-1",
            s.toggleBtn,
            {
              [s.toggleBtnLeft]: onLeft,
              [s.toggleBtnRight]: !onLeft,
              "bg-blue-300": color === "primary",
              "bg-gray-700": color === "secondary",
            },
          )}
          style={{ top: offsetHeight }}
          ref={isFirstSidebar ? firstToggleButton : secondToggleButton}
          onClick={handleToggle}
        >
          <Icon
            prefix="trox"
            iconName={
              isOpened ? "chevron-left-regular" : "chevron-right-regular"
            }
          />
          <div className={cx("fw-semibold user-select-none", s.toggleBtnText)}>
            {translate(text)}
          </div>
        </div>
      </div>
    );
  },
);
