import { translate } from "./localsUtils";

export const validateCopiedItem = (item, min = 1) => {
  const response = {
    isValid: false,
    message: "",
  };
  if (item === null || item === undefined) {
    response.message = "Item is null or undefined";
    return response;
  }
  if (item.isConfiguration && isNaN(item.quantity)) {
    response.message = "Quantity is not a number";
    return response;
  }
  if (item.isConfiguration && item.quantity < min) {
    response.message = translate("MIN_QUANTITY_WARNING");
    return response;
  }
  response.isValid = true;
  return response;
};
