import React, { useMemo } from "react";
import cx from "classnames";
import {
  FormFeedback,
  FormGroup,
  Input,
  Label,
  UncontrolledTooltip,
} from "reactstrap";
import { TranslateWrapper } from "components/TranslateWrapper";
import { Icon } from "components/Icon/Icon";

export const TextInput = ({
  field,
  form,
  wrapperClassName,
  label,
  tooltipText,
  type,
  maxLength,
  ...restProps
}) => {
  const meta = form.getFieldMeta(field.name);

  const isInvalid = useMemo(
    () => meta.touched && !!meta.error,
    [meta.error, meta.touched],
  );

  const isTextArea = useMemo(() => type === "textarea", [type]);

  const fieldLengthText = useMemo(
    () => isTextArea && (field.value?.length || "0"),
    [isTextArea, field],
  );

  return (
    <FormGroup className={wrapperClassName}>
      {label && (
        <Label
          className="label-small fw-bolder d-flex align-items-center justify-content-between mt-1 text-gray-700"
          for={field.name}
        >
          <div className="d-flex align-items-center">
            {label}
            {tooltipText && (
              <>
                <Icon
                  className="ms-2 text-gray-600 cursor-pointer"
                  id={field.name}
                  tabIndex={-1}
                  iconName="circle-info"
                  prefix="far"
                />
                <UncontrolledTooltip target={field.name} placement="top">
                  {tooltipText}
                </UncontrolledTooltip>
                {typeof tooltipText === "object" && (
                  <TranslateWrapper
                    tooltipId={field.name}
                    tooltipText={tooltipText.props.tooltipText}
                  />
                )}
              </>
            )}
          </div>
        </Label>
      )}
      {field && (
        <div className="d-flex position-relative h-100">
          <Input
            {...field}
            invalid={isInvalid}
            type={type}
            maxLength={maxLength}
            value={field.value || ""}
            {...restProps}
          />
          {isTextArea && (
            <div className="text-gray-400 align-self-end mb-1 me-2 position-absolute end-0 bottom-0">
              <span className="fw-bolder">{fieldLengthText}</span>
              <span> / {maxLength}</span>
            </div>
          )}
        </div>
      )}
      <FormFeedback className={cx("mt-2", { "d-flex": isInvalid })}>
        {meta.error}
      </FormFeedback>
    </FormGroup>
  );
};
