import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import cx from "classnames";
import {
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledTooltip,
} from "reactstrap";
import NiceModal from "@ebay/nice-modal-react";
import html2canvas from "html2canvas";
import { translate } from "utils";
import { toast } from "react-toastify";
import { pdf } from "@react-pdf/renderer";
import * as Scrivito from "scrivito";
import { svgTopology } from "components/_Modals/CreatePDFModal/PreviewTopology/svgTopology";
import { ZonesPagination } from "components/_Modals/CreatePDFModal/ZonesPagination";
import { Button } from "../../Button";
import { Icon } from "../../Icon/Icon";
import { PreviewTopology } from "./PreviewTopology/PreviewTopology";
import { TemplatePDF } from "./PreviewTopology/TemplatePDF";

import s from "./CreatePDFModal.module.scss";

export const CreatePDFModal = NiceModal.create(
  Scrivito.connect(({ wizardTitle, structuredTopology }) => {
    const modal = NiceModal.useModal();

    const [selectedZoneIdx, setSelectedZoneIdx] = useState(0);
    const [zonesRangeStr, setZonesRangeStr] = useState("");
    const [zonesRangeArr, setZonesRangeArr] = useState([]);

    const zones = useMemo(
      () =>
        structuredTopology?.bottomPlacedChildren[0]
          .connectedByTopBracketChildren || [],
      [structuredTopology?.bottomPlacedChildren],
    );

    const [formData, setFormData] = useState({
      type: "PDF",
      name: wizardTitle,
      viewType: "whole", // types: whole, zonewise, zonesRange, screenshot
    });

    const targetRef = useRef(null);

    const downloadLinkPDF = (url, filename) => {
      const link = window.document.createElement("a");
      link.style = "display:none;";
      link.download = filename;
      link.href = url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      link.remove();
    };

    const shadowSVG = (structuredData, chosenZone = null) => {
      const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
      svg.setAttribute("xmlns", "http://www.w3.org/2000/svg");
      svg.setAttribute("version", "1.1");
      svg.setAttribute("data-zone", chosenZone);
      svg.style.position = "absolute";
      svg.zIndex = "-1";
      svgTopology(svg, structuredData, chosenZone);
      document.body.appendChild(svg);

      return svg;
    };

    const downloadSVG = () => {
      const svg = shadowSVG(structuredTopology, null);
      const htmlStr = svg.outerHTML;
      const blob = new Blob([htmlStr], { type: "image/svg+xml" });
      const url = URL.createObjectURL(blob);
      const link = window.document.createElement("a");
      link.style = "display:none;";
      link.download = formData.name;
      link.href = url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      link.remove();
      URL.revokeObjectURL(url);
      svg.remove();
    };

    const zonesRangeFilterData = useCallback(
      (str = "") => {
        const arrayRange = (start, end) =>
          Array.from({ length: end - start + 1 }, (_, index) => start + index);

        const compareNumbers = (a, b) => a - b;
        const trimmedStr = str.replaceAll(" ", "");
        const regexpRange = /(\d+-\d*)/g;

        const zoneListSeparatedByDashArr = Array.from(
          trimmedStr.matchAll(regexpRange),
          (m) => {
            const matchedArr = m[0].split("-");
            return arrayRange(Number(matchedArr[0]), Number(matchedArr[1]));
          },
        )
          .flat()
          .filter((i) => i <= zones.length);

        const zoneListSeparatedByCommaArr =
          trimmedStr
            ?.split(",")
            .filter((n) => n > 0 && n <= zones.length)
            .map(Number) || null;

        const zoneRangeArr = zoneListSeparatedByDashArr.concat(
          zoneListSeparatedByCommaArr,
        );

        const unicSortedZonesArr = [0, ...new Set(zoneRangeArr)].sort(
          compareNumbers,
        );

        setZonesRangeArr(unicSortedZonesArr);
        setSelectedZoneIdx(0);
      },
      [zones.length],
    );

    useEffect(() => {
      zonesRangeFilterData(zonesRangeStr);
    }, [zonesRangeFilterData, zonesRangeStr]);

    const handleZoneChange = useCallback((value) => {
      setSelectedZoneIdx(value);
    }, []);

    const handleSetAllPages = useCallback(() => {
      setZonesRangeStr(`1-${zones.length}`);
    }, [zones.length]);

    const handleNameChange = useCallback(
      (e) => {
        setFormData({ ...formData, name: e.target.value });
      },
      [formData],
    );

    const handleZonesRangeChange = useCallback(
      (e) => {
        e.preventDefault();
        const inputValue = e.target.value;
        setZonesRangeStr(inputValue);

        if ((e.type === "keyup" && e.key === "Enter") || e.type === "blur") {
          setZonesRangeStr(inputValue || `1-${zones.length}`);
        }
      },
      [zones.length],
    );

    const handleChangeViewType = useCallback(
      ({ target }) => {
        setFormData({
          ...formData,
          viewType: target.value,
        });
        setZonesRangeStr(`1-${zones.length}`);
        zonesRangeFilterData(`1-${zones.length}`);
        targetRef.current.parentNode.style.transform =
          "translate(0, 0) scale(1)";
      },
      [formData, zones.length, zonesRangeFilterData],
    );

    const handleChangeType = useCallback(
      (e) => {
        setFormData({
          ...formData,
          viewType: "whole",
          type: e.target.value,
        });
        targetRef.current.parentNode.style.transform =
          "translate(0, 0) scale(1)";
      },
      [formData],
    );

    const convertSVGtoImg = async (chosenZone, structuredData) => {
      const loadImage = async (url) => {
        const img = document.createElement("img");
        img.src = url;
        return new Promise((resolve, reject) => {
          img.onload = () => resolve(img);
          img.onerror = reject;
        });
      };

      const serializeAsXML = (e) => new XMLSerializer().serializeToString(e);
      const dataHeader = "data:image/svg+xml;charset=utf-8";

      const svg = shadowSVG(structuredData, chosenZone);

      const width = svg.clientWidth;
      const height = svg.clientHeight;

      const encodeAsUTF8 = (xmlStr) =>
        `${dataHeader},${encodeURIComponent(xmlStr)}`;

      const svgData = encodeAsUTF8(serializeAsXML(svg));

      const img = await loadImage(svgData);

      const canvas = document.createElement("canvas");
      canvas.width = width;
      canvas.height = height;
      canvas
        .getContext("2d")
        .drawImage(img, 0, 0, svg.clientWidth, svg.clientHeight);
      const finalImg = canvas
        .toDataURL("image/png", 1.0)
        .replace("image/png", "image/octet-stream");
      svg.remove();

      return {
        width,
        height,
        img: finalImg,
      };
    };

    const downloadPDF = async () => {
      try {
        toast.loading(translate("IS_LOADING_TEXT"));
        const topologyImg = await convertSVGtoImg(null, structuredTopology);

        let zonesImgsArr = [];

        switch (formData.viewType) {
          case "whole":
            break;
          case "zonesRange":
            zonesImgsArr = await Promise.all(
              zonesRangeArr
                .filter((i) => i !== 0)
                .map((zoneNum) => convertSVGtoImg(zoneNum, structuredTopology)),
            );
            break;
          case "screenshot": {
            targetRef.current.parentNode.parentNode.overflow = "hidden";
            // TODO: It might be sense remove html2canvas lib and implement it as convertSVGtoImg + calc coordinates canvas
            const canvas = await html2canvas(
              targetRef.current.parentNode.parentNode,
            );
            const screenshot = canvas
              .toDataURL("image/png", 1.0)
              .replace("image/png", "image/octet-stream");
            zonesImgsArr.push({ width: 1, height: 0, img: screenshot });
            break;
          }
          default: {
            break;
          }
        }

        const blob = await pdf(
          <TemplatePDF
            textConfigurator={translate("CONFIGURATOR")}
            title={wizardTitle}
            topologyImg={topologyImg}
            pages={zonesImgsArr}
          />,
        ).toBlob();
        const url = await URL.createObjectURL(blob);

        await downloadLinkPDF(url, formData.name);
      } catch (err) {
        toast.dismiss();
        toast.error(translate("ERROR_OCCURED"), {
          type: "error",
          theme: "colored",
        });
        console.error(err);
      } finally {
        toast.dismiss();
      }
    };

    return (
      <Modal
        isOpen={modal.visible}
        centered={true}
        size="xl"
        toggle={modal.remove}
        contentClassName={cx("h-auto", s.modal)}
      >
        <ModalHeader
          toggle={modal.remove}
          tag="div"
          className="d-flex align-items-start pb-0"
        >
          <h3 className="text-uppercase mb-1 lh-32">
            {translate("WIZARD_CREATE_PDF_MODAL_TITLE")}
          </h3>
          <h4 className="mb-0 lh-24">
            {translate("WIZARD_CREATE_PDF_MODAL_SUBTITLE")}
          </h4>
        </ModalHeader>
        <ModalBody className="d-flex pb-4 gap-4">
          <div className="d-flex flex-column w-50">
            <legend className="d-flex align-items-center mb-3 fs-md text-gray-700 fw-bold">
              {translate("WIZARD_CREATE_PDF_MODAL_PDF_TYPE")}
              <Icon
                className="ms-2 text-gray-600 cursor-pointer"
                tabIndex={-1}
                iconName="circle-info"
                prefix="far"
                id="type"
              />
              <UncontrolledTooltip target="type" placement="top">
                {translate("WIZARD_CREATE_PDF_MODAL_TOOLTIP_VERSION")}
              </UncontrolledTooltip>
            </legend>
            <div className="d-flex gap-4 mb-4">
              <div className="d-flex gap-2_5">
                <Input
                  name="type"
                  type="radio"
                  data-recording-disable={true}
                  value="PDF"
                  id="PDF"
                  htmlFor="PDF"
                  defaultChecked
                  data-testid="createPDFModal_type-pdf-radio"
                  onChange={handleChangeType}
                />
                <Label
                  className="d-flex align-items-center fs-md text-gray-700"
                  for="PDF"
                >
                  {translate("WIZARD_CREATE_PDF_MODAL_PDF_TYPE_PDF")}
                </Label>
              </div>
              <div className="d-flex gap-2_5">
                <Input
                  name="type"
                  type="radio"
                  data-recording-disable={true}
                  value="SVG"
                  id="SVG"
                  htmlFor="SVG"
                  data-testid="createPDFModal_type-svg-radio"
                  onChange={handleChangeType}
                />
                <Label
                  className="d-flex align-items-center fs-md text-gray-700"
                  for="SVG"
                >
                  {translate("WIZARD_CREATE_PDF_MODAL_PDF_TYPE_SVG")}
                </Label>
              </div>
            </div>
            <div className="mb-4 gap-2_5">
              <Label
                className="d-flex align-items-center fs-md text-gray-700 fw-bold"
                for="fileName"
              >
                {translate("WIZARD_CREATE_PDF_MODAL_PDF_NAME")}
                <Icon
                  className="ms-2 text-gray-600 cursor-pointer"
                  tabIndex={-1}
                  iconName="circle-info"
                  prefix="far"
                  id="fileName"
                />
                <UncontrolledTooltip target="fileName" placement="top">
                  {translate("WIZARD_CREATE_PDF_MODAL_TOOLTIP_NAME")}
                </UncontrolledTooltip>
              </Label>
              <Input
                name="name"
                type="text"
                autoFocus={true}
                data-recording-disable={true}
                data-testid="CreatePDFModal_pdf-name-input"
                value={formData.name}
                onChange={handleNameChange}
              />
            </div>
            {formData.type === "PDF" && (
              <div className="d-flex flex-column">
                <Label
                  className="d-flex align-items-center fs-md text-gray-700 fw-bold"
                  for="viewType"
                >
                  Topology view type
                </Label>
                <div className="d-flex gap-2_5">
                  <Input
                    name="viewType"
                    type="radio"
                    value="whole"
                    id="whole"
                    htmlFor="whole"
                    defaultChecked
                    onChange={handleChangeViewType}
                  />
                  <Label
                    className="d-flex align-items-center fs-md text-gray-700"
                    for="whole"
                  >
                    {translate(
                      "WIZARD_CREATE_PDF_MODAL_PDF_PRINT_ON_SINGLE_PAGE",
                    )}
                  </Label>
                </div>
                <div className="d-flex flex-column">
                  <div className="d-flex gap-2_5">
                    <Input
                      name="viewType"
                      type="radio"
                      value="zonesRange"
                      id="zonesRange"
                      htmlFor="zonesRange"
                      onChange={handleChangeViewType}
                    />
                    <Label
                      className="d-flex align-items-center fs-md text-gray-700"
                      for="zonesRange"
                    >
                      {translate(
                        "WIZARD_CREATE_PDF_MODAL_PDF_PRINT_ZONE_SELECTIONS",
                      )}
                    </Label>
                  </div>
                  {formData.viewType === "zonesRange" && (
                    <Input
                      className="mb-2_5"
                      name="zonesRange"
                      placeholder={translate(
                        "WIZARD_CREATE_PDF_MODAL_PDF_PRINT_ZONE_SELECTIONS_PLACEHOLDER",
                      )}
                      autoFocus={true}
                      value={zonesRangeStr}
                      onChange={handleZonesRangeChange}
                      onBlur={handleZonesRangeChange}
                      onKeyUp={handleZonesRangeChange}
                    />
                  )}
                </div>
                <div className="d-flex gap-2_5">
                  <Input
                    name="viewType"
                    type="radio"
                    value="screenshot"
                    id="screenshot"
                    htmlFor="screenshot"
                    onChange={handleChangeViewType}
                  />
                  <Label
                    className="d-flex align-items-center fs-md text-gray-700"
                    for="screenshot"
                  >
                    {translate("WIZARD_CREATE_PDF_MODAL_PDF_PRINT_SCREENSHOT")}
                  </Label>
                </div>
              </div>
            )}
          </div>
          <div className="d-flex flex-column w-50">
            <label className="d-flex align-items-center mb-3 fs-md text-gray-700 fw-bold">
              {translate("WIZARD_CREATE_PDF_MODAL_PREVIEW")}
            </label>
            <div className="border border-gray-400 mb-4">
              <div className={cx("position-relative", s.previewWindow)}>
                {structuredTopology && (
                  <PreviewTopology
                    innerRef={targetRef}
                    wizardTitle={wizardTitle}
                    structuredTopology={structuredTopology}
                    selectedZone={zonesRangeArr[selectedZoneIdx] || null}
                  />
                )}
              </div>
            </div>
            {formData.viewType === "zonesRange" && (
              <div className="d-flex justify-content-center align-items-center gap-2_5">
                <Button
                  color="primary"
                  size="sm"
                  outline={!!selectedZoneIdx}
                  onClick={handleSetAllPages}
                >
                  {translate("WIZARD_CREATE_PDF_MODAL_PDF_PRINT_ALL_ZONES")}
                </Button>
                <ZonesPagination
                  pagesAround={4}
                  pagesNumbersArr={zonesRangeArr}
                  currentPageIdx={selectedZoneIdx}
                  onChangePage={handleZoneChange}
                />
              </div>
            )}
          </div>
        </ModalBody>
        <ModalFooter className="pt-0">
          {formData.type === "SVG" ? (
            <Button
              id="pdfButton"
              type="submit"
              color="primary"
              className="d-inline-block text-white"
              data-testid="downloadModal_download_svg-btn"
              onClick={downloadSVG}
            >
              {translate("WIZARD_CREATE_PDF_MODAL_DOWNLOAD_SVG_BTN")}
            </Button>
          ) : (
            <Button
              id="pdfButton"
              type="submit"
              color="primary"
              className="d-inline-block text-white"
              data-testid="downloadModal_download_pdf-btn"
              onClick={downloadPDF}
            >
              {translate("WIZARD_CREATE_PDF_MODAL_EXPORT_BTN")}
            </Button>
          )}
        </ModalFooter>
      </Modal>
    );
  }),
);
