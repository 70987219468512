import React, { memo, useMemo } from "react";
import cx from "classnames";
import { Icon } from "components/Icon/Icon";
import s from "./Styles.module.scss";
import { WizardIndicator } from "./WizardIndicator";

export const NodeLayout = memo(
  ({
    children,
    isWizard,
    isPasteMode,
    options,
    hasChildren,
    isOpen,
    onClick,
    isConfiguration,
    isRootNode,
    isAncestorDragging,
    isDragging,
    willReceiveDrop,
    onDoubleClick,
    isDragEvent,
    isPasteAllowed,
    isDraggingConfig,
    isActiveProductNode,
  }) => {
    const isBlurred =
      (isAncestorDragging ||
        (isPasteMode && (isWizard || isConfiguration)) ||
        (isDragEvent &&
          (isWizard || isConfiguration || (isDraggingConfig && isRootNode))) ||
        (!isPasteAllowed && isPasteMode)) &&
      !(isConfiguration && options.productAddingModal && !isWizard);
    const isActionAllowed = (!isBlurred && isPasteMode) || !isPasteMode;

    const dataTestID = useMemo(() => {
      const nodeProps = children.props.node.data;
      if (isConfiguration) {
        return `product-${nodeProps.foldedOrderCode}`;
      }
      return isWizard
        ? `wizard-${nodeProps.title.replaceAll(" ", "")}`
        : `folder-${nodeProps.title.replaceAll(" ", "")}`;
    }, [children.props.node.data, isConfiguration, isWizard]);

    return (
      <div
        className="w-100 h-100 py-2 position-relative text-gray-700"
        onClick={onClick}
        onDoubleClick={onDoubleClick}
        data-testid={`ProjectTree_${dataTestID}`}
      >
        <div
          className={cx(
            "w-100 d-flex flex-row justify-content-between align-items-center rounded-2 border h-100 overflow-hidden",
            {
              [s.folder]: !isConfiguration,
              [s.configuration]: isConfiguration,
              "border-info":
                willReceiveDrop && !isDragging && !isAncestorDragging,
              "opacity-50": isBlurred,
              "opacity-25": isDragging,
              [s.pastePreview]: !isBlurred && isPasteMode && isPasteAllowed,
              "cursor-pointer": isActionAllowed,
              "cursor-not-allowed": !isActionAllowed,
              "border-info bg-blue-50": isActiveProductNode,
            },
          )}
        >
          <LeftSideComponents
            isWizard={isWizard}
            isConfiguration={isConfiguration}
            isRootNode={isRootNode}
            options={options}
          />
          <div
            className={cx(
              "d-flex flex-row justify-content-between align-items-center pe-4 w-100 h-100",
              {
                "ps-1": isRootNode || (options.dndDisabled && !isWizard),
              },
            )}
          >
            {children}
          </div>
        </div>
        <BottomSpacer
          options={options}
          hasChildren={hasChildren}
          isOpen={isOpen}
        />
      </div>
    );
  },
);

const LeftSideComponents = memo(
  ({ isWizard, isConfiguration, isRootNode, options }) => (
    <>
      {isWizard ? (
        <div
          className={cx("h-100", {
            "pe-2": isConfiguration,
          })}
        >
          <WizardIndicator />
        </div>
      ) : (
        !isRootNode &&
        !options.dndDisabled && (
          <div
            className={cx("", {
              "ps-1": !isConfiguration,
              "ps-3 pe-2": isConfiguration,
            })}
          >
            <Icon
              iconName="grip-dots-vertical"
              className={cx("icon-12 cursor-pointer", {
                "text-gray-400": isConfiguration,
                "text-gray-700": !isConfiguration,
              })}
            />
          </div>
        )
      )}
    </>
  ),
);

const BottomSpacer = memo(({ options, hasChildren, isOpen }) => (
  <>
    {options.isIndentCompact ? (
      <CompactSpacer
        width={options.indentSize}
        hasChildren={hasChildren}
        isOpen={isOpen}
      />
    ) : (
      <Spacer
        width={options.indentSize}
        hasChildren={hasChildren}
        isOpen={isOpen}
      />
    )}
  </>
));

const Spacer = ({ width, hasChildren, isOpen }) => (
  <div className={cx("w-100 d-flex flex-row", s.bottomSpacer)}>
    <div className={cx("h-100 d-flex flex-row")} style={{ width }}>
      <div className="w-50 h-100"></div>
      <div
        className={cx("w-50 h-100 border-gray-400", {
          "border-start": hasChildren && isOpen,
        })}
      ></div>
    </div>
    <div className="h-100 flex-grow-1"></div>
  </div>
);

const CompactSpacer = ({ width, hasChildren, isOpen }) => (
  <div className={cx("w-100 d-flex flex-row", s.bottomSpacer)}>
    <div
      className={cx("w-100 h-100 border-gray-400", {
        "border-start": hasChildren && isOpen,
      })}
      style={{ width }}
    ></div>
  </div>
);
