import React, { createContext, useContext, useState, useCallback } from "react";
import { useMutation } from "urql";
import NiceModal from "@ebay/nice-modal-react";
import { translate } from "utils";
import { ConfigUpdateMut } from "api/mutations";

const ConfiguratorPopUpContext = createContext({});

export const ConfiguratorPopupProvider = ({ children }) => {
  const [params, setParams] = useState(null);
  const [isUpdateButtonActive, setIsUpdateButtonActive] = useState(false);
  const [isResetButtonActive, setIsResetButtonActive] = useState(false);
  const [, exeEditProduct] = useMutation(ConfigUpdateMut);
  const [navFuncState, setNavFunc] = useState(null);
  const [disableExit, setDisableExit] = useState(false);

  const handleUpdate = useCallback(() => {
    exeEditProduct({
      ...params,
    }).then(() => navFuncState());
  }, [exeEditProduct, params, navFuncState]);

  const showConfiguratorPopup = useCallback((navFunc) => {
    setNavFunc(() => navFunc); // save function argument reference
    NiceModal.show("confirmation", {
      iconName: "save-regular",
      title: translate("TEXT_SAVE_CHANGES"),
      alertText: translate("SAVE_PRODUCT_DATA_CONFIRMATION_ALERT"),
      submitBtnText: translate("SAVE"),
      onCancel: () => navFunc(),
    }).finally(() => {
      setIsUpdateButtonActive(false);
      setParams(null);
      setNavFunc(null);
      setDisableExit(false);
    });
  }, []);

  const context = {
    setIsUpdateButtonActive,
    setParams,
    isUpdateButtonActive,
    showConfiguratorPopup,
    isResetButtonActive,
    setIsResetButtonActive,
    disableExit,
    setDisableExit,
    navFuncState,
    handleUpdate,
  };

  return (
    <ConfiguratorPopUpContext.Provider value={context}>
      {children}
    </ConfiguratorPopUpContext.Provider>
  );
};
export const useConfiguratorPopupContext = () =>
  useContext(ConfiguratorPopUpContext);
