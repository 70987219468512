import React, { useState, useEffect, useCallback } from "react";
import * as Scrivito from "scrivito";
import { Button, Form, FormGroup, Input } from "reactstrap";
import { getPageLink, getHomepage, translate } from "utils";
import { TranslateWrapper } from "components/TranslateWrapper";
import { Icon } from "../../Icon/Icon";

export const SearchField = Scrivito.connect(() => {
  const [searchPage, setSearchPage] = useState(Scrivito.currentPage());
  const [searchInput, setSearchInput] = useState("");

  const handleInputChange = useCallback((e) => {
    setSearchInput(e.target.value);
  }, []);

  const placeHolder = translate("SEARCH_FIELD_PLACEHOLDER");
  const placeholderText =
    typeof placeHolder === "object" ? placeHolder.props.text : placeHolder;

  useEffect(() => {
    const fetchSearchPage = async () => {
      const res = await Scrivito.load(() => {
        const homepagePath = getHomepage().path();
        return (
          Scrivito.Obj.where("_path", "startsWith", homepagePath)
            .and("_objClass", "equals", "SearchPage")
            .take(1)[0] || Scrivito.currentPage()
        );
      });
      setSearchPage(res);
    };
    fetchSearchPage();
  }, []);

  const handleSubmit = (event, searchPageObj) => {
    event.preventDefault();

    const searchPageLink = getPageLink(searchPageObj);
    if (
      searchInput &&
      searchPageLink &&
      searchPageLink instanceof Scrivito.Link &&
      searchPageLink.isExternal()
    ) {
      const testLink = new Scrivito.Link({
        url: `${searchPageLink.url()}?q=${searchInput}`,
      });
      Scrivito.navigateTo(testLink);
    } else if (
      searchInput &&
      searchPageLink &&
      (searchPageLink instanceof Scrivito.Obj ||
        (searchPageLink instanceof Scrivito.Link &&
          searchPageLink.isInternal()))
    ) {
      Scrivito.navigateTo(
        new Scrivito.Link({
          obj: searchPageLink,
          query: `q=${searchInput}`,
        }),
      );
    }
  };
  return (
    <Form
      data-redirect-to-obj-type={searchPage.objClass?.()}
      data-testid="search-form"
      className="d-flex flex-nowrap w-100 search-form"
      onSubmit={(e) => handleSubmit(e, searchPage)}
    >
      <FormGroup className="d-flex justify-content-lg-end flex-nowrap w-100 mb-0 mb-lg-0">
        <Input
          data-testid="search-input"
          onChange={handleInputChange}
          className="search-input w-100 rounded-start"
          type="text"
          name="searchField"
          id="searchField"
          value={searchInput}
          placeholder={placeholderText}
        />
        <Button
          data-testid="SearchField_search-btn"
          outline
          color="transparent"
          className="search-button rounded-0 rounded-end d-flex align-items-center justify-content-center"
        >
          <Icon prefix="trox" iconName="magnifying-glass-regular" />
        </Button>
        {typeof placeHolder === "object" && (
          <TranslateWrapper
            tooltipText={placeHolder.props.tooltipText}
            tooltipId="searchField"
          />
        )}
      </FormGroup>
    </Form>
  );
});
