import React, { useMemo, useState } from "react";
import { nodeTypes } from "variables";
import cx from "classnames";
import {
  getIsAncestorDragging,
  getScrollToIndex,
} from "components/ProjectTree/utils";
import { toast } from "react-toastify";
import { Folder } from "./Folder/Folder";
import { useIndentComponent } from "./useIndentComponent";
import { NodeLayout } from "./NodeLayout";
import { Configuration } from "./Configuration/Configuration";

export const Node = ({
  node,
  tree,
  style,
  dragHandle,
  options,
  nodeActions,
  projectId,
  projectName,
  copiedItem,
  showProductPictures,
  translations,
  setRedrawIndex,
  setScrollToIndex,
  handleToggleNode,
  navigateToProduct,
  setCopiedItem,
  units,
  lang,
}) => {
  // Props
  const { data, rowIndex, children, isOpen, isDragging, willReceiveDrop } =
    node;
  const { kind, isWizard } = data;
  const isConfiguration = kind === nodeTypes.CONFIGURATION;
  const isRootNode = kind === nodeTypes.ROOT;

  // Hooks
  const [isEditMode, setIsEditMode] = useState(false);
  const indent = useIndentComponent(node, options);

  const isAncestorDragging = getIsAncestorDragging(node);

  const isPasteAllowed =
    copiedItem &&
    !(copiedItem.isConfiguration && isRootNode) &&
    !isWizard &&
    !isConfiguration;

  const handlePasteNode = () => {
    if (!isPasteAllowed) {
      return;
    }
    setRedrawIndex(rowIndex);
    nodeActions.pasteNode(node, (res) => {
      if (options.productAddingModal) {
        if (res?.error) {
          toast.error(res.error);
        } else {
          const {
            data: {
              addProjectNodeConfiguration: { productName },
            },
          } = res;
          toast.success(
            translations.product.productAddedSuccess({
              productName,
              projectName,
            }),
          );
        }
      }
    });
    if (options.resetOnPaste) {
      setCopiedItem(null);
    }
    if (!isOpen) {
      node.toggle();
      handleToggleNode(data.id);
    }
    const index = getScrollToIndex(node, copiedItem?.isConfiguration);
    setScrollToIndex(index);
  };

  const isDragEvent = tree.dragNodes && tree.dragNodes.length > 0;
  const isDraggingConfig =
    tree.dragNodes[0]?.data.kind === nodeTypes.CONFIGURATION;

  // Handlers
  const handleToggleEditMode = () => {
    if (isRootNode || isWizard) {
      return;
    }
    if (!isEditMode) {
      node.edit();
      setIsEditMode(true);
      return;
    }
    node.reset();
    setIsEditMode(false);
  };

  const onDoubleClick = () => {
    if (!isEditMode && isConfiguration && !isWizard && !copiedItem) {
      navigateToProduct(data);
    }
  };

  const productIdURL =
    new URL(window.location.href).searchParams.get("productId") || null;

  const isActiveProductNode = useMemo(
    () => node.data?.id === productIdURL,
    [node.data?.id, productIdURL],
  );

  return (
    <div
      ref={!isEditMode ? dragHandle : null}
      style={style}
      className={cx("w-100 h-100", {
        "pe-9": options.isFullpage,
        "pe-2": !options.isFullpage,
      })}
    >
      <div className="w-100 h-100 d-flex flex-row">
        {indent}
        <NodeLayout
          node={node}
          isWizard={isWizard}
          isPasteMode={!!copiedItem}
          options={options}
          hasChildren={children && children.length > 0}
          isOpen={isOpen}
          isConfiguration={isConfiguration}
          isRootNode={isRootNode}
          isAncestorDragging={isAncestorDragging}
          isDragging={isDragging}
          willReceiveDrop={willReceiveDrop}
          onDoubleClick={onDoubleClick}
          isDragEvent={isDragEvent}
          isDraggingConfig={isDraggingConfig}
          onClick={handlePasteNode}
          isPasteAllowed={isPasteAllowed}
          productIdURL={productIdURL}
          isActiveProductNode={isActiveProductNode}
        >
          {isConfiguration ? (
            <Configuration
              node={node}
              pimId={node.data.pimId}
              productName={node.data.productName}
              presentation={node.data.presentation}
              showProductPictures={showProductPictures}
              isWizard={isWizard}
              options={options}
              translations={translations}
              navigateToProduct={() => navigateToProduct(data)}
              nodeActions={nodeActions}
              data={node.data}
              handleToggleEditMode={handleToggleEditMode}
              setIsEditMode={setIsEditMode}
              isEditMode={isEditMode}
              copiedItem={copiedItem}
              units={units}
              lang={lang}
            />
          ) : (
            <Folder
              node={node}
              tree={tree}
              style={style}
              options={options}
              copiedItem={copiedItem}
              nodeActions={nodeActions}
              projectId={projectId}
              translations={translations}
              setRedrawIndex={setRedrawIndex}
              willReceiveDrop={willReceiveDrop}
              handleToggleNode={handleToggleNode}
              handleToggleEditMode={handleToggleEditMode}
              setIsEditMode={setIsEditMode}
              isEditMode={isEditMode}
              setScrollToIndex={setScrollToIndex}
            />
          )}
        </NodeLayout>
      </div>
    </div>
  );
};
