import { useCallback, useEffect, useState } from "react";

export const useOpenMap = (tree, isLoading, error) => {
  const [openMap, setOpenMap] = useState(null);

  const toggleNode = useCallback(
    (nodeId) => {
      if (!nodeId) {
        return;
      }
      if (openMap && nodeId in openMap) {
        setOpenMap((prev) => {
          const newOpenMap = { ...prev };
          newOpenMap[nodeId] = !newOpenMap[nodeId];
          return newOpenMap;
        });
        return;
      }
      if (openMap !== null) {
        setOpenMap((prev) => {
          const newOpenMap = { ...prev };
          newOpenMap[nodeId] = false;
          return newOpenMap;
        });
      }
    },
    [openMap, setOpenMap],
  );

  useEffect(() => {
    if (!openMap && tree && !isLoading && !error) {
      const map = {};

      const populateMap = (nodes) => {
        nodes.forEach((node) => {
          if (node.isWizard) {
            map[node.id] = false;
          }
          if (Array.isArray(node.nodes) && node.nodes.length > 0) {
            populateMap(node.nodes);
          }
        });
      };

      populateMap(tree);

      setOpenMap(map);
    }
  }, [tree, isLoading, openMap, error]);

  return { openMap, setOpenMap, toggleNode };
};
