import React, { useCallback, useEffect } from "react";
import * as Scrivito from "scrivito";
import { Alert, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import NiceModal from "@ebay/nice-modal-react";
import { useConfiguratorPopupContext } from "providers";
import { translate } from "utils";
import { useMutation } from "urql";
import cx from "classnames";
import { CheckboxInput } from "components/Inputs/FormikInputs/CheckboxInput";
import { sessionStorageDisabledConfirmationsKey } from "variables";
import { Spinner } from "../Spinner";
import { Button } from "../Button";
import { Icon } from "../Icon/Icon";
import { Marker } from "../Marker";
import s from "./ConfigurationModal.module.scss";

export const ConfirmationModal = NiceModal.create(
  Scrivito.connect(
    ({
      iconName = "trash-can-light",
      title,
      alertText,
      submitBtnText,
      mutation,
      params,
      onConfirm,
      displayList,
      onCancel,
      mutationContext,
      withDisableCheckbox,
      modalToDisable,
    }) => {
      const modal = NiceModal.useModal();

      const [checked, setChecked] = React.useState(false);

      const [{ data, fetching }, execute] = useMutation(mutation);
      const { disableExit, navFuncState, handleUpdate } =
        useConfiguratorPopupContext();
      useEffect(() => {
        if (data) {
          modal.resolve(data);
          modal.remove();
        }
      }, [data, modal]);

      const handleSubmit = useCallback(() => {
        if (checked) {
          const prevItems =
            JSON.parse(
              sessionStorage.getItem(sessionStorageDisabledConfirmationsKey),
            ) || [];

          sessionStorage.setItem(
            sessionStorageDisabledConfirmationsKey,
            JSON.stringify(
              prevItems ? [...prevItems, modalToDisable] : [modalToDisable],
            ),
          );
        }

        if (onConfirm) {
          onConfirm();
          modal.resolve();
          modal.remove();
          return;
        }
        if (mutationContext) {
          execute(params, mutationContext);
          modal.resolve();
          modal.remove();
          return;
        }

        if (navFuncState) {
          handleUpdate();
          modal.resolve();
          modal.remove();
          return;
        }
        execute(params);
      }, [
        modalToDisable,
        checked,
        onConfirm,
        mutationContext,
        execute,
        params,
        modal,
        navFuncState,
        handleUpdate,
      ]);

      const handleCancel = useCallback(() => {
        if (onCancel) {
          onCancel();
          modal.resolve();
          modal.remove();
        } else {
          modal.hide();
        }
      }, [onCancel, modal]);

      const handleCheckboxChanged = useCallback(() => {
        setChecked((val) => !val);
      }, []);

      return (
        <Modal
          isOpen={modal.visible}
          centered={true}
          size="md"
          toggle={modal.hide}
          className="confirmation-modal"
        >
          <ModalHeader toggle={modal.hide} tag="div" className="pb-0" />
          <ModalBody className="pt-2_5 d-flex row m-0">
            <div className="text-center pb-3">
              <Icon
                iconName={iconName}
                className="icon-48 text-gray-400"
                title={iconName}
                prefix="trox"
              />
              <h5 className="mb-0 pt-2 text-gray-700">{title}</h5>
            </div>
            <Alert
              color="danger"
              className="ml-4 mr-4 text-red overflow-hidden p-0 fs-md d-flex flex-nowrap"
            >
              <Marker color="red" />
              <div className="d-inline-block align-top py-2_5 ps-2_5 w-100">
                <div className="fw-bold mb-1">{translate("TEXT_WARNING")}</div>
                <div className="m-0 pe-2_5">{alertText}</div>
                {displayList && (
                  <ul className={s.scrollbar}>
                    {displayList.map((proj) => (
                      <li key={proj.id}>{proj.title}</li>
                    ))}
                  </ul>
                )}
              </div>
            </Alert>
          </ModalBody>
          <ModalFooter
            className={cx("pt-0", {
              "justify-content-between": withDisableCheckbox,
            })}
          >
            {withDisableCheckbox && (
              <CheckboxInput
                label={translate("DO_NOT_SHOW_WARNING")}
                type="checkbox"
                value={checked}
                onChange={handleCheckboxChanged}
              />
            )}
            <div className="d-flex flex-nowrap m-0">
              <Button
                className="btn-tertiary me-3"
                onClick={handleCancel}
                disabled={disableExit}
                data-testid="ConfirmationModal-cancel-btn"
              >
                {translate("CANCEL")}
              </Button>
              <Button
                className="d-flex align-items-center gap-2_5 btn-secondary"
                disabled={disableExit || fetching}
                data-testid="ConfirmationModal-submit-btn"
                onClick={handleSubmit}
              >
                {submitBtnText}
                {fetching && <Spinner size="xxs" />}
              </Button>
            </div>
          </ModalFooter>
        </Modal>
      );
    },
  ),
);

NiceModal.register("confirmation", ConfirmationModal);
