import React, { useCallback, useEffect, useMemo, useState } from "react";
import * as Scrivito from "scrivito";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useMutation } from "urql";
import NiceModal from "@ebay/nice-modal-react";
import cx from "classnames";

import {
  CreateProjectReportsMut,
  CreateTenderTextReportMut,
} from "api/mutations";
import { systemExportItems, wizardExportItems } from "variables";
import { translate } from "utils";
import { useAppBaseContext } from "providers";
import { ProgressBar } from "components/_Modals/ProjectDownloadModal/ProgressBar";
import { downloadFileByURL } from "utils/downloadFileByURL";
import { useSharedWorker } from "providers/SharedWorkerProvider/SharedWorkerContext";
import { ModalCheckboxesBlock } from "./ModalCheckboxesBlock";
import { Button } from "../../Button";
import { Icon } from "../../Icon/Icon";

import s from "./ProjectDownloadModal.module.scss";

const getProperty = (object, path) =>
  path.split(".").reduce((acc, key) => acc && acc[key], object);

export const ProjectOrFolderDownloadModal = NiceModal.create(
  Scrivito.connect(({ modalSubtitle, projectId, wizardId }) => {
    const modal = NiceModal.useModal();
    const { lang } = useAppBaseContext();
    const [systemExportData, setSystemExportData] = useState(
      wizardId ? wizardExportItems : systemExportItems,
    );
    const handleUpdateAll = useCallback((checked) => {
      setSystemExportData((prevData) =>
        prevData?.map((item) => ({
          ...item,
          checked,
        })),
      );
    }, []);

    const [{ fetching }, createProjectReport] = useMutation(
      CreateProjectReportsMut,
    );

    const [{ fetching: tenderTextFetching }, requestTenderTextReport] =
      useMutation(CreateTenderTextReportMut);

    const { startPolling, getMessageByNodeId, setActiveModal } =
      useSharedWorker();
    const requestInProgressPercentage = useMemo(() => {
      const message = getMessageByNodeId(wizardId);

      return message?.percentage || null;
    }, [getMessageByNodeId, wizardId]);

    const handleSelectAll = useCallback(
      () => handleUpdateAll(true),
      [handleUpdateAll],
    );

    const handleClearAll = useCallback(
      () => handleUpdateAll(false),
      [handleUpdateAll],
    );

    const checked = useMemo(
      () => systemExportData.filter((item) => item.checked),
      [systemExportData],
    );

    const getReportOptions = useCallback((id) => {
      if (id === 1) {
        return {
          urlPath: "createProjectReports.projectWithSpecText.url",
          targetField: "projectWithSpecText",
        };
      }
      if (id === 2) {
        return {
          urlPath: "createProjectReports.bomCompact.url",
          targetField: "bomCompact",
        };
      }
    }, []);

    // when AB provides single zip file for all reports we can get rid of await inside loop
    /* eslint-disable no-await-in-loop */
    const handleSubmit = useCallback(async () => {
      if (wizardId) {
        requestTenderTextReport({
          wizardNodeId: wizardId,
          language: lang,
        }).then((result) => {
          if (result?.data?.createTenderText?.requestId) {
            startPolling(wizardId, result?.data?.createTenderText.requestId, {
              modalSubtitle,
              projectId,
              wizardId,
              initialOperation: result.operation,
            });
          }
        });
      } else {
        const requestNeeded = systemExportData.filter((item) => item.checked);

        for (const { id } of requestNeeded) {
          const mutationParams = getReportOptions(id);

          const { data } = await createProjectReport({
            input: {
              projectId,
              languageCode: lang,
              [mutationParams.targetField]: { include: true },
            },
          });

          if (data) {
            const url = getProperty(data, mutationParams.urlPath);
            if (url) {
              await downloadFileByURL(url);
            }
          }
        }
      }
    }, [
      wizardId,
      requestTenderTextReport,
      lang,
      startPolling,
      modalSubtitle,
      projectId,
      systemExportData,
      getReportOptions,
      createProjectReport,
    ]);

    useEffect(() => {
      setActiveModal(modal.visible ? wizardId : null);
      return () => {
        setActiveModal(null);
      };
    }, [modal.visible, setActiveModal, wizardId]);

    return (
      <Modal
        isOpen={modal.visible}
        centered={true}
        size="xl"
        toggle={modal.remove}
        scrollable={true}
      >
        <ModalHeader
          toggle={modal.remove}
          tag="div"
          className="d-flex align-items-start pb-0"
        >
          <h3 className="text-uppercase my-xxl-1 lh-32">
            {translate("DOWNLOAD_OPTIONS")}
          </h3>
          <h4 className="mb-0 lh-24">{modalSubtitle}</h4>
        </ModalHeader>
        <ModalBody
          className={cx(
            "d-flex row pb-8 pt-xs-6 gap-xs-5_5 position-relative",
            s.modalBodyScrollbar,
          )}
        >
          {requestInProgressPercentage && (
            <ProgressBar percentage={requestInProgressPercentage} />
          )}
          <ModalCheckboxesBlock
            itemsData={systemExportData}
            setItemsData={setSystemExportData}
            blockTitle={translate("PROJECT_INFORMATION")}
            showProducts={false}
          />
        </ModalBody>
        <ModalFooter className="pt-0">
          <Button
            data-testid="DownloadModal_select-all-btn"
            onClick={handleSelectAll}
            className="me-3"
            color="secondary"
            outline
            disabled={checked.length === systemExportData.length}
          >
            {translate("SELECT_ALL")}
          </Button>
          <Button
            data-testid="DownloadModal_clear-selection-btn"
            onClick={handleClearAll}
            className="me-3"
            color="secondary"
            outline
            disabled={!checked.length}
          >
            {translate("CLEAR_SELECTION")}
          </Button>
          <Button
            data-testid="DownloadModal_download-btn"
            className="d-inline-block text-white"
            onClick={handleSubmit}
            color="primary"
            isLoading={fetching || tenderTextFetching}
            disabled={checked.length === 0 || !!requestInProgressPercentage}
          >
            <Icon
              prefix="trox"
              iconName="arrow-down-bracket-regular"
              className="me-1"
            />
            {translate("DOWNLOAD_DATA")}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }),
);
