import { gql } from "@urql/core";

export const createProductMut = gql`
  mutation (
    $seriesId: Int!
    $languageIso2L: String!
    $countryIso2L: String
    $requestUnitsSystem: String!
    $resultUnitsSystem: String!
    $state: productStateInput
    $wizardType: String
    $dummyType: String
    $specificationOnly: Boolean
    $specificationRequired: Boolean
    $replaceDisplayValue: Boolean
    $epfVersion: epfVersionInput
    $groupPosition: Int
  ) {
    createProduct(
      seriesId: $seriesId
      languageIso2L: $languageIso2L
      countryIso2L: $countryIso2L
      requestUnitsSystem: $requestUnitsSystem
      resultUnitsSystem: $resultUnitsSystem
      state: $state
      wizardType: $wizardType
      dummyType: $dummyType
      specificationOnly: $specificationOnly
      specificationRequired: $specificationRequired
      replaceDisplayValue: $replaceDisplayValue
      epfVersion: $epfVersion
      groupPosition: $groupPosition
    ) {
      result {
        product {
          id
          ordercode {
            parts {
              options {
                signatures {
                  description
                  value
                  range
                  isDefault
                  state
                  key
                  title
                  presentation
                }
                currentSignature {
                  description
                  value
                  range
                  isDefault
                  state
                  key
                  title
                  presentation
                }
                state
                range {
                  min
                  max
                }
                type
                separator
                key
                title
                presentation
              }
              state
              key
              title
              presentation
            }
            key
            title
            presentation
          }
          accessoryOrdercodes {
            seriesId
            seriesName
            currentPresentation
            ordercode {
              parts {
                options {
                  signatures {
                    description
                    value
                    range
                    isDefault
                    state
                    key
                    title
                    presentation
                  }
                  currentSignature {
                    description
                    value
                    range
                    isDefault
                    state
                    key
                    title
                    presentation
                  }
                  state
                  range {
                    min
                    max
                  }
                  type
                  separator
                  key
                  title
                  presentation
                }
                state
                key
                title
                presentation
              }
              key
              title
              presentation
            }
            accessoryId
            accessoryGroupId
            infoText
            optionStates {
              partKey
              optionKey
              signatureKey
              parentRef {
                partKey
                optionKey
              }
              isSizePart
              signatureValue
              signatureState
              separator
            }
            lastChange {
              partKey
              optionKey
              signatureKey
              parentRef {
                partKey
                optionKey
              }
              isSizePart
              signatureValue
              signatureState
              separator
            }
          }
          accessoryGroups {
            id
            name
            accessories {
              seriesId
              seriesName
              currentPresentation
              ordercode {
                parts {
                  options {
                    signatures {
                      description
                      value
                      range
                      isDefault
                      state
                      key
                      title
                      presentation
                    }
                    currentSignature {
                      description
                      value
                      range
                      isDefault
                      state
                      key
                      title
                      presentation
                    }
                    state
                    range {
                      min
                      max
                    }
                    type
                    separator
                    key
                    title
                    presentation
                  }
                  state
                  key
                  title
                  presentation
                }
                key
                title
                presentation
              }
              accessoryId
              accessoryGroupId
              infoText
              optionStates {
                partKey
                optionKey
                signatureKey
                parentRef {
                  partKey
                  optionKey
                }
                isSizePart
                signatureValue
                signatureState
                separator
              }
              lastChange {
                partKey
                optionKey
                signatureKey
                parentRef {
                  partKey
                  optionKey
                }
                isSizePart
                signatureValue
                signatureState
                separator
              }
            }
          }
          inputs {
            presentation
            properties {
              id
              symbol
              presentation
              description
              displayUnit
              displayValue
              unit
              precision
              value
              valueMin
              valueMax
              valueZeroAllowed
              valueMinInclusive
              valueMaxInclusive
              values
              validationType
              validationMessage
              bomRelevant
            }
          }
          outputs {
            presentation
            properties {
              id
              symbol
              presentation
              description
              displayUnit
              displayValue
              unit
              precision
              value
              valueMin
              valueMax
              valueZeroAllowed
              valueMinInclusive
              valueMaxInclusive
              values
              validationType
              validationMessage
              bomRelevant
            }
          }
          outputsAcoustic {
            presentation
            properties {
              id
              symbol
              presentation
              description
              displayUnit
              displayValue
              unit
              precision
              value
              valueMin
              valueMax
              valueZeroAllowed
              valueMinInclusive
              valueMaxInclusive
              values
              validationType
              validationMessage
              bomRelevant
            }
          }
          availableStrategies {
            id
            presentation
          }
          currentStrategyId
          productType
          pluginName
          serie
        }
        productImageList {
          productImages {
            pimId
            text
            isReportImage
            typ
          }
        }
        specificationText {
          text
        }
      }
      errorCode
      errorMessage
      epfVersion {
        major
        minor
        revision
        build
      }
    }
  }
`;

export const AddProductToNodeMut = gql`
  mutation (
    $nodeId: String!
    $quantity: Int!
    $pimId: String!
    $productName: String!
    $orderCode: OrderCodeInput!
    $presentation: String
  ) {
    addProjectNodeConfiguration(
      nodeId: $nodeId
      quantity: $quantity
      pimId: $pimId
      productName: $productName
      orderCode: $orderCode
      presentation: $presentation
    ) {
      id
      title
      kind
      quantity
      productId
      referencePosition
      nodeId
      localPath
      systemPath
      presentation
      productName
    }
  }
`;

export const createProductReport = gql`
  mutation (
    $languageIso2L: String!
    $version: String!
    $viewImages: [singleProductReportImage]
    $orderCode: singleProductReportOrderCode
    $inputs: ioReportClass
    $outputs: ioReportClass
    $notes: ioReportClass
    $productAcousticHeaders: acousticHeaderClass
    $acousticData: productAcousticDataList
    $descriptionText: descriptionClass
    $airflowPatternReport: airflowPatternSingleReportRequest
  ) {
    createProductReport(
      languageIso2L: $languageIso2L
      version: $version
      viewImages: $viewImages
      orderCode: $orderCode
      inputs: $inputs
      outputs: $outputs
      notes: $notes
      productAcousticHeaders: $productAcousticHeaders
      acousticData: $acousticData
      descriptionText: $descriptionText
      airflowPatternReport: $airflowPatternReport
    ) {
      base64Data
    }
  }
`;

export const createProductXBIMReport = gql`
  mutation (
    $systemOfUnits: String!
    $languageIso2L: String!
    $countryIso2L: String!
    $seriesId: Int!
    $state: productStateInput
    $epfVersion: epfVersionInput
  ) {
    createProductXBIMReport(
      systemOfUnits: $systemOfUnits
      languageIso2L: $languageIso2L
      countryIso2L: $countryIso2L
      seriesId: $seriesId
      state: $state
      epfVersion: $epfVersion
    ) {
      base64Data
    }
  }
`;
