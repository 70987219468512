import { gql } from "@urql/core";

const priceFragment = gql`
  fragment priceFragment on priceType {
    type
    currencyCode
    centAmount
    fractionDigits
    preciseAmount
  }
`;

export const ProductPriceListQry = gql`
  query (
    $id: String!
    $projectNodeId: String
    $filters: ProductPriceListFilters
  ) {
    project(id: $id) {
      id
      productPriceList(filters: $filters, projectNodeId: $projectNodeId) {
        includesPriceOnRequest
        products {
          id
          priceGroup
          unitPrice {
            ...priceFragment
          }
          totalPrice {
            ...priceFragment
          }
        }
        productsPrice {
          type
          currencyCode
          centAmount
          fractionDigits
        }
      }
    }
  }
  ${priceFragment}
`;

export const UserProductListQry = gql`
  query (
    $id: String!
    $productsLimit: Int
    $productsPage: Int
    $projectNodeId: String
    $sort: ProductListSort
  ) {
    project(id: $id) {
      title
      id
      productList(
        limit: $productsLimit
        page: $productsPage
        projectNodeId: $projectNodeId
        sort: $sort
      ) {
        limit
        page
        pages
        includesDummyProduct
        productsCount
        productsPrice {
          ...priceFragment
        }
        products {
          title
          kind
          quantity
          id
          ordercode
          presentation
          unitPrice {
            ...priceFragment
          }
          totalPrice {
            ...priceFragment
          }
          priceGroup
        }
      }
    }
  }
  ${priceFragment}
`;

export const ProductListQuery = gql`
  query ($client: String!, $languageIso2L: String!, $countryIso2L: String) {
    seriesNodes(
      client: $client
      languageIso2L: $languageIso2L
      countryIso2L: $countryIso2L
    ) {
      getSupportedSeriesNodesResult {
        result
        errorCode
        errorMessage
        epfVersion {
          major
          minor
          revision
          build
        }
      }
    }
  }
`;

export const DummyProductSeriesQuery = gql`
  query (
    $languageIso2L: String!
    $countryIso2L: String
    $wizardName: String!
    $dummyAlias: String!
  ) {
    dummySeries(
      languageIso2L: $languageIso2L
      countryIso2L: $countryIso2L
      wizardName: $wizardName
      dummyAlias: $dummyAlias
    ) {
      message
      result {
        name
        pimId
        seriesId
        seriesAlias
      }
    }
  }
`;
