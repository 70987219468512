import React from "react";
import { UncontrolledTooltip } from "reactstrap";
import * as Scrivito from "scrivito";
import cx from "classnames";

import { TranslateWrapper } from "components/TranslateWrapper";
import { translate } from "utils";
import { Button } from "./Button";

export const IconSet = Scrivito.connect(
  ({
    id,
    isEditable,
    handleToggleEditMode,
    onDuplicate,
    onDelete,
    onDownload,
    onStartCopying,
    isDuplicatable = true,
    isWizard,
    isDisabled,
    isConfiguredProduct = false,
    handleToggle3dView,
    isWizardReportsDownloadBtnShown,
  }) => (
    <div
      className="d-flex align-items-center justify-content-end me-4 text-gray-700"
      style={{ width: "190px" }}
    >
      {isEditable && !isWizard && (
        <>
          <Button
            data-testid="editIcon"
            square={true}
            color="tertiary"
            iconPrefix="trox"
            icon="pencil-light"
            iconClassName="icon-16"
            classNames="p-0 border-0 border-end cursor-pointer pe-2"
            id={`editIcon${id}`}
            onClick={handleToggleEditMode}
          />
          <UncontrolledTooltip target={`editIcon${id}`} placement="top">
            {translate("PROJECT_SUBTITLE_RENAME")}
          </UncontrolledTooltip>
          {typeof translate("EDIT") === "object" && (
            <TranslateWrapper
              tooltipId={`editIcon${id}`}
              tooltipText={translate("EDIT").props.tooltipText}
              placement="top"
            />
          )}
        </>
      )}
      {/* TODO: forthcoming feature (hidden acc US-19953) */}
      {/* <>
        <Button
          data-testid="favouriteIcon"
          square={true}
          color="tertiary"
          iconPrefix="trox"
          icon="star-light"
          iconClassName="icon-16"
          classNames="p-0 border-0 border-end cursor-pointer px-2"
          id={`favouriteIcon${id}`}
          onClick={() => {}}
        />
        <UncontrolledTooltip target={`favouriteIcon${id}`} placement="top">
          {translate("ADD_TO_FAVOURITE")}
        </UncontrolledTooltip>
      </> */}
      {isDuplicatable && !isWizard && (
        <>
          <Button
            id={`duplicateIcon${id}`}
            square={true}
            color="tertiary"
            iconPrefix="trox"
            icon="duplicate-light"
            iconClassName="icon-16"
            classNames={cx("p-0 border-0 px-2 border-end cursor-pointer", {
              "pe-none": isDisabled,
            })}
            data-testid="duplicateIcon"
            onClick={onDuplicate}
          />
          <UncontrolledTooltip target={`duplicateIcon${id}`} placement="top">
            {translate("DUPLICATE")}
          </UncontrolledTooltip>
          {typeof translate("DUPLICATE") === "object" && (
            <TranslateWrapper
              tooltipId={`duplicateIcon${id}`}
              tooltipText={translate("DUPLICATE").props.tooltipText}
              placement="top"
            />
          )}
        </>
      )}
      <Button
        data-testid="copyIcon"
        square={true}
        color="tertiary"
        iconPrefix="trox"
        icon="copy-light"
        iconClassName="icon-16"
        classNames={cx("p-0 border-0 px-2 cursor-pointer border-end", {
          "pe-none": isDisabled,
          "border-end-0": isWizard,
        })}
        id={`copyIcon${id}`}
        onClick={onStartCopying}
      />
      <UncontrolledTooltip target={`copyIcon${id}`} placement="top">
        {translate("COPY")}
      </UncontrolledTooltip>
      {typeof translate("COPY") === "object" && (
        <TranslateWrapper
          tooltipId={`copyIcon${id}`}
          tooltipText={translate("COPY").props.tooltipText}
          placement="top"
        />
      )}
      {isConfiguredProduct && handleToggle3dView && (
        <>
          <Button
            data-testid="3dViewIcon"
            square={true}
            color="tertiary"
            iconPrefix="trox"
            icon="3-d-symbol-regular"
            iconClassName="icon-16"
            classNames={cx("p-0 border-0 px-2 cursor-pointer", {
              "border-end": !isWizard,
            })}
            id={`X3dview${id}`}
            onClick={handleToggle3dView}
          />
          <UncontrolledTooltip target={`X3dview${id}`} placement="top">
            {translate("PRODUCT_CONFIGURATOR_3D")}
          </UncontrolledTooltip>
          {typeof translate("PRODUCT_CONFIGURATOR_3D") === "object" && (
            <TranslateWrapper
              tooltipId={`X3dview${id}`}
              tooltipText={
                translate("PRODUCT_CONFIGURATOR_3D").props.tooltipText
              }
              placement="top"
            />
          )}
        </>
      )}

      {((isConfiguredProduct && !isWizard) ||
        isWizardReportsDownloadBtnShown) && (
        <>
          <Button
            data-testid="downloadIcon"
            square={true}
            color="tertiary"
            iconPrefix="trox"
            icon="arrow-down-bracket-light"
            iconClassName="icon-16"
            classNames={cx("p-0 border-0 px-2 cursor-pointer", {
              "border-end": !isWizard,
            })}
            id={`downloadIcon${id}`}
            onClick={onDownload}
          />
          <UncontrolledTooltip target={`downloadIcon${id}`} placement="top">
            {translate("DOWNLOAD")}
          </UncontrolledTooltip>
          {typeof translate("DOWNLOAD") === "object" && (
            <TranslateWrapper
              tooltipId={`downloadIcon${id}`}
              tooltipText={translate("DOWNLOAD").props.tooltipText}
              placement="top"
            />
          )}
        </>
      )}
      {!isWizard && (
        <>
          <Button
            data-testid="deleteIcon"
            square={true}
            color="tertiary"
            iconPrefix="trox"
            icon="trash-can-light"
            iconClassName="icon-16"
            classNames="p-0 border-0 ms-2"
            id={`deleteIcon${id}`}
            onClick={onDelete}
          />
          <UncontrolledTooltip target={`deleteIcon${id}`} placement="top">
            {translate("DELETE")}
          </UncontrolledTooltip>
          {typeof translate("DELETE") === "object" && (
            <TranslateWrapper
              tooltipId={`deleteIcon${id}`}
              tooltipText={translate("DELETE").props.tooltipText}
              placement="top"
            />
          )}
        </>
      )}
    </div>
  ),
);
