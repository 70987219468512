import { replaceTempUUid } from "api/exchanges/exchangeUtils";

export const exeUpdateNodeFromQueue = ({
  operation,
  client,
  queue,
  replaceKeysMap,
}) => {
  const parentId = operation.variables?.input?.parentId;
  const id = operation.variables?.id;
  if (parentId && parentId.startsWith("temp_")) {
    operation.variables.input.parentId = replaceTempUUid({
      id: parentId,
      replaceKeysMap,
    });
  }

  if (id && id.startsWith("temp_")) {
    operation.variables.id = replaceTempUUid({
      id,
      replaceKeysMap,
    });
  }

  client.reexecuteOperation({
    ...operation,
    context: {
      ...operation.context,
      requestPolicy: "network-only",
      fromQueue: true,
    },
  });
  queue.delete(operation);
};
