export const productTranslations = {
  PRODUCT_SEARCH_CATEGORIES_RESULTS_TITLE: "Category",
  PRODUCT_SEARCH_SERIES_RESULTS_TITLE: "Series",
  PRODUCT_PRICE_LIST: "Price list",
  PRODUCT_PRICE_WITHOUT_VAT: "without VAT",
  PRODUCTS_LIST_MODAL_TITLE: "Product Selection",
  PRODUCTS_NAVIGATION_ERROR_MESSAGE:
    "Products_API doesn't work. Please try again later.",
  PRODUCT_VALUE_RANGE_FROM: "Value should be from",
  PRODUCT_VALUE_RANGE_WARNING:
    "Value should be from {{fromValue}} to {{toValue}}",
  CUSTOM_ITEM: "Custom item",
  PRICE_PER_PIECE: "Price per piece",
  PRICE_GROUP_PRICE_LIST: "Price group / price list",
  DESCRIPTION: "Description",
  DESCRIBE_PRODUCT_PLACEHOLDER: "Describe product",
  CHANGE_PRODUCT_PICTURE_BUTTON: "Change Product Picture",
  PRODUCT_PREVIEW: "Product preview",
  WISHED_ORDERCODE_PLACEHOLDER: "fill in your wished ordercode",
  PRICE_GROUP_INPUT_PLACEHOLDER: "Type in Price group",
  PRODUCT_SUCCESSFUL_ADD_TO_PROJECT:
    "Component {{copiedProductName}} added successfully to Project {{projectName}}",
  PRODUCT_INFORMATION: "Product Information",
  SPECIFICATION_TEXT: "Specification Text",
  BIM_DATA: "BIM Data",
  PRODUCT_DOWNLOADS_INFO_TEXT:
    "Here are some information about the Product. Also it could contain a Download.",
  GUIDED_PRODUCT_SELECTION: "Guided Product Selection",
  PRODUCT_INFORMATION_REPORT: "Product Information",
  PRODUCT_IFRAME_EMPTY: "No model available for this product",
  PRODUCTS_OVERVIEW_PRODUCTS_LIST_TITLE: "Product List",
  PRODUCT_LIST_LEFTSIDEBAR_NAV_TITLE: "Products",
};
