export const replaceTempUUid = ({ id, replaceKeysMap }) => {
  let updatedId = id;

  while (updatedId.startsWith("temp_")) {
    if (updatedId.includes("temp_copy_")) {
      const { keyToReplace, replaceBy } = replaceCopyFromKey(
        updatedId,
        replaceKeysMap,
      );
      updatedId = updatedId.replace(keyToReplace, replaceBy);
    } else {
      const keyToReplace = extractFinalKey(updatedId);
      if (!keyToReplace) {
        throw new Error(`Invalid key format for ID: ${updatedId}`);
      }
      const replaceBy = replaceKeysMap.get(keyToReplace);
      if (!replaceBy) {
        throw new Error(`No replacement found for key: ${keyToReplace}`);
      }

      updatedId = updatedId.replace(keyToReplace, replaceBy);
    }
  }

  return updatedId;
};

function extractFinalKey(complexKey) {
  const parts = complexKey.split("__");

  const uuidIndex = parts.findIndex(
    (part) => part === "null" || part.match(/^[0-9a-fA-F-]{36}$/),
  );

  if (uuidIndex !== -1) {
    const tempPart = parts[uuidIndex - 1];
    const uuid = parts[uuidIndex];
    const lastDigit = parts[uuidIndex + 1];
    return `${tempPart}__${uuid}__${lastDigit}`;
  }

  return null;
}

function replaceCopyFromKey(complexKey, replaceKeysMap) {
  const result = replaceKeysMap.get(complexKey);

  if (result) {
    return { keyToReplace: complexKey, replaceBy: result };
  }

  const parts = complexKey.split("__");

  if (parts.length <= 2) {
    throw new Error(
      `Invalid key format or no replacement found for key: ${complexKey}`,
    );
  }
  parts.shift();
  parts.pop();
  const newKey = parts.join("__");
  return replaceCopyFromKey(newKey, replaceKeysMap);
}
