import { replaceTempUUid } from "api/exchanges/exchangeUtils";

export const actionOnGetCopyNodeResponse = ({
  response,
  queue,
  replaceKeysMap,
}) => {
  if (queue.size !== 0 && response?.data?.copyProjectNode) {
    const pastedItemTempId = `temp_copy_${response.operation.variables.input.title.replace(/[^\w-]/g, "")}__${response?.operation?.variables?.destinationId}__${response?.operation?.variables?.referencePosition}`;
    const { id, nodes } = response?.data?.copyProjectNode || {};
    replaceKeysMap.set(pastedItemTempId, id);

    if (nodes?.length !== 0) {
      collectAllReplacementKeys({
        nodes,
        parentId: pastedItemTempId,
        replaceKeysMap,
      });
    }
  }
};

const collectAllReplacementKeys = ({ nodes, parentId, replaceKeysMap }) => {
  nodes?.forEach((node) => {
    const idToReplace = `temp__${parentId}__${node.referencePosition}`;
    replaceKeysMap.set(idToReplace, node.id);
    if (node?.nodes && node?.nodes.length > 0) {
      collectAllReplacementKeys({
        nodes: node.nodes,
        parentId: idToReplace,
        replaceKeysMap,
      });
    }
  });
};

export const exeCopyNodeFromQueue = ({
  operation,
  client,
  queue,
  replaceKeysMap,
}) => {
  const { destinationId, id } = operation.variables;

  if (destinationId?.startsWith("temp_")) {
    operation.variables.destinationId = replaceTempUUid({
      id: destinationId,
      replaceKeysMap,
    });
  }
  if (id.startsWith("temp_")) {
    operation.variables.id = replaceTempUUid({
      id,
      replaceKeysMap,
    });
  }

  client.reexecuteOperation({
    ...operation,
    context: {
      ...operation.context,
      requestPolicy: "network-only",
      fromQueue: true,
    },
  });
  queue.delete(operation);
};
