import React from "react";
import cx from "classnames";
import { Icon } from "components/Icon/Icon";
import { TranslateWrapper } from "components/TranslateWrapper";
import s from "../Styles.module.scss";

export const PasteButton = ({ nodeId: id, text }) => (
  <div className={cx("ms-2 text-blue-300 text-nowrap", s.pastePreviewButton)}>
    <Icon
      className="text-blue-300 me-1"
      iconName="paste-light"
      prefix="trox"
      data-testid="paste-light"
    />
    <>
      {text}
      {typeof nodePaste === "object" && (
        <TranslateWrapper
          tooltipText={text.props.tooltipText}
          tooltipId={`project-node-${id}`}
        />
      )}
    </>
  </div>
);
