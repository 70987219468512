import React from "react";
import * as Scrivito from "scrivito";
import cx from "classnames";
import { getHomepage, translate } from "utils";
import { ProjectTree } from "components/ProjectTree/ProjectTree";
import { nodeSizes } from "variables";
import { SideBarWrapper } from "components/LeftSidebar/SideBarWrapper";
import { Icon } from "../Icon/Icon";

export const ProjectSummarySidebar = Scrivito.connect(({ projectId }) => {
  const navigateToConfigurator = () => {
    const configurator = getHomepage()?.get("projectConfiguratorPage");

    if (configurator) {
      Scrivito.navigateTo(() => configurator, {
        params: {
          projectId,
        },
      });
    }
  };
  return (
    <SideBarWrapper
      width={332}
      zIndex="2"
      withToggle
      toggleOnLeft
      toggleText="PROJECT_SUMMARY_TITLE"
      isOpenDefault={false}
      color="secondary"
    >
      <div className={cx("d-flex flex-shrink-0 bg-white z-index-1 h-100")}>
        <div className="d-flex flex-column flex-shrink-0 w-100">
          <div className="p-3_5 w-100 h-100 d-flex flex-column">
            <div className="d-flex flex-column flex-shrink-0">
              <div className="d-flex flex-row justify-content-between align-items-center mb-1">
                <h5 className="text-uppercase mb-0 text-nowrap">
                  {translate("PROJECT_SUMMARY_TITLE")}
                </h5>
                <Icon
                  className="text-gray-400 icon-24 cursor-pointer"
                  iconName="arrow-up-right-square-regular"
                  prefix="trox"
                  onClick={navigateToConfigurator}
                />
              </div>
              <h6 className="text-nowrap">
                {translate("PROJECT_SUMMARY_SIDEBAR_MY_STRUCTURE")}
              </h6>
            </div>
            <div className="d-flex flex-grow-1">
              <ProjectTree
                options={{
                  dndDisabled: true,
                  createNodeDisabled: true,
                  copyNodeDisabled: true,
                  duplicateNodeDisabled: true,
                  editNodeDisabled: true,
                  deleteNodeDisabled: true,
                  isIndentCompact: true,
                  productImagesDisabled: true,
                  configurationLinkDisabled: true,
                  indentSize: nodeSizes.NODE_INDENT_COMPACT,
                  folderHeight: nodeSizes.FOLDER_HEIGHT_COMPACT,
                  configurationHeight: nodeSizes.CONFIGURATION_HEIGHT_COMPACT,
                  ModelViewDisabled: true,
                  downloadModalDisabled: true,
                  simpleQuantityView: true,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </SideBarWrapper>
  );
});
