import React from "react";
import cx from "classnames";
import { Icon } from "../Icon/Icon";
import s from "./SortButton.module.scss";

export const SortButton = ({
  disabled = false,
  sortCriteria,
  setSortCriteria = () => {},
  currentSort,
  isOrderDescending,
  setIsOrderDescending,
  setCurrentPageNo,
  setIsSelectAll = () => {},
  setProjectsToDelete = () => {},
}) => {
  const isActive = sortCriteria === currentSort;

  const handleClick = () => {
    if (disabled) {
      return;
    }
    setSortCriteria(sortCriteria);
    if (!isActive) {
      setIsOrderDescending(false);
    } else {
      setIsOrderDescending((prev) => !prev);
    }
    setCurrentPageNo(1);
    setIsSelectAll(false);
    setProjectsToDelete([]);
  };

  return (
    <button
      onClick={handleClick}
      className={cx(
        "mx-1 p-0 border-0 bg-transparent text-gray-700 d-flex align-items-center",
        {
          "text-gray-400": disabled,
        },
      )}
      disabled={disabled}
    >
      <Icon
        prefix="trox"
        iconName="arrow-up-regular"
        className={cx(s.arrowRight, {
          "text-blue-300": !isOrderDescending && !disabled && isActive,
        })}
      />
      <Icon
        prefix="trox"
        iconName="arrow-up-regular"
        className={cx(s.arrowDown, "rotate-180", {
          "text-blue-300": isOrderDescending && isActive,
        })}
      />
    </button>
  );
};
