import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useQuery, useMutation } from "urql";
import cx from "classnames";
import { WizardRunQry } from "api/queries";
import { invokeTopologyActionsMut } from "api/mutations/topology";
import { duplicateWizardStepQuestionnaireMut } from "api/mutations";
import {
  useCenterTopologyItem,
  useOpenAndScrollLeftSidebarTopologyItem,
  useVariables,
  useWizardData,
} from "hooks";
import { isBrowserEnv } from "utils";
import { XCircleLoader } from "../components/XCircleLoader/XCircleLoader";

import { getTopology } from "../objs/_ConfiguratorObjs/Wizard/wizardHelpers";

export const WizardContext = createContext({});

export const WizardContextProvider = ({
  children,
  projectId,
  initialNodeId,
  subType,
  step,
  lang,
}) => {
  const [selectedTopologyItemId, setSelectedTopologyItemId] = useState("");
  const [centerTopologyItemId, setCenterTopologyItemIdState] = useState("");
  const [leftSidebarTopologyItemId, setLeftSidebarTopologyItemIdState] =
    useState("");

  const [selectedPasteItem, setSelectedPasteItem] = useState(new Set());
  const [topologyOperation, setTopologyOperation] = useState(null);
  const [wizardQuestionsValidation, setWizardQuestionsValidation] = useState({
    isValidationMessageShown: true,
  });

  const transformRef = useRef(null);
  const centerElementRef = useRef(null);
  const topologyMenuWrapperRef = useRef(null);
  const firstToggleButton = useRef(null);
  const secondToggleButton = useRef(null);
  const leftSidebarTopologyCollapsesMap = useRef(new Map());
  const [saving, setSaving] = useState(false);
  const [createZoneAllowed, setCreateZoneAllowed] = useState(true);
  const setCenterTopologyItemId = useCallback((id) => {
    setCenterTopologyItemIdState(id ? `topology-item-${id}` : id);
  }, []);

  const setLeftSidebarTopologyItemId = useCallback((id) => {
    setLeftSidebarTopologyItemIdState(id ? `ls-topology-item-${id}` : id);
  }, []);

  useOpenAndScrollLeftSidebarTopologyItem({
    leftSidebarTopologyItemId,
    leftSidebarTopologyCollapsesMap,
    selectedTopologyItemId,
    setLeftSidebarTopologyItemId,
    topologyMenuWrapperRef,
  });

  useCenterTopologyItem({ centerTopologyItemId, transformRef });

  const [wizardParams, setWizardParams] = useState({
    stepVar: step ? Number(step) : null,
    nodeIdVar: initialNodeId || null,
  });

  const [isFirstSideBarOpen, setIsFirstSideBarOpen] = useState(false);
  const [isSecondSideBarOpen, setIsSecondSideBarOpen] = useState(true);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [wizardResultTab, setWizardResultTab] = useState("topology");

  const variables = useVariables(
    projectId,
    wizardParams.nodeIdVar,
    wizardParams.stepVar,
    lang,
    subType,
  );

  const [{ data, fetching }, exeWizardRunQry] = useQuery({
    query: WizardRunQry,
    variables,
    pause: wizardParams.stepVar === 0 || !lang,
  });
  const [{ fetching: isActionFetching }, executeTopologyAct] = useMutation(
    invokeTopologyActionsMut,
  );

  const [{ fetching: isDuplicating }, executeDuplicateMutation] = useMutation(
    duplicateWizardStepQuestionnaireMut,
  );
  const isProjectExisting = useMemo(
    () => initialNodeId || projectId,
    [initialNodeId, projectId],
  );

  const {
    availableSteps,
    constraints = [],
    node = {},
    nodeId,
    currentQuestionnaire,
    questionnaireLists,
    step: stepResponse,
    steps: stepsResponse,
    projectId: projectIdResponse,
    subType: subTypeResponse,
    topology = [], // todo: might be null, so the default value will not work in that case
  } = data?.wizardRun &&
  (data?.wizardRun?.subType === subType || subType === undefined)
    ? data.wizardRun
    : {};

  const maxQuestionnaireCount = constraints.find(
    ({ scope, reference }) =>
      scope[0] === "questionnaireLists" && reference === "count",
  )?.maxValue;

  const { wizardSubTypeObj, wizardItemStepsTitles = [] } = useWizardData(
    subType || subTypeResponse,
  );

  const isMultipleQuestionnaireListStep = maxQuestionnaireCount > 1;

  const topologyActions = useMemo(
    () =>
      (topology &&
        topology[0]?.actions &&
        topology[0]?.actions.reduce((acc, item) => {
          acc[item.id] = item;
          return acc;
        }, {})) ||
      null,
    [topology],
  );

  const structuredTopology = useMemo(
    () => topology && getTopology(topology[0]?.configurations[0]),
    [topology],
  );

  const isIntro = useMemo(
    () => wizardParams.stepVar === 0,
    [wizardParams.stepVar],
  );

  const isTopology = useMemo(
    () =>
      topology && stepResponse === stepsResponse && wizardParams.stepVar !== 0,
    [stepResponse, stepsResponse, topology, wizardParams.stepVar],
  );

  const isUserActionsDisabled = useMemo(
    () => isMultipleQuestionnaireListStep && isFormDirty,
    [isMultipleQuestionnaireListStep, isFormDirty],
  );

  const isLastAvailableStep = useMemo(
    () => availableSteps === stepResponse,
    [availableSteps, stepResponse],
  );

  useEffect(() => {
    if (step === "0" && subType && subType !== subTypeResponse) {
      setWizardParams({ nodeIdVar: null, stepVar: 0 });
    }
  }, [step, subType, initialNodeId, projectId, subTypeResponse]);

  useEffect(() => {
    if (
      nodeId &&
      nodeId !== initialNodeId &&
      subType === subTypeResponse &&
      isBrowserEnv()
    ) {
      const queryParamsStr = `?subType=${
        subTypeResponse || subType
      }&projectId=${projectIdResponse}&nodeId=${nodeId}`;
      window.history.replaceState({}, "", queryParamsStr);
      setWizardParams((params) => ({
        ...params,
        nodeIdVar: nodeId,
      }));
    }
  }, [
    initialNodeId,
    nodeId,
    projectIdResponse,
    step,
    subType,
    subTypeResponse,
  ]);

  useEffect(() => {
    stepResponse &&
      setWizardParams((params) => ({
        ...params,
        stepVar: stepResponse,
      }));
  }, [stepResponse]);

  useEffect(() => {
    setIsSecondSideBarOpen(
      !(
        isTopology ||
        (subTypeResponse === "roomcontrol" && stepResponse === 2)
      ),
    );
  }, [isTopology, stepResponse, subTypeResponse]);

  const handleStepChange = useCallback(
    (stepNumber) =>
      setWizardParams((params) => ({
        ...params,
        stepVar: stepNumber,
      })),
    [],
  );

  const handlePasteAction = useCallback(
    (id) => {
      setSelectedPasteItem((prevSelected) => {
        const selected = new Set(prevSelected);
        selected.has(id) ? selected.delete(id) : selected.add(id);
        return selected;
      });
    },
    [setSelectedPasteItem],
  );

  return (
    <div
      className={cx(
        isTopology
          ? "bg-gray-100 d-flex flex-column h-100 overflow-hidden"
          : "bg-gray-100 flex-grow-1",
      )}
      data-testid="wizard-component"
    >
      {fetching ? (
        <div className="d-flex justify-content-center align-items-center w-100 h-100">
          <XCircleLoader />
        </div>
      ) : (
        <WizardContext.Provider
          value={{
            centerElementRef,
            centerTopologyItemId,
            leftSidebarTopologyCollapsesMap,
            leftSidebarTopologyItemId,
            selectedTopologyItemId,
            setCenterTopologyItemId,
            setLeftSidebarTopologyItemId,
            setSelectedTopologyItemId,
            topologyMenuWrapperRef,
            transformRef,
            handleStepChange,
            isLastAvailableStep,
            isUserActionsDisabled,
            isIntro,
            structuredTopology,
            wizardSubTypeObj,
            wizardItemStepsTitles,
            isProjectExisting,
            currentQuestionnaire,
            questionnaireLists,
            node,
            isFirstSideBarOpen,
            setIsFirstSideBarOpen,
            isSecondSideBarOpen,
            setIsSecondSideBarOpen,
            firstToggleButton,
            secondToggleButton,
            exeWizardRunQry,
            isFormDirty,
            setIsFormDirty,
            step: stepResponse || step,
            nodeId,
            availableSteps,
            constraints,
            isMultipleQuestionnaireListStep,
            isTopology,
            subType: subTypeResponse || subType,
            topology,
            steps: stepsResponse,
            maxQuestionnaireCount,
            projectId,
            setWizardQuestionsValidation,
            wizardQuestionsValidation,
            selectedPasteItem,
            setSelectedPasteItem,
            saving,
            setSaving,
            createZoneAllowed,
            setCreateZoneAllowed,
            topologyActions,
            handlePasteAction,
            isActionFetching,
            executeTopologyAct,
            wizardResultTab,
            setWizardResultTab,
            topologyOperation,
            setTopologyOperation,
            executeDuplicateMutation,
            isDuplicating,
          }}
        >
          {children}
        </WizardContext.Provider>
      )}
    </div>
  );
};

export const useWizardContext = () => useContext(WizardContext);
