import cx from "classnames";
import React from "react";
import * as Scrivito from "scrivito";

import { IconSet } from "../IconSet";
import { Icon } from "../Icon/Icon";
import { NumberInput } from "../Inputs/NumberInput";

import styles from "./Product.module.scss";

export const ProductItem = ({
  isWizardProduct = false,
  preview = null,
  drag = null,
  title,
  presentationTitle,
  presentation,
  quantityTitle,
  isEditMode,
  quantity,
  handleQuantityUpdate,
  handleCopyStart,
  handleDelete,
  handleToggleEditMode,
  withIcons = true,
  productImage,
  allowProductInputOutsidePropagation = true,
}) => (
  <div
    className={cx(
      "border d-flex border-box align-items-center justify-content-between text-gray-700",
      { [styles.wizardProduct]: isWizardProduct },
    )}
    ref={preview}
  >
    <div className="d-flex align-items-center">
      {!isWizardProduct && drag && (
        <div ref={drag}>
          <Icon
            iconName="grip-dots-vertical"
            className="icon-12 ms-1 text-gray-400 cursor-pointer"
          />
        </div>
      )}
      {productImage ? (
        <div className="icon-64 m-2 d-flex align-items-center justify-content-center">
          <Scrivito.ImageTag
            width={64}
            content={productImage}
            className="w-100 h-100 object-fit-contain m-0"
          />
        </div>
      ) : (
        <Icon className="icon-64 m-2" prefix="trox" iconName="squares-light" />
      )}
      <div className="d-flex flex-column">
        <div className="d-flex">
          <span className="fw-bolder fs-md">{title}</span>
          {/* TODO: once we will undersand what this icon should do... */}
          {/* <Icon */}
          {/*  className="ms-2 text-gray-300" */}
          {/*  prefix="trox" */}
          {/*  iconName="arrow-up-right-square-regular" */}
          {/* /> */}
        </div>
        <span className="text-gray-400">
          {presentationTitle}: {presentation}
        </span>
      </div>
    </div>
    <div className="me-4 pe-2 d-flex flex-nowrap">
      <div className="d-flex align-items-center pe-5">
        <span className="me-2">{quantityTitle}:</span>
        <div style={{ width: "82px" }}>
          {isEditMode ? (
            <NumberInput
              initialValue={quantity}
              onSave={handleQuantityUpdate}
              allowOutsidePropagation={allowProductInputOutsidePropagation}
              min={1}
              updateOnChange={true}
            />
          ) : (
            quantity
          )}
        </div>
      </div>
      {withIcons && (
        <IconSet
          isEditMode={isEditMode}
          isEditable={true}
          isDuplicatable={false}
          handleToggleEditMode={handleToggleEditMode}
          onStartCopying={handleCopyStart}
          onDelete={handleDelete}
          isWizard={isWizardProduct}
        />
      )}
    </div>
  </div>
);
