import { nodeTypes } from "variables";

export const mapTree = (projectTree, projectId, projectTitle) => {
  if (projectId && projectTree) {
    const mappedTree = mapChildren({
      id: projectId,
      kind: nodeTypes.ROOT,
      title: projectTitle,
      nodes: projectTree,
    });
    return [mappedTree];
  }
  return [];
};

export const mapChildren = (node, isWizardChild = false) => {
  const WIZARD_PREFIX = "wizard";
  const isWizard = isWizardChild || node.kind.startsWith(WIZARD_PREFIX);

  if (node.kind === nodeTypes.CONFIGURATION) {
    return { ...node, isWizard };
  }

  const configurationsAndNodes = Array.isArray(node.configurations)
    ? node.configurations.map((conf) => ({
        ...conf,
        kind: nodeTypes.CONFIGURATION,
        parentId: conf.parentId || node.id,
        isWizard,
      }))
    : [];

  node.nodes =
    Array.isArray(node.nodes) && node.nodes.length > 0
      ? [...configurationsAndNodes, ...node.nodes]
      : configurationsAndNodes;

  if (node.configurations) {
    delete node.configurations;
  }

  return {
    ...node,
    nodes: node.nodes?.map((child) => mapChildren(child, isWizard)) || [],
    isWizard,
  };
};
