import { addConfigurationToNode } from "api/cacheUtils/treeNodeUpdates/utils";

export const addConfig = (projectTree, newConfiguration, args) => {
  const { pimId, orderCode } = args;
  const { nodeId } = newConfiguration;

  addConfigurationToNode({
    nodes: projectTree,
    newConfiguration: {
      ...newConfiguration,
      pimId,
      orderCode,
    },
    parentId: nodeId,
  });
  return projectTree;
};
