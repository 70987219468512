export const priceToString = ({
  price = {},
  noPriceText,
  commissioningPrice = 0,
}) => {
  const {
    centAmount = 0,
    preciseAmount = 0,
    currencyCode,
    fractionDigits = 0,
    type,
  } = price;
  if (
    !currencyCode ||
    (!centAmount && !commissioningPrice) ||
    (!Number(centAmount) && !commissioningPrice)
  ) {
    return noPriceText;
  }

  const formatter = new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: currencyCode,
  });

  const formatPrice = () => {
    let amount;

    if (type === "highPrecision") {
      amount = preciseAmount / 10 ** fractionDigits + commissioningPrice;
    } else {
      amount = centAmount / 10 ** fractionDigits + commissioningPrice;
    }

    return formatter.format(amount);
  };

  return formatPrice();
};
