export const updateConfig = (projectTree, updatedNode) => {
  const { id, quantity } = updatedNode;

  updateQuantity({
    nodes: projectTree,
    id,
    newQuantity: quantity,
  });

  return projectTree;
};

const updateQuantity = ({ nodes, id, newQuantity }) => {
  const recursiveUpdate = (currentNode) => {
    if (currentNode.id === id) {
      currentNode.quantity = newQuantity;
      return true;
    }

    return currentNode.nodes?.some(recursiveUpdate) ?? false;
  };

  return nodes.some(recursiveUpdate);
};
