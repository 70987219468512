import React from "react";

export const ProductListTableRowSkeleton = () => (
  <>
    <td style={{ width: "20%" }}>
      <div className="shortloadingLine col-12 rounded"></div>
    </td>
    <td style={{ width: "25%" }}>
      <div className="shortloadingLine col-8 rounded"></div>
    </td>
    <td style={{ width: "10%" }}>
      <div className="shortloadingLine col-8 rounded"></div>
    </td>
    <td style={{ width: "15%" }}>
      <div className="shortloadingLine col-8 rounded"></div>
    </td>
    <td style={{ width: "15%" }}>
      <div className="shortloadingLine col-8 rounded"></div>
    </td>
    <td style={{ width: "15%" }}>
      <div className="shortloadingLine col-8 rounded"></div>
    </td>
  </>
);
