import React from "react";
import * as Scrivito from "scrivito";
import { AccordionBody, AccordionHeader, AccordionItem } from "reactstrap";
import cx from "classnames";
import { translate } from "utils";
import { Icon } from "components/Icon/Icon";
import { CheckboxAccordionContent } from "./CheckboxAccordionContent";

export const ProjectDownloadAccordionItem = Scrivito.connect(
  ({
    title,
    children,
    checked = false,
    setItemsData,
    itemsData,
    hasContent,
    id,
  }) => (
    <AccordionItem className="border-bottom border-bottom-1 border-bottom-solid">
      <AccordionHeader tag="h6" className="p-2_5 mb-0" targetId={title}>
        <div
          className={cx(
            "gap-2 font-md text-gray-700 d-flex align-items-center",
            { "cursor-not-allowed pe-none opacity-50": !hasContent },
          )}
        >
          <CheckboxAccordionContent
            title={title}
            itemsData={itemsData}
            checked={checked}
            setItemsData={setItemsData}
            id={id}
          />
        </div>

        <Icon prefix="trox" iconName="chevron-down-light" />
      </AccordionHeader>
      <AccordionBody accordionId={title}>
        {hasContent ? (
          <div className="px-5 d-flex flex-column gap-3">{children}</div>
        ) : (
          <div className="px-5 text-gray-400">
            {translate("NO_DOCUMENTS_AVAILABLE")}
          </div>
        )}
      </AccordionBody>
    </AccordionItem>
  ),
);
