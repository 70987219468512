import React from "react";

export const ProjectListTableRowSkeleton = () => (
  <>
    <td style={{ width: "25%" }}>
      <div style={{ width: "238px" }}>
        <div className="shortloadingLine col-12 rounded"></div>
      </div>
    </td>
    <td style={{ width: "20%" }}>
      <div className="col-9">
        <div className="shortloadingLine col-8 rounded"></div>
        <div className="shortloadingLine col-12 rounded"></div>
      </div>
    </td>
    <td style={{ width: "20%" }}>
      <div className="blockSkeleton d-flex justify-content-center align-items-center w-100">
        <div className="whiteLine w-50" />
      </div>
    </td>
    <td style={{ width: "20%" }}>
      <div className="blockSkeleton d-flex justify-content-center align-items-center w-100">
        <div className="whiteLine w-50" />
      </div>
    </td>
    <td style={{ width: "15%" }}>
      <div className="blockSkeleton d-flex justify-content-center align-items-center w-75">
        <div className="whiteLine w-50" />
      </div>
    </td>
  </>
);
