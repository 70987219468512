import { filter, pipe, tap } from "wonka";
import {
  actionOnGetCreateNodeResponse,
  exeCreateNodeFromQueue,
} from "api/exchanges/nodeCreate";
import {
  actionOnGetCopyNodeResponse,
  exeCopyNodeFromQueue,
} from "api/exchanges/nodeCopy";
import { exeUpdateNodeFromQueue } from "api/exchanges/nodeUpdate";
import { exeDeleteNodeFromQueue } from "api/exchanges/nodeDelete";

let requestInProgress = false;

export const pauseExchange = ({ forward, client }) => {
  const queue = new Set();
  const replaceKeysMap = new Map();

  return (operations$) => {
    const sharedOps$ = pipe(
      operations$,
      tap((operation) => {
        if (
          operation.context.projectPauseExchangeType &&
          operation.context.optimistic === true
        ) {
          if (requestInProgress) {
            queue.add(operation);
          } else {
            requestInProgress = true;
          }
        }
      }),
      filter((operation) => !queue.has(operation)),
    );

    return pipe(
      forward(sharedOps$),
      tap((response) => {
        if (response.operation.context.projectPauseExchangeType) {
          switch (response.operation.context.projectPauseExchangeType) {
            case "nodeCreate":
              actionOnGetCreateNodeResponse({
                response,
                client,
                queue,
                replaceKeysMap,
                requestInProgress,
              });
              break;
            case "nodeCopy":
              actionOnGetCopyNodeResponse({
                response,
                client,
                queue,
                replaceKeysMap,
                requestInProgress,
              });
              break;
            case "nodeUpdate":
              break;
            case "nodeDelete":
              break;
            case "moveConfig":
              break;
            case "updateConfig":
              break;
            case "deleteConfig":
              break;
            case "addConfig":
              break;
            case "copyConfig":
              break;
            default:
              console.error(
                response.operation.context.projectPauseExchangeType,
                "not specified",
              );
          }
          if (queue.size === 0) {
            requestInProgress = false;
            replaceKeysMap.clear();
          } else {
            const operation = queue.values().next().value;
            switch (operation.context.projectPauseExchangeType) {
              case "nodeCreate":
                exeCreateNodeFromQueue({
                  operation,
                  client,
                  queue,
                  replaceKeysMap,
                });
                break;
              case "nodeCopy":
                exeCopyNodeFromQueue({
                  operation,
                  client,
                  queue,
                  replaceKeysMap,
                });
                break;
              case "nodeUpdate":
                exeUpdateNodeFromQueue({
                  operation,
                  client,
                  queue,
                  replaceKeysMap,
                });
                break;
              case "nodeDelete":
                exeDeleteNodeFromQueue({
                  operation,
                  client,
                  queue,
                  replaceKeysMap,
                });
                break;
              case "moveConfig":
                break;
              case "updateConfig":
                break;
              case "deleteConfig":
                break;
              case "addConfig":
                break;
              case "copyConfig":
                break;
              default:
                console.error(
                  response.operation.context.projectPauseExchangeType,
                  "not specified",
                );
            }
          }
        }
      }),
    );
  };
};
