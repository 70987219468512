export const PRODUCTS_PER_PAGE = 10;

export const SORTING_CRITERIA = {
  QUANTITY: "QUANTITY",
  UNIT_PRICE: "PRICE",
  TOTAL_PRICE: "TOTAL_PRICE",
  NAME: "NAME",
  PRESENTATION: "PRESENTATION",
  PRICE_GROUP: "PRICE_GROUP",
};
