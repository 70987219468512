import React, { useCallback, useEffect, useState, useMemo } from "react";
import { useMutation } from "urql";
import cx from "classnames";
import { createProductXBIMReport } from "api/mutations";
import { translate } from "utils";

const Product3DModel = ({
  requestParams,
  setWaitingFor3dModel,
  isIncreased,
  hasNoInputProd = false,
}) => {
  const [, execute] = useMutation(createProductXBIMReport);
  const [iframeDoc, setIframeDoc] = useState("");
  const [error, setError] = useState(false);

  useEffect(() => {
    requestParams &&
      execute(
        {
          systemOfUnits: requestParams.resultUnitsSystem,
          languageIso2L: requestParams.languageIso2L,
          countryIso2L: "de",
          seriesId: requestParams.seriesId,
          state: requestParams.state,
        },
        { context: { requestType: "3d-iframe" } },
      ).then(({ error: _error, data }) => {
        if (_error) {
          setError(true);
        }
        if (data) {
          if (data.createProductXBIMReport?.base64Data) {
            setWaitingFor3dModel && setWaitingFor3dModel(false);
            const atobw = atob(data.createProductXBIMReport?.base64Data);
            setIframeDoc(atobw);
          } else {
            setWaitingFor3dModel && setWaitingFor3dModel("failed");
          }
        }
      });
  }, [requestParams, execute, setIframeDoc, setWaitingFor3dModel]);

  const sendXmlByForm = useCallback(() => {
    const binaryString = iframeDoc;
    const byteArray = new Uint8Array(binaryString.length);
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < binaryString.length; i++) {
      byteArray[i] = binaryString.charCodeAt(i);
    }
    const decoder = new TextDecoder("utf-8");
    const xmlString = decoder.decode(byteArray);
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xmlString, "application/xml");
    const serializedXml = new XMLSerializer().serializeToString(xmlDoc);

    document.getElementById("xmlString").value = serializedXml;

    const submitForm = () => {
      const form = document.getElementById("view_3d_form");
      HTMLFormElement.prototype.submit.call(form);
    };

    submitForm();
  }, [iframeDoc]);

  useEffect(() => {
    sendXmlByForm();
  }, [iframeDoc, sendXmlByForm]);

  const height = useMemo(() => {
    if (isIncreased || setWaitingFor3dModel) {
      return "75vh";
    }
    if (hasNoInputProd) {
      return "560px";
    }
    return "calc(30vh + 150px)";
  }, [isIncreased, setWaitingFor3dModel, hasNoInputProd]);

  return (
    <div
      className={cx("view_3d  w-100", {
        "h-100": !hasNoInputProd,
      })}
    >
      <form
        name="view_3d_form"
        id="view_3d_form"
        action="https://linear-cad-portal.herokuapp.com/pages/productLight"
        encType="multipart/form-data"
        method="post"
        target="vid_iframe"
        className="view3d_form"
      >
        <div>
          <textarea id="xmlString" name="xmlString" hidden defaultValue="" />
        </div>
        <div>
          <input
            type="hidden"
            id="language"
            name="language"
            defaultValue={requestParams.languageIso2L || "de"}
          />
        </div>
        <div>
          <input type="hidden" name="showXmlDescription" defaultValue="false" />
        </div>
        <div>
          <input type="hidden" name="vendorStyle" defaultValue="trox" />
        </div>
        <div>
          <button
            type="submit"
            id="view3dFormSubmit"
            name="submit"
            hidden
          ></button>
        </div>
      </form>

      {error ? (
        <div
          className="d-flex justify-content-center align-items-center w-100 h-100"
          style={{
            minHeight: height,
          }}
        >
          <p>{translate("PRODUCT_IFRAME_EMPTY")} </p>
        </div>
      ) : (
        <iframe
          title="3d-view"
          name="vid_iframe"
          id="vid_iframe"
          className="d-flex  mt-2"
          style={{
            height: "100%",
            width: "100%",
            minHeight: height,
          }}
        />
      )}
    </div>
  );
};

export default Product3DModel;
