import { mapTree } from "api/cacheUtils/treeNodeUpdates/mapTree";
import { refreshCacheProjectTree } from "api/cacheUtils/urqlCacheUtils";
import { ProjectDataQry } from "api/queries";

export const projectSideEffects = {
  queries: {
    project(result, args, cache) {
      if (result?.project?.projectTree && !result?.project?.updated) {
        cache.updateQuery(
          { query: ProjectDataQry, variables: args },
          (data) => {
            if (data) {
              const {
                project: { projectTree, title, id },
              } = data;
              return {
                ...data,
                project: {
                  ...data.project,
                  updated: true,
                  projectTree: mapTree(projectTree, id, title),
                },
              };
            }
            return data;
          },
        );
      }
    },
  },
  mutations: {
    createProjectNode(result, args, cache, info) {
      if (result.createProjectNode) {
        refreshCacheProjectTree(
          "createNode",
          result.createProjectNode,
          args,
          cache,
          info,
        );
      }
    }, // updates and/or moves node
    updateProjectNode(result, args, cache, info) {
      if (result.updateProjectNode) {
        refreshCacheProjectTree(
          "updateNode",
          result.updateProjectNode,
          args,
          cache,
          info,
        );
      }
    },
    deleteProjectNode(result, args, cache, info) {
      if (result.deleteProjectNode) {
        refreshCacheProjectTree(
          "deleteNode",
          result.deleteProjectNode,
          args,
          cache,
          info,
        );
      }
    },
    copyProjectNode(result, args, cache, info) {
      if (result.copyProjectNode) {
        refreshCacheProjectTree(
          "copyNode",
          result.copyProjectNode,
          args,
          cache,
          info,
        );
      }
    },
    editProjectNodeConfiguration(result, args, cache, info) {
      if (result.editProjectNodeConfiguration) {
        refreshCacheProjectTree(
          "updateConfig",
          result.editProjectNodeConfiguration,
          args,
          cache,
          info,
        );
      }
    },
    deleteProjectNodeConfiguration(result, args, cache, info) {
      if (result.deleteProjectNodeConfiguration) {
        refreshCacheProjectTree(
          "deleteConfig",
          result.deleteProjectNodeConfiguration,
          args,
          cache,
          info,
        );
      }
    },
    copyProjectNodeConfiguration(result, args, cache) {
      if (result.copyProjectNodeConfiguration) {
        refreshCacheProjectTree(
          "copyConfig",
          result.copyProjectNodeConfiguration,
          args,
          cache,
        );
      }
    },
    moveProjectNodeConfiguration(result, args, cache, info) {
      if (result.moveProjectNodeConfiguration) {
        refreshCacheProjectTree(
          "moveConfig",
          result.moveProjectNodeConfiguration,
          args,
          cache,
          info,
        );
      }
    },
    addProjectNodeConfiguration(result, args, cache, info) {
      if (result.addProjectNodeConfiguration) {
        refreshCacheProjectTree(
          "addConfig",
          result.addProjectNodeConfiguration,
          args,
          cache,
          info,
        );
      }
    },
  },
  optimistic: {
    createProjectNode(args, _cache, info) {
      if (info.context?.fromQueue !== true) {
        return {
          __typename: "ProjectNode",
          id: `temp__${args.input.parentId}__${args.referencePosition}`,
          kind: "any",
          parentId: args.input.parentId,
          referencePosition: args.referencePosition,
          systemPath: info.variables.parentSystemPath
            ? `${info.variables.parentSystemPath}.nodes[${args.referencePosition - 1}]`
            : `[${args.referencePosition - 1}]`,
          title: args.input.title,
        };
      }
    },
    deleteProjectNode(args, _cache, info) {
      if (info.context?.fromQueue !== true) {
        return {
          __typename: "ProjectNode",
          projectId:
            info?.variables?.projectId ||
            new URLSearchParams(document.location.search)?.get("projectId"),
          id: args.id,
          kind: "any",
          systemPath: info?.variables?.systemPath,
        };
      }
    },
    copyProjectNode(args, _cache, info) {
      if (info.context?.fromQueue !== true) {
        return {
          __typename: "ProjectNode",
          parentId: args.destinationId,
          referencePosition: args.referencePosition,
          title: args.input.title,
          id: `temp_copy_${args.input.title.replace(/[^\w-]/g, "")}__${args.destinationId || null}__${args.referencePosition}`,
          copiedFrom: args.id,
          projectId: info.variables?.projectId,
        };
      }
    },
    updateProjectNode(args, _cache, info) {
      if (info.context?.fromQueue !== true) {
        return {
          __typename: "ProjectNode",
          id: args.id,
          parentId: args.input?.parentId || undefined,
          referencePosition: args.referencePosition || null,
          title: args.input?.title || null,
        };
      }
    },
    addProjectNodeConfiguration(args, _cache, info) {
      if (info.context?.fromQueue !== true) {
        return {
          __typename: "ProjectNodeConfiguration",
          id: `temp__${crypto.randomUUID()}`,
          productId: info?.variables.productId || null,
          quantity: args.quantity,
          pimId: args.pimId,
          orderCode: args.orderCode,
          presentation: args.presentation,
          productName: args.productName,
          nodeId: args.nodeId,
        };
      }
    },
    editProjectNodeConfiguration(args, _cache, info) {
      if (info.context?.fromQueue !== true) {
        return {
          id: args.id,
          quantity: args.quantity,
        };
      }
    },
    moveProjectNodeConfiguration(args, _cache, info) {
      if (info.context?.fromQueue !== true) {
        return {
          id: args.id,
          nodeId: args.destinationNodeId,
        };
      }
    },
    deleteProjectNodeConfiguration(args, _cache, info) {
      if (info.context?.fromQueue !== true) {
        return {
          __typename: "ProjectNodeConfiguration",
          id: args.id,
        };
      }
    },
  },
};
