import React, { useCallback, useState } from "react";
import { Accordion } from "reactstrap";
import { ProjectDownloadAccordionItem } from "./ProjectDownloadAccordionItem";
import { ProjectDownloadSortDropdown } from "./ProjectDownloadSortDropdown";
import { ProjectDownloadAccordionItemSublist } from "./ProjectDownloadAccordionItemSublist";

export const ModalCheckboxesBlock = ({
  blockTitle,
  itemsData,
  setItemsData,
  showProducts,
}) => {
  const [open, setOpen] = useState("");
  const [sublistItemOpen, setSublistItemOpen] = useState("");

  const toggle = useCallback((id) => {
    setOpen((prevValue) => (prevValue === id ? "" : id));
  }, []);

  const toggleSublist = useCallback((id) => {
    setSublistItemOpen((prevValue) => (prevValue === id ? "" : id));
  }, []);

  return (
    <div className="flex-xs-column d-flex gap-2">
      <div className="d-flex justify-content-between align-items-center">
        <h3 className="text-uppercase mb-0 font20">{blockTitle}</h3>
        <ProjectDownloadSortDropdown showProducts={showProducts} />
      </div>
      <Accordion open={open} toggle={toggle}>
        {itemsData &&
          itemsData.map((item, index) => (
            <ProjectDownloadAccordionItem
              open={open}
              title={item.title}
              key={item.title + index}
              toggle={toggle}
              checked={item.checked}
              setItemsData={setItemsData}
              hasContent={item.checkboxContent.length > 0}
              itemsData={itemsData}
              id={item.id}
            >
              {!item.skipContentRender && (
                <ProjectDownloadAccordionItemSublist
                  item={item}
                  toggleSublist={toggleSublist}
                  sublistItemOpen={sublistItemOpen}
                  setItemsData={setItemsData}
                  itemsData={itemsData}
                />
              )}
            </ProjectDownloadAccordionItem>
          ))}
      </Accordion>
    </div>
  );
};
