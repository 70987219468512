import { replaceTempUUid } from "api/exchanges/exchangeUtils";

export const actionOnGetCreateNodeResponse = ({
  response,
  queue,
  replaceKeysMap,
}) => {
  if (queue.size !== 0 && response.data?.createProjectNode) {
    const idToReplace = `temp__${response?.operation?.variables?.input?.parentId}__${response?.operation?.variables?.referencePosition}`;
    const replaceBy = response.data?.createProjectNode?.id;
    replaceKeysMap.set(idToReplace, replaceBy);
  }
};

export const exeCreateNodeFromQueue = ({
  operation,
  client,
  queue,
  replaceKeysMap,
}) => {
  const parentId = operation.variables?.input?.parentId;
  if (parentId && parentId.startsWith("temp_")) {
    operation.variables.input.parentId = replaceTempUUid({
      id: parentId,
      replaceKeysMap,
    });
  }
  client.reexecuteOperation({
    ...operation,
    context: {
      ...operation.context,
      requestPolicy: "network-only",
      fromQueue: true,
    },
  });
  queue.delete(operation);
};
