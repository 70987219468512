import React, { useEffect, useCallback } from "react";
import * as Scrivito from "scrivito";
import { Checkbox } from "components/Inputs";
import { useProjectTreeContext } from "providers/ProjectProvider";
import { translate } from "utils";

export const ToggleShowPictures = Scrivito.connect(() => {
  const { showProductPictures, setShowProductPictures } =
    useProjectTreeContext();

  useEffect(() => {
    const storedValue = sessionStorage.getItem("showProductPictures");
    if (storedValue !== null) {
      setShowProductPictures(storedValue === "true");
    }
  }, [setShowProductPictures]);

  const handleChange = useCallback(() => {
    setShowProductPictures((prev) => {
      const newValue = !prev;
      sessionStorage.setItem("showProductPictures", newValue.toString());
      return newValue;
    });
  }, [setShowProductPictures]);

  return (
    <div className="d-flex gap-2">
      <Checkbox
        checked={showProductPictures}
        onChange={handleChange}
        wrapperClassName="cursor-pointer"
      />
      {translate("PROJECT_STRUCTURE_SHOW_PRODUCT_PICTURES")}
    </div>
  );
});
