export const walkTree = ({
  node,
  childAccessor = (arg) => arg?.nodes,
  skipChildren,
  callback = () => {},
}) => {
  if (!node) {
    return;
  }
  callback(node);

  if (
    skipChildren &&
    typeof skipChildren === "function" &&
    skipChildren(node)
  ) {
    return;
  }
  const nodes = childAccessor(node);
  if (nodes) {
    nodes.forEach((n) => {
      walkTree({ node: n, childAccessor, callback });
    });
  }
};
