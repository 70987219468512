import { replaceTempUUid } from "api/exchanges/exchangeUtils";

export const exeDeleteNodeFromQueue = ({
  operation,
  client,
  queue,
  replaceKeysMap,
}) => {
  const id = operation.variables?.id;

  if (id && id.startsWith("temp_")) {
    operation.variables.id = replaceTempUUid({
      id,
      replaceKeysMap,
    });
  }

  client.reexecuteOperation({
    ...operation,
    context: {
      ...operation.context,
      requestPolicy: "network-only",
      fromQueue: true,
    },
  });
  queue.delete(operation);
};
