import { nodeTypes } from "variables";

export const disableDrop = ({ parentNode, dragNodes, index }) => {
  const dragNode = dragNodes[0];

  // Cases where drop is disabled
  // if new parent node is a wizard item
  const toWizard = parentNode.data?.isWizard;
  // if new parent node is the tree root and the drag node is a configuration
  const configToRoot =
    parentNode.data.kind === nodeTypes.ROOT &&
    dragNode?.data?.kind === nodeTypes.CONFIGURATION;
  // if new parent node is the same as drag node's parent and drag node is configuration
  const configToSameParent =
    parentNode.data.id === dragNode.data.parentId &&
    dragNode.data.kind === nodeTypes.CONFIGURATION;
  // if new parent node has a level less than 0
  const isNotValidNodeLevel = parentNode.level < 0;
  // if the drag node is a folder being dropped between or above configurations
  const folderBetweenOrAboveConfigs =
    index > 0 &&
    parentNode?.children[index]?.data?.kind === nodeTypes.CONFIGURATION &&
    dragNode?.data?.kind !== nodeTypes.CONFIGURATION;
  // if the drag node is a configuration being dropped between or below folders
  const configBetweenOrBelowFolders =
    index > 0 &&
    parentNode?.children[index - 1]?.data?.kind !== nodeTypes.CONFIGURATION &&
    parentNode?.children[index]?.data?.kind !== nodeTypes.CONFIGURATION &&
    dragNode?.data?.kind === nodeTypes.CONFIGURATION;
  const configBetweenConfigs =
    index > 0 &&
    parentNode?.children[index]?.data?.kind === nodeTypes.CONFIGURATION &&
    dragNode?.data?.kind === nodeTypes.CONFIGURATION;

  if (
    isNotValidNodeLevel ||
    folderBetweenOrAboveConfigs ||
    configBetweenOrBelowFolders ||
    configBetweenConfigs ||
    toWizard ||
    configToRoot ||
    configToSameParent
  ) {
    return true;
  }
  return false;
};
