import React, { useState, useRef, memo } from "react";
import { useClickOutside } from "hooks";
import { Input } from "reactstrap";
import cx from "classnames";
import s from "../Styles.module.scss";

export const NodeTitle = memo(
  ({
    title,
    handleUpdateNode,
    isEditMode,
    setIsEditMode,
    handleToggleEditMode,
    isRootNode,
    isWizard,
  }) => {
    const [inputValue, setInputValue] = useState(title || "");
    const inputRef = useRef();

    const onUpdateNode = () => {
      if (inputValue !== title) {
        handleUpdateNode(inputValue);
      }
      setIsEditMode(false);
    };

    const handleInputKeyDown = (e) => {
      if (e.key === "Enter") {
        onUpdateNode();
      }
    };

    useClickOutside(inputRef, () => onUpdateNode(), isEditMode);

    return (
      <>
        {isEditMode ? (
          <div
            className={cx(s.inputWrapper, "d-flex align-items-center")}
            ref={inputRef}
          >
            <Input
              className="ms-2 my-1 fw-bolder text-gray-700 ps-2 fs-md"
              type="text"
              bsSize="sm"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              maxLength={30}
              onKeyDown={handleInputKeyDown}
              data-testid="folder-title-input"
            />
            <div
              className={cx(
                s.inputLengthLabel,
                "text-gray-400 d-flex flex-nowrap",
              )}
            >
              <span className="fw-bolder">{inputValue.length}</span>
              <span>&nbsp;/ {30}</span>
            </div>
          </div>
        ) : (
          <span
            onClick={handleToggleEditMode}
            className={cx("mx-1 fw-bold fs-md text-truncate", s.title, {
              "cursor-pointer": !isRootNode && !isWizard,
            })}
            data-testid="folder-title"
          >
            {title}
          </span>
        )}
      </>
    );
  },
);
