import { gql } from "@urql/core";
import { questionnaireFragment, wizardBodyFragment } from "api/queries";

const WizardStepMut = gql`
  mutation (
    $answersLists: [WizardListsAnswers!]
    $step: Int!
    $nodeId: String!
  ) {
    wizardStep(step: $step, answersLists: $answersLists, nodeId: $nodeId) {
      ...wizardBodyFragment
      currentQuestionnaire {
        ...questionnaireFragment
      }
      questionnaireLists {
        ...questionnaireFragment
      }
    }
  }
  ${questionnaireFragment}
  ${wizardBodyFragment}
`;

const createWizardStepQuestionnaire = gql`
  mutation ($nodeId: String!, $step: Int!) {
    createWizardStepQuestionnaire(nodeId: $nodeId, step: $step) {
      ...questionnaireFragment
    }
  }
  ${questionnaireFragment}
`;

const changeWizardActiveQuestionnaireMut = gql`
  mutation ($nodeId: String!, $step: Int!, $id: String!) {
    changeWizardActiveQuestionnaireMut(nodeId: $nodeId, step: $step, id: $id)
    ...wizardBodyFragment
    currentQuestionnaire {
      ...questionnaireFragment
    }
    questionnaireLists {
      ...questionnaireFragment
    }
  }
  ${questionnaireFragment}
  ${wizardBodyFragment}
`;

const updateWizardStepQuestionnaireMut = gql`
  mutation (
    $nodeId: String!
    $step: Int!
    $id: String!
    $questionnaire: [WizardListsAnswers!]
  ) {
    updateWizardStepQuestionnaire(
      nodeId: $nodeId
      step: $step
      id: $id
      questionnaire: $questionnaire
    ) {
      id
      title
    }
  }
`;

const deleteWizardStepQuestionnaireMut = gql`
  mutation ($nodeId: String!, $step: Int!, $id: String!) {
    deleteWizardStepQuestionnaire(nodeId: $nodeId, step: $step, id: $id) {
      id
      position
    }
  }
`;

const duplicateWizardStepQuestionnaireMut = gql`
  mutation (
    $nodeId: String!
    $step: Int!
    $id: String!
    $duplicationFactor: Int!
  ) {
    duplicateWizardStepQuestionnaires(
      id: $id
      nodeId: $nodeId
      step: $step
      duplicationFactor: $duplicationFactor
    ) {
      identifier
      title
      id
      position
    }
  }
`;

export {
  WizardStepMut,
  createWizardStepQuestionnaire,
  updateWizardStepQuestionnaireMut,
  changeWizardActiveQuestionnaireMut,
  deleteWizardStepQuestionnaireMut,
  duplicateWizardStepQuestionnaireMut,
};
