import { toast } from "react-toastify";
import { createClient, fetchExchange, mapExchange } from "urql";
import { cacheExchange } from "@urql/exchange-graphcache";
import { devtoolsExchange } from "@urql/devtools";
import { authExchange } from "@urql/exchange-auth";
import { getPageObjAsync } from "utils";
import * as Scrivito from "scrivito";

import { projectSideEffects } from "api/sideEffects/project";
import { wizardSideEffects } from "api/sideEffects/wizard";

import { refreshReq } from "api/auth";
import { pipe, tap } from "wonka";
import { useStateLoaderContext } from "providers/PageLoadStateProvider";
import { pauseExchange } from "api/exchanges/pauseExchange";
import schema from "./schema.json";

const ABUrl = process.env.ASSISTIVE_BACKEND_URL;
let requestType = "";

export const useCreateUrqlClient = () => {
  const { setIsFetching, setIsLoaderXCircle } = useStateLoaderContext();

  const firstRenderExchange = ({ forward }) => {
    const requests = new Set();

    return (operations$) => {
      const sharedOps$ = pipe(
        operations$,
        tap((operation) => {
          if (operation.kind === "query" || operation.kind === "mutation") {
            requests.add(operation.key);
          }
        }),
      );
      return pipe(
        forward(sharedOps$),
        tap((response) => {
          if (
            response.operation.kind === "query" ||
            response.operation.kind === "mutation"
          ) {
            requests.delete(response.operation.key);
            if (requests.size === 0) {
              setIsLoaderXCircle(false);
              setIsFetching(false);
            }
          }
        }),
      );
    };
  };

  const exchanges = [
    cacheExchange({
      schema: schema.data,
      keys: {
        constraint: () => null,
        priceType: () => null,
        projectStatusesType: (data) => data.status || null,
        projectList: () => null,
        projectListStatistics: () => null,
        ProjectListFiltersResp: () => null,
        productList: () => null,
        Questionnaire: () => null,
        wizardsInfo: (data) => `${data.nodeId},${data.step}`,
        WizardQuestion: () => null,
        WizardQuestionField: () => null,
        CountryList: () => null,
        CountryListItem: () => null,
        departmentsResponse: () => null,
      },
      cacheExchange,
      updates: {
        Query: {
          ...wizardSideEffects.queries,
          ...projectSideEffects.queries,
        },
        Mutation: {
          ...projectSideEffects.mutations,
          ...wizardSideEffects.mutations,
        },
      },
      optimistic: {
        ...projectSideEffects.optimistic,
      },
    }),
    mapExchange({
      onOperation(operation) {
        if (operation?.kind === "teardown") {
          setTimeout(() => {
            setIsFetching(false);
          }, 0);
        }
        if (!["query", "mutation"].includes(operation?.kind)) {
          return null;
        }

        if (operation.context.context?.requestType) {
          requestType = operation.context.context?.requestType;
        }
        // SetTimeout uses for let a component render fully before setting state
        setTimeout(() => {
          setIsFetching(true);
          if (operation.context?.loaderType === "xCircle") {
            setIsLoaderXCircle(true);
          }
        }, 0);
      },
      onResult() {
        setIsFetching(false);
      },
      async onError(error) {
        setIsFetching(false);
        if (process.env.TROX_ENVIRONMENT !== "production") {
          console.groupCollapsed("onError"); // eslint-disable-line
          console.dir(error); // eslint-disable-line
          console.groupEnd(); // eslint-disable-line
        }

        const { statusText, status } = error.response || {};

        if (status >= 200 && status < 300) {
          // weird but sometimes it happened on the CI
          return;
        }
        let errorText = "Unknown error";
        const { message } = error.networkError || error || {};

        if (status && statusText) {
          errorText = `${status}: ${statusText}`;
        } else if (message) {
          errorText = message;
        }
        if (!requestType) {
          toast.error(errorText, {
            type: "error",
            theme: "colored",
          });
        }

        if (status === 403 || status === 404) {
          const redirectToProjectListPage = async () => {
            const res = await getPageObjAsync("ProjectList");
            Scrivito.navigateTo(res);
          };
          return redirectToProjectListPage();
        }

        if (status === 500) {
          const redirectToErrorPage = async () => {
            const res = await getPageObjAsync("ErrorPage");
            Scrivito.navigateTo(res);
          };

          return redirectToErrorPage();
        }
      },
    }),
    authExchange(() => ({
      addAuthToOperation(operation) {
        return operation;
      },
      didAuthError(error, _operation) {
        return (
          error?.response?.status === 401 || error?.response?.status === 403
        );
      },
      async refreshAuth() {
        return refreshReq();
      },
    })),
    firstRenderExchange,
    pauseExchange,
    fetchExchange,
  ];
  if (
    process.env.TROX_ENVIRONMENT === "development" ||
    process.env.NODE_ENV === "test"
  ) {
    exchanges.splice(1, 0, devtoolsExchange);
  }

  return {
    client: createClient({
      url: `${ABUrl}/graphql`,
      requestPolicy: "cache-and-network",
      exchanges,
      fetchOptions: {
        credentials: "include",
      },
    }),
  };
};
