import React from "react";
import { Button } from "components/Button";
import cx from "classnames";
import { UncontrolledTooltip } from "reactstrap";

export const IconButton = ({
  onClick,
  className,
  disabled,
  iconClassName,
  icon,
  title,
  testId,
  tooltipText,
  iconPrefix,
  color,
  id,
}) => (
  <>
    <Button
      id={id}
      square={true}
      color={color || "tertiary"}
      iconPrefix={iconPrefix || "trox"}
      iconClassName={iconClassName || "icon-16"}
      classNames={cx("p-0 m-0", className, {
        "cursor-pointer": !disabled,
      })}
      icon={icon}
      title={title}
      data-testid={testId}
      onClick={(e) => !disabled && onClick(e)}
    />
    <UncontrolledTooltip target={id} placement="top">
      {tooltipText}
    </UncontrolledTooltip>
  </>
);
