export const productConfigurator = {
  PRODUCT_CONFIGURATOR_CONFIGURATION: "Configuration",
  PRODUCT_CONFIGURATOR_BIM: "BIM Data",
  PRODUCT_CONFIGURATOR_CERTIFICATE: "Certificate",
  PRODUCT_CONFIGURATOR_DOWNLOADS: "Downloads",
  PRODUCT_CONFIGURATOR_INFORMATION: "Product Information",
  PRODUCT_CONFIGURATOR_INPUT: "Inputs",
  PRODUCT_CONFIGURATOR_STRATEGY: "Input Strategy",
  PRODUCT_CONFIGURATOR_MANUALS: "Manuals",
  PRODUCT_CONFIGURATOR_MODAL_ACCESSOIRE_TEXT: "Accessoires Spec Text",
  PRODUCT_CONFIGURATOR_MODAL_SPECIFICATION_TEXT: "Specification Text",
  PRODUCT_CONFIGURATOR_OVERVIEW: "Product Overview",
  PRODUCT_CONFIGURATOR_PRODUCT_SPECIFICATION: "Product Specification",
  PRODUCT_CONFIGURATOR_RESULTS: "Results",
  PRODUCT_CONFIGURATOR_MEDIA: "Media",
  PRODUCT_CONFIGURATOR_3D: "3D preview",
  PRODUCT_CONFIGURATOR_3D_NOT_AVAILABLE:
    "3D preview is not available for this product",
  PRODUCT_CONFIGURATOR_RESULTS_MATRIX: "Results Matrix",
  PRODUCT_CONFIGURATOR_SPECIFICATION_TEXT: "Specification Text",
  PRODUCT_CONFIGURATOR_TECHNICAL_DESIGN: "Technical Design",
  PRODUCT_CONFIGURATOR_VIEW_SPECIFICATION: "View Specification",
  PRODUCT_CONFIGURATOR_REPORT_INPUT_LABEL: "Input Data",
  PRODUCT_CONFIGURATOR_REPORT_STRATEGY: "Strategy",
  PRODUCT_CONFIGURATOR_REPORT_RESULTS_LABEL: "Results",
  PRODUCT_CONFIGURATOR_REPORT_ACOUSTING_LABEL: "Acousting Results",
  PRODUCT_CONFIGURATOR_REPORT_DESCRRIPTION: "Description",
  PRODUCT_CONFIGURATOR_REPORT_DOWNLOAD: "Download Product Report",
  PRODUCT_CONFIGURATOR_XBIM_REPORT_DOWNLOAD: "Download X-BIM",
  PRODUCT_CONFIGURATOR_REPORT_SCHEMATIC_SIDE_VIEW_LABEL: "Schematic side view",
  PRODUCT_CONFIGURATOR_UPDATE_CONFIGURATION: "Update Configuration",
  PRODUCT_CONFIGURATOR_PRODUCT_CONFIGURATION: "Product Configuration",
  PRODUCT_CONFIGURATOR_ADD_TO_OTHER_PROJECT: "Add to other Project",
  PRODUCT_CONFIGURATOR_BACK_TO_PROJECT_STRUCTURE: "Back to Project Structure",
  PRODUCT_CONFIGURATOR_PRODUCT_FINDER: "Product Finder",
  PRODUCT_CONFIGURATOR_DONE: "Done",
  SAVE_PRODUCT_DATA_CONFIRMATION_ALERT: "Unsaved changes will be lost",
  PRODUCT_CONFIGURATOR_IMAGE: "Image",
  PRODUCT_CONFIGURATOR_TECHNICAL_RESULTS: "Technical results",
  PRODUCT_CONFIGURATOR_RESET_BUTTON_TOOLTIP: "Reset",
  PRODUCT_CONFIGURATOR_SHOW_ALL_OPTIONS_BUTTON_TOOLTIP: "Show all options",
  PRODUCT_CONFIGURATOR_APPLY_SETTING_BUTTON_TOOLTIP: "Confirm Setting",
};
