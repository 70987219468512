import React, { useMemo, memo } from "react";
import cx from "classnames";
import s from "./Styles.module.scss";

export const useIndentComponent = ({ parent, rowIndex, level, id }, options) =>
  useMemo(() => {
    const indicators = getSpacers({
      parent,
      rowIndex,
      level,
    });

    const IndentRenderer = options.isIndentCompact
      ? CompactIndentComponent
      : IndentComponent;

    return (
      <div className="d-flex flex-row h-100 position-absolute start-0">
        {indicators.map((indicator, index) => (
          <IndentRenderer
            indicator={indicator}
            key={`${id}${index}`}
            width={options.indentSize}
          />
        ))}
      </div>
    );
  }, [parent, rowIndex, level, id, options]);

const IndentComponent = memo(({ indicator, key, width }) => (
  <div
    key={key}
    className={cx("d-flex flex-row h-100", s.spacerContainer)}
    style={{ width }}
  >
    <div className="w-50 h-100"></div>
    <div className="d-flex flex-column w-50 h-100">
      <div
        className={cx("h-50 w-100 border-gray-400", {
          "border-bottom":
            indicator === "last-sibling" || indicator === "sibling",
          "border-start": !(indicator === "none"),
        })}
      ></div>
      <div
        className={cx("h-50 w-100 border-gray-400", {
          "border-start": indicator === "sibling" || indicator === "ancestor",
        })}
      ></div>
    </div>
  </div>
));

const CompactIndentComponent = memo(({ indicator, key, width }) => (
  <div
    key={key}
    className={cx("d-flex flex-row h-100", s.spacerContainer)}
    style={{ width }}
  >
    <div className="d-flex flex-column w-100 h-100">
      <div
        className={cx("h-50 w-100 border-gray-400", {
          "border-bottom":
            indicator === "last-sibling" || indicator === "sibling",
          "border-start": !(indicator === "none"),
        })}
      ></div>
      <div
        className={cx("h-50 w-100 border-gray-400", {
          "border-start": indicator === "sibling" || indicator === "ancestor",
        })}
      ></div>
    </div>
  </div>
));

const getSpacers = ({ parent, rowIndex, level }) => {
  if (parent.level < 0) {
    return [];
  }

  const spacerList = [];
  let currentParent = parent;

  while (currentParent && currentParent.level >= 0) {
    const lastChildRowIndex =
      currentParent.children[currentParent.children.length - 1].rowIndex;

    if (level - currentParent.level === 1) {
      // Direct parent cases
      spacerList.push(
        lastChildRowIndex > rowIndex ? "sibling" : "last-sibling",
      );
    } else {
      // Ancestor cases
      spacerList.push(lastChildRowIndex > rowIndex ? "ancestor" : "none");
    }

    currentParent = currentParent.parent;
  }

  return spacerList.reverse(); // Reverse to maintain original order
};
