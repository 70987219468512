import { translate } from "utils";
import { nodeTypes } from "variables";

export const genNewNodeTitle = (
  copiedNodeTitle,
  siblings = [],
  isNewLayer = false,
) => {
  const nodeTitle = isNewLayer
    ? translate("PROJECT_NEW_NODE")
    : copiedNodeTitle;

  const regExpNodeNumber = /\((\d+)\)$/;
  const arrOfSameTitles = [];

  siblings &&
    siblings.forEach((siblingNode) => {
      siblingNode.title === nodeTitle &&
        arrOfSameTitles.push(siblingNode.title);
    });

  // Case: New layer-node
  if (!siblings || !siblings.length) {
    return nodeTitle;
  }

  // Case: New node in a layer or there's not nodes with the same title
  if (!arrOfSameTitles.length) {
    return nodeTitle;
  }

  const arrOfTitleNumbers = [];
  siblings.forEach((siblingNode) => {
    if (siblingNode.kind !== nodeTypes.CONFIGURATION) {
      const matchedTitleNumber = siblingNode.title.match(regExpNodeNumber)?.[1];
      if (matchedTitleNumber) {
        arrOfTitleNumbers.push(Number(matchedTitleNumber));
      }
    }
  });

  // Case: Only one node with same titles "title (1)"
  if (!regExpNodeNumber.test(nodeTitle) && !arrOfTitleNumbers.length) {
    return `${nodeTitle} (1)`;
  }

  const changedNumberTitle = nodeTitle.replace(regExpNodeNumber, "").trim();

  // Case: Node(s) with same titles and with number. "title (n+1)"
  return `${changedNumberTitle} (${Math.max(...arrOfTitleNumbers) + 1})`;
};
