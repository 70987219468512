import * as Scrivito from "scrivito";
import React, { memo } from "react";
import { getHomepage } from "utils";
import cx from "classnames";

const WizardLinkComponent = Scrivito.connect(({ params, text, disabled }) => (
  <Scrivito.LinkTag
    onClick={() => {
      !disabled &&
        Scrivito.navigateTo(getHomepage()?.get("configWizardObjRefs")?.[1], {
          params,
        });
    }}
    className={cx("ms-2", { "cursor-not-allowed": disabled })}
  >
    {text}
  </Scrivito.LinkTag>
));

export const WizardLink = memo(({ params, text, disabled }) => (
  <WizardLinkComponent params={params} text={text} disabled={disabled} />
));
