import { nodeTypes } from "variables";

export const getScrollToIndex = (node, isPastingConfig = false) => {
  if (isPastingConfig) {
    return getProductScrollTo(node);
  }
  const lastDecendant = getLastDecendant(node);
  return lastDecendant.rowIndex + 1;
};

const getLastDecendant = (node) => {
  if (node.children?.length > 0) {
    return getLastDecendant(node.children[node.children.length - 1]);
  }
  return node;
};

const getProductScrollTo = (node) => {
  if (node.children?.length > 0) {
    let index = 0;
    for (let i = 0; i < node.children.length; i += 1) {
      if (node.children[i].data.kind !== nodeTypes.CONFIGURATION) {
        break;
      }
      index = i;
    }
    return node.children[index].rowIndex + 1;
  }
  return node.rowIndex;
};
