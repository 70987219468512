import { addConfigurationToNode } from "api/cacheUtils/treeNodeUpdates/utils";
import { removeNodeById } from "./nodeUpdate";

export const moveConfig = (projectTree, result) => {
  const { nodeId, id, systemPath } = result;

  const removedNode = removeNodeById({
    nodes: projectTree,
    idToRemove: id,
  });

  addConfigurationToNode({
    nodes: projectTree,
    newConfiguration: {
      ...removedNode,
      parentId: nodeId,
      systemPath: systemPath || removedNode.systemPath,
    },
    parentId: nodeId,
  });
  return projectTree;
};
