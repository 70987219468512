import React from "react";
import { Accordion, AccordionItem } from "reactstrap";
import { CheckboxAccordionContent } from "./CheckboxAccordionContent";
import { AccordionItemFileDescription } from "./AccordionItemFileDescription";

export const ProjectDownloadAccordionItemSublist = ({
  item,
  toggleSublist,
  sublistItemOpen,
  setItemsData,
  itemsData,
}) =>
  item.checkboxContent.map((contentItem, index) => (
    <Accordion
      key={contentItem.title + index}
      open={sublistItemOpen}
      toggle={toggleSublist}
    >
      <AccordionItem className="d-flex justify-content-between">
        <CheckboxAccordionContent
          title={contentItem.title}
          itemsData={itemsData}
          checked={contentItem.checked}
          setItemsData={setItemsData}
          id={contentItem.id}
        />
        <AccordionItemFileDescription
          fileDescription={contentItem.fileDescription}
          contentLength={contentItem.contentLength}
          extension={contentItem.extension}
          contentUrl={contentItem.contentUrl}
        />
      </AccordionItem>
    </Accordion>
  ));
