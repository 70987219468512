import React from "react";
import { Table } from "reactstrap";
import { useVirtualizer } from "@tanstack/react-virtual";
import { translate } from "utils";
import { ProductListTableRowSkeleton } from "components/ProductListOfProject/SubComponents/ProductListTableRowSkeleton";

export const VirtualizedModalTable = ({
  columns,
  products,
  renderRow,
  fetchingProductList,
  skeletonCount,
  estimateSize = 58,
  overscan = 7,
  commissioningProduct,
  isLastPage,
  tableRef,
  fetchingPrices,
  commissionFetching,
}) => {
  const rowVirtualizer = useVirtualizer({
    count: !fetchingProductList ? products.length : skeletonCount,
    getScrollElement: () => tableRef?.current,
    estimateSize: () => estimateSize,
    overscan,
  });

  return (
    <div ref={tableRef} style={{ overflowY: "auto", height: "50vh" }}>
      <Table>
        <thead>
          <tr>
            {columns.map((column, index) => (
              <th
                className="px-2 py-1"
                key={index}
                style={{ width: column.width }}
              >
                {column.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody
          style={{
            height: `${rowVirtualizer.getTotalSize()}px`,
            width: "100%",
            position: "relative",
          }}
        >
          {rowVirtualizer.getVirtualItems().length > 0 ? (
            <>
              {rowVirtualizer.getVirtualItems().map((virtualItem) => (
                <tr
                  key={virtualItem.index}
                  style={{
                    position: "absolute",
                    display: "flex",
                    width: "100%",
                    height: `58px`,
                    transform: `translateY(${virtualItem.start}px)`,
                  }}
                >
                  {!fetchingProductList ? (
                    renderRow(products[virtualItem.index], fetchingPrices)
                  ) : (
                    <ProductListTableRowSkeleton />
                  )}
                </tr>
              ))}
              {isLastPage && commissioningProduct && (
                <tr
                  key="last-row"
                  style={{
                    position: "absolute",
                    display: "flex",
                    width: "100%",
                    height: `58px`,
                    transform: `translateY(${rowVirtualizer.getTotalSize()}px)`,
                  }}
                >
                  {commissionFetching ? (
                    <ProductListTableRowSkeleton />
                  ) : (
                    renderRow(commissioningProduct, fetchingPrices)
                  )}
                </tr>
              )}
            </>
          ) : (
            <>
              <tr>
                <td
                  className="text-center"
                  colSpan="6"
                  style={{ height: "55vh" }}
                >
                  {translate("PROJECT_LIST_NO_DATA")}
                </td>
              </tr>
            </>
          )}
        </tbody>
      </Table>
    </div>
  );
};
