import React from "react";
import { createPortal } from "react-dom";
import * as Scrivito from "scrivito";
import { isBrowserEnv } from "utils/isWindowObjAvailable";
import cx from "classnames";
import s from "./LeftSidebar.module.scss";

export const LeftSidebarPortal = Scrivito.connect(({ children }) => {
  if (!isBrowserEnv()) {
    // Return null if running in a server environment
    return null;
  }
  const target = document.getElementById("portal_left_sidebar");

  return createPortal(
    <div
      className={cx(
        s.leftSidebarPortalWrapperExtended,
        "d-flex flex-row flex-shrink-0 bg-gray-700 position-relative",
      )}
    >
      {children}
    </div>,
    target,
  );
});
