import React, { memo } from "react";
import { Icon } from "components/Icon/Icon";
import cx from "classnames";
import s from "../Styles.module.scss";
import { IconButton } from "../Buttons/IconButton";

export const LeftControls = memo(
  ({
    isWizard,
    hasChildren,
    handleToggleNode,
    isNodeOpen,
    handleCreateNode,
    options,
    isPasteMode,
    translations,
    nodeId,
  }) => (
    <>
      {hasChildren && (
        <Icon
          onClick={(e) => {
            e.stopPropagation();
            !isPasteMode && handleToggleNode();
          }}
          iconName={isNodeOpen ? "fa-chevron-down" : "fa-chevron-right"}
          className={cx(
            "icon-16  mx-1 cursor-pointer position-relative",
            s.folderIcon,
          )}
        />
      )}
      {!isWizard && !options.createNodeDisabled && (
        <IconButton
          testId="addFolderButton"
          id={`addFolder${nodeId}`}
          icon="plus-regular"
          iconClassName="icon-12"
          onClick={(e) => {
            e.stopPropagation();
            handleCreateNode();
          }}
          className={cx("mx-1 p-0_5 bg-white position-relative z-1", {
            "cursor-not-allowed opacity-20 pe-none": isPasteMode,
          })}
          tooltipText={translations.folder.addLayer}
          disabled={isPasteMode}
        />
      )}
    </>
  ),
);

export const RightControls = memo(
  ({
    isWizard,
    isWizardRoot,
    isPasteMode,
    handleToggleEditMode,
    handleCopyNode,
    handleDuplicateNode,
    handleDeleteNode,
    options,
    isNodeOptimistic,
    translations,
    nodeId,
    hasChildren,
    openDownloadModal,
  }) => (
    <div
      className={cx(
        "d-flex flex-row align-items-center justify-content-end",
        s.borderBetween,
      )}
    >
      {!isWizard && !options.editNodeDisabled && (
        <IconButton
          testId="editFolderButton"
          id={`editFolder${nodeId}`}
          icon="pencil-light"
          iconClassName="icon-16"
          onClick={handleToggleEditMode}
          className={cx("px-2 border-0", {
            "cursor-not-allowed opacity-20 pe-none": isPasteMode,
          })}
          tooltipText={translations.folder.editNode}
          disabled={isPasteMode}
        />
      )}
      {(!isWizard || isWizardRoot) && !options.duplicateNodeDisabled && (
        <IconButton
          id={`duplicateFolder${nodeId}`}
          tooltipText={translations.folder.duplicateNode}
          testId="duplicateFolderButton"
          icon="duplicate-light"
          iconClassName="icon-16"
          onClick={handleDuplicateNode}
          className={cx("px-2 border-0", {
            "cursor-not-allowed opacity-20 pe-none": isPasteMode,
          })}
          disabled={isPasteMode}
        />
      )}
      {!options.copyNodeDisabled && (
        <IconButton
          id={`copyFolder${nodeId}`}
          tooltipText={translations.folder.copyNode}
          testId="copyIcon"
          icon="copy-light"
          iconClassName="icon-16"
          onClick={handleCopyNode}
          className={cx("px-2 border-0", {
            "cursor-not-allowed opacity-20 pe-none": isPasteMode,
          })}
          disabled={isPasteMode}
        />
      )}
      {!options.downloadModalDisabled && isWizardRoot && hasChildren && (
        <IconButton
          id={`downloadModal${nodeId}`}
          tooltipText={translations.product.download}
          testId="downloadIcon"
          iconPrefix="trox"
          icon="arrow-down-bracket-light"
          iconClassName="icon-16"
          onClick={openDownloadModal}
          className={cx("px-2 border-0", {
            "cursor-not-allowed opacity-20 pe-none": isPasteMode,
          })}
          disabled={isPasteMode}
        />
      )}
      {(!isWizard || isWizardRoot) && !options.deleteNodeDisabled && (
        <IconButton
          id={`deleteFolder${nodeId}`}
          tooltipText={translations.folder.deleteNode}
          testId="deleteIcon"
          icon="trash-can-light"
          iconClassName="icon-16"
          onClick={handleDeleteNode}
          className={cx("px-2 border-0", {
            "cursor-not-allowed opacity-20 pe-none":
              isNodeOptimistic || isPasteMode,
          })}
          disabled={isPasteMode || isNodeOptimistic}
        />
      )}
    </div>
  ),
);
