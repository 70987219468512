import { nodeTypes } from "variables";

export const findNodeById = ({ nodes, parentId }) => {
  for (const node of nodes) {
    if (node.id === parentId || parentId === null) {
      return node;
    }
    if (node.nodes?.length) {
      const found = findNodeById({ nodes: node.nodes, parentId });
      if (found) {
        return found;
      }
    }
  }
  return null;
};

const addNodeToParent = ({ parentNode, newNode, referencePosition = null }) => {
  parentNode.nodes = parentNode.nodes || [];

  let maxRefPos = 0;
  parentNode.nodes.forEach((node) => {
    if (
      node.kind !== nodeTypes.CONFIGURATION &&
      node.referencePosition > maxRefPos
    ) {
      maxRefPos = node.referencePosition;
    }
  });

  if (maxRefPos < referencePosition || referencePosition === null) {
    newNode.referencePosition = maxRefPos + 1;
    parentNode.nodes.push(newNode);
    return;
  }

  let insertIndex = 0;
  parentNode.nodes.forEach((node, index) => {
    if (node.referencePosition === referencePosition) {
      insertIndex = index;
    }
    if (
      node.kind !== nodeTypes.CONFIGURATION &&
      node.referencePosition >= referencePosition
    ) {
      node.referencePosition += 1;
    }
  });
  newNode.referencePosition = referencePosition;
  parentNode.nodes.splice(insertIndex, 0, newNode);
};

export const addNodeByParentId = ({
  nodes,
  parentId,
  newNode,
  referencePosition = null,
}) => {
  if (!parentId) {
    addNodeToParent({ parentNode: nodes[0], newNode, referencePosition });
  } else {
    const parentNode = findNodeById({ nodes, parentId });

    if (!parentNode) {
      console.error(`Parent node with ID ${parentId} not found.`);
      return false;
    }

    addNodeToParent({ parentNode, newNode, referencePosition });
  }
  return true;
};

export const updateNodeIds = ({ nodes, parentId = "root" }) => {
  nodes.forEach((node) => {
    if (node.kind === nodeTypes.CONFIGURATION) {
      node.id = `temp__${node.id}`;
    } else {
      node.id = `temp__${parentId}__${node.referencePosition}`;
    }
    node.parentId = parentId;
    if (node.nodes && node.nodes.length > 0) {
      updateNodeIds({ nodes: node.nodes, parentId: node.id });
    }
  });
};

export const addConfigurationToNode = ({
  nodes,
  parentId,
  newConfiguration,
}) => {
  const parentNode = findNodeById({ nodes, parentId });

  if (!parentNode) {
    console.error(`Node with ID ${parentId} not found.`);
    return false;
  }

  parentNode.nodes = parentNode.nodes || [];
  let configIndex = 0;
  parentNode.nodes.forEach((node, index) => {
    if (node.kind === nodeTypes.CONFIGURATION) {
      configIndex = index + 1;
    }
  });

  parentNode.nodes.splice(configIndex, 0, {
    ...newConfiguration,
    id: newConfiguration.id || newConfiguration.productId,
    kind: nodeTypes.CONFIGURATION,
  });

  return true;
};

export const deleteById = (nodes, idToDelete, isConfiguration = false) => {
  for (let i = 0; i < nodes.length; i += 1) {
    const node = nodes[i];

    if (node.id === idToDelete) {
      nodes.splice(i, 1);
      if (isConfiguration) {
        return true;
      }
      for (let j = i + 1; j < nodes.length; j += 1) {
        nodes[j].referencePosition -= 1;
      }
      return true;
    }

    if (node.nodes && node.nodes.length > 0) {
      const found = deleteById(node.nodes, idToDelete);
      if (found) {
        return true;
      }
    }
  }

  return false;
};
