import { nodeSizes } from "variables";

export const defaultOptions = {
  dndDisabled: false,
  hideWizards: false,
  productImagesDisabled: false,
  createNodeDisabled: false,
  deleteNodeDisabled: false,
  editNodeDisabled: false,
  copyNodeDisabled: false,
  configurationLinkDisabled: false,
  duplicateNodeDisabled: false,
  folderHeight: nodeSizes.FOLDER_HEIGHT,
  configurationHeight: nodeSizes.CONFIGURATION_HEIGHT,
  compactConfigurationHeight: nodeSizes.CONFIGURATION_HEIGHT_COMPACT,
  indentSize: nodeSizes.NODE_INDENT,
  overscanCount: 5,
  isFullpage: false,
  isIndentCompact: false,
  filteringDisabled: true,
  resetOnPaste: true,
  ModelViewDisabled: false,
  downloadModalDisabled: false,
  simpleQuantityView: false,
  productAddingModal: false,
};
