import React, { useCallback, useMemo } from "react";
import { Field, Form, Formik } from "formik";
import * as Scrivito from "scrivito";
import NiceModal from "@ebay/nice-modal-react";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useMutation } from "urql";
import { Button } from "components/Button";
import { FormSelect } from "components/Inputs";
import { translate } from "utils";
import { ProjectStatusUpdateMut } from "api/mutations";
import { useProjectStatuses } from "hooks";
import { ModalWrapper } from "../ModalWrapper";

export const UpdateProjectStatusModal = NiceModal.create(
  Scrivito.connect(({ project = {} }) => {
    const projectStatuses = useProjectStatuses();
    const modal = NiceModal.useModal();

    const [projectStatusUpdateRes, exeProjectStatusUpdate] = useMutation(
      ProjectStatusUpdateMut,
    );
    const { fetching } = projectStatusUpdateRes;

    const handleSubmit = useCallback(
      (values) => {
        if (!fetching) {
          exeProjectStatusUpdate({
            id: project.id,
            input: {
              status: values.status?.value || projectStatuses[0]?.status,
            },
          }).then(({ error }) => {
            if (!error) {
              modal.resolve();
              modal.remove();
            }
          });
        }
      },
      [exeProjectStatusUpdate, projectStatuses, project.id, fetching, modal],
    );

    const optionsList = useMemo(
      () =>
        projectStatuses?.map((item) => ({
          value: item.status,
          label: translate(`PROJECT_STATUS_${item.status.toUpperCase()}`),
          color: item.color,
        })) || [],
      [projectStatuses],
    );

    const initialValues = useMemo(
      () => ({
        status: optionsList.find((item) => item.value === project?.status),
      }),
      [optionsList, project?.status],
    );

    return (
      <ModalWrapper
        isOpen={modal.visible}
        centered={true}
        size="sm"
        toggle={modal.hide}
        isLoading={optionsList === undefined}
      >
        <ModalHeader toggle={modal.remove} tag="div" className="pb-0">
          <h3 className="text-uppercase mb-0">{translate("PROJECT_STATUS")}</h3>
        </ModalHeader>
        <ModalBody className="pt-1">
          <h4 className="mb-4">{project.title}</h4>
          <Formik
            onSubmit={handleSubmit}
            initialValues={initialValues}
            enableReinitialize
          >
            <Form className="d-flex flex-column gap-4">
              <Field
                component={FormSelect}
                name="status"
                autoFocus={true}
                data-recording-disable={true}
                label={translate("PROJECT_STATUS")}
                tooltipText={translate("PROJECT_STATUS_SELECT_TOOLTIP_TEXT")}
                options={optionsList}
              />
              <ModalFooter className="px-0 pb-0">
                <Button color="primary" type="submit">
                  {translate("TEXT_SAVE_CHANGES")}
                </Button>
              </ModalFooter>
            </Form>
          </Formik>
        </ModalBody>
      </ModalWrapper>
    );
  }),
);
