import { useMutation } from "urql";
import {
  NodeCopyMut,
  NodeCreateMut,
  NodeDeleteMut,
  NodeUpdateMut,
  ConfigMoveMut,
  AddProductToNodeMut,
  ConfigUpdateMut,
  ConfigDeleteMut,
} from "api/mutations";
import NiceModal from "@ebay/nice-modal-react";
import { ConfirmationModal } from "components/_Modals/ConfirmationModal";
import { useProjectTreeContext } from "providers/ProjectProvider";
import { nodeTypes } from "variables";
import { useCallback } from "react";
import { validateCopiedItem } from "utils";
import { toast } from "react-toastify";
import { genNewNodeTitle } from "../utils";

export const useNodeActions = (projectId, translations) => {
  const { copiedItem, setCopiedItem } = useProjectTreeContext();

  const [_updateConfData, exeMoveConfig] = useMutation(ConfigMoveMut);
  const [_copyNode, exeCopyNode] = useMutation(NodeCopyMut);
  const [_createNode, exeCreateNode] = useMutation(NodeCreateMut);
  const [_updateNode, exeUpdateNode] = useMutation(NodeUpdateMut);
  const [_createProduct, exeCreateProduct] = useMutation(AddProductToNodeMut);
  const [_editProduct, exeEditProduct] = useMutation(ConfigUpdateMut);
  const [_deleteNode, exeDeleteNode] = useMutation(NodeDeleteMut);
  const [_deleteConfig, exeDeleteConfig] = useMutation(ConfigDeleteMut);

  const moveNode = ({ parentId, index, dragNodes, parentNode }, callback) => {
    const dragNode = dragNodes[0];
    const { data } = dragNode;

    if (data.kind === nodeTypes.CONFIGURATION && parentId === data.parentId) {
      return;
    }

    callback(Math.min(dragNode.rowIndex, parentNode.rowIndex));
    const { nodes: parentNodes } = parentNode.data;

    let refPos = 1;
    if (parentNodes.length === index && parentNodes.length > 0) {
      const { referencePosition = 0 } = parentNodes[index - 1] || {};
      refPos = referencePosition + 1;
    } else {
      const { referencePosition = 1 } = parentNodes[index] || {};
      refPos = referencePosition;
      if (parentId === data.parentId && refPos > data.referencePosition) {
        refPos -= 1;
      }
    }

    if (data.kind === nodeTypes.CONFIGURATION) {
      exeMoveConfig(
        {
          id: validateNodeId(data.id, projectId),
          destinationNodeId: validateNodeId(parentId, projectId),
        },
        {
          projectPauseExchangeType: "moveConfig",
        },
      );
    } else {
      exeUpdateNode(
        {
          id: validateNodeId(data.id, projectId),
          referencePosition: refPos,
          input: { parentId: validateNodeId(parentId, projectId) },
        },
        {
          projectPauseExchangeType: "nodeUpdate",
        },
      );
    }
  };

  // CREATE NEW NODE
  const createNode = useCallback(
    (node) => {
      if (node.isClosed) {
        node.toggle();
      }
      exeCreateNode(
        {
          projectId,
          input: {
            title: genNewNodeTitle(node.data.title, node.data.nodes, true),
            parentId: validateNodeId(node.id, projectId),
          },
          referencePosition: Array.isArray(node.data.nodes)
            ? node.children.length + 1
            : 1,
        },
        { projectPauseExchangeType: "nodeCreate" },
      );
    },
    [exeCreateNode, projectId],
  );

  // DELETE NODE
  const deleteNode = useCallback(
    (node, skipModal = false) => {
      if (skipModal) {
        exeDeleteNode(
          { id: node.data.id },
          { projectPauseExchangeType: "nodeDelete" },
        );
        return;
      }
      const hasChildren =
        Array.isArray(node.children) && node.children.length > 0;
      NiceModal.show(ConfirmationModal, {
        submitBtnText: hasChildren
          ? translations.folder.submitBtnTextWithChild
          : translations.folder.submitBtnTextNoChild,
        title: hasChildren
          ? translations.folder.titleWithChild
          : translations.folder.titleNoChild,
        alertText: `${
          hasChildren
            ? translations.folder.getDeleteModalBodyPluralWithTitleParam([
                node.data.title,
              ])
            : translations.folder.getDeleteModalBodySingularWithTitleParam([
                node.data.title,
              ])
        } ${translations.folder.alertTextCaution}`,
        withDisableCheckbox: true,
        modalToDisable: "folderDeleteModal",
        mutation: NodeDeleteMut,
        params: { id: validateNodeId(node.id, projectId) },
        mutationContext: { projectPauseExchangeType: "nodeDelete" },
      });
    },
    [exeDeleteNode, projectId, translations.folder],
  );

  const deleteConfig = useCallback(
    (node, skipModal = false) => {
      if (skipModal) {
        exeDeleteConfig(
          { id: node.data.id },
          { projectPauseExchangeType: "deleteConfig" },
        );
        return;
      }
      NiceModal.show(ConfirmationModal, {
        submitBtnText: translations.product.submitBtnText,
        title: translations.product.modalTitle,
        alertText: `${translations.product.alertText} "${node.data.productName}?" ${translations.product.alertTextCaution}`,
        mutation: ConfigDeleteMut,
        params: { id: node.data.id },
        withDisableCheckbox: true,
        modalToDisable: "productDeleteModal",
        mutationContext: { projectPauseExchangeType: "deleteConfig" },
      });
    },
    [
      exeDeleteConfig,
      translations.product.alertText,
      translations.product.alertTextCaution,
      translations.product.modalTitle,
      translations.product.submitBtnText,
    ],
  );

  // UPDATE NODE
  const updateNode = useCallback(
    (node, newTitle) =>
      exeUpdateNode(
        {
          id: validateNodeId(node.id, projectId),
          input: { title: newTitle },
        },
        {
          projectPauseExchangeType: "nodeUpdate",
        },
      ),
    [exeUpdateNode, projectId],
  );

  // COPY NODE
  const copyNode = useCallback((node) => setCopiedItem(node), [setCopiedItem]);

  // PASTE NODE
  const pasteNode = useCallback(
    (node, callback) => {
      if (!copiedItem || !node?.data?.id) {
        return;
      }
      const status = validateCopiedItem(copiedItem);
      if (!status.isValid) {
        toast.error(status.message);
        return;
      }
      if (copiedItem.isConfiguration) {
        exeCreateProduct(
          { ...copiedItem, nodeId: node.data.id },
          {
            projectPauseExchangeType: "addConfiguration",
          },
        ).then((res) => callback(res));
        return;
      }

      const { data } = copiedItem;

      exeCopyNode(
        {
          id: data.id,
          input: {
            title: genNewNodeTitle(data.title, node.data.nodes, false),
          },
          referencePosition: validatePosition(node, data.isWizard),
          destinationId: validateNodeId(node.data.id, projectId),
          projectId,
        },
        {
          projectPauseExchangeType: "nodeCopy",
        },
      );
      setCopiedItem(null);
    },
    [copiedItem, exeCopyNode, exeCreateProduct, projectId, setCopiedItem],
  );

  const duplicateNode = useCallback(
    (node) => {
      if (!node?.data?.id) {
        return;
      }
      const {
        data: { id, title, isWizard },
        parent,
      } = node;

      exeCopyNode(
        {
          id,
          input: {
            title: genNewNodeTitle(title, parent.data.nodes, false),
          },
          referencePosition: validatePosition(parent, isWizard),
          destinationId: validateNodeId(parent.data.id, projectId),
          projectId,
        },
        {
          projectPauseExchangeType: "nodeCopy",
        },
      );
    },
    [exeCopyNode, projectId],
  );

  const updateConfig = useCallback(
    (node, newQuantity) =>
      exeEditProduct(
        {
          id: node.data.id,
          quantity: newQuantity,
        },
        {
          projectPauseExchangeType: "updateConfig",
        },
      ),
    [exeEditProduct],
  );

  return {
    createNode,
    deleteNode,
    deleteConfig,
    updateNode,
    copyNode,
    pasteNode,
    duplicateNode,
    moveNode,
    updateConfig,
  };
};

const validateNodeId = (id, projectId) => (id !== projectId ? id : null);

const validatePosition = (node, isWizard = false) => {
  const { nodes } = node.data;
  if (!nodes || (Array.isArray(nodes) && nodes.length === 0)) {
    return 1;
  }
  if (isWizard) {
    return getLastWizardPos(node) + 1;
  }
  return nodes.length + 1;
};

const getLastWizardPos = (node) => {
  let referencePosition = 0;
  node.data.nodes.forEach((n) => {
    if (n.isWizard && n.referencePosition > referencePosition) {
      referencePosition = n.referencePosition;
    }
  });
  return referencePosition;
};
