import React, { useCallback } from "react";
import { Checkbox } from "components/Inputs";
import { translate } from "utils";

const getFileNameOrTitle = (reportTitle, displayedItems) => {
  for (const item of displayedItems) {
    if (item.checkboxContent) {
      for (const contentItem of item.checkboxContent) {
        if (contentItem.title === reportTitle) {
          if (contentItem.fromScrivito) {
            return contentItem.title;
          }
          return contentItem.fileName;
        }
      }
    }
  }

  return translate(reportTitle);
};

export const CheckboxAccordionContent = ({
  title,
  itemsData,
  checked = false,
  setItemsData,
  id,
}) => {
  const handleCheckboxClick = useCallback(
    (documentId) => {
      setItemsData((prevData) =>
        prevData?.map((item) => {
          if (item.title === title) {
            if (!item.checkboxContent?.length) {
              return { ...item, checked: !item.checked };
            }
            return {
              ...item,
              checked: !item.checked,
              checkboxContent: item.checkboxContent.map((subItem) => ({
                ...subItem,
                checked: !item.checked,
              })),
            };
          }

          if (item.checkboxContent?.length) {
            const updatedSublist = item.checkboxContent.map((subItem) => ({
              ...subItem,
              checked:
                subItem.id === documentId ? !subItem.checked : subItem.checked,
            }));

            const isAnySubItemChecked = updatedSublist.some(
              (subItem) => subItem.checked,
            );

            return {
              ...item,
              checkboxContent: updatedSublist,
              checked: isAnySubItemChecked,
            };
          }

          return item;
        }),
      );
    },
    [title, setItemsData],
  );

  const displayedTitle = getFileNameOrTitle(title, itemsData);

  return (
    <div
      className="d-flex align-items-center"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Checkbox
        id={id}
        checked={checked}
        data-testid={`checkbox-on-${title}`}
        onChange={() => handleCheckboxClick(id)}
      />
      <label
        htmlFor={id}
        className="ms-2 text-gray-700 "
        onClick={() => handleCheckboxClick(id)}
      >
        {displayedTitle}
      </label>
    </div>
  );
};
