import { svgMethods } from "components/_Modals/CreatePDFModal/PreviewTopology/renderMethods";
import { convertFAMs } from "objs/_ConfiguratorObjs/Wizard/wizardHelpers";

export const svgTopology = (canvas, topologyData, selectedZone = null) => {
  const {
    sameLayer,
    // eslint-disable-next-line no-unused-vars
    connectedByTopBracketChildren,
    bottomPlacedChildren,
    ...systemController
  } = topologyData;

  let topologyWidth = 0;
  let topologyHeight = 0;

  const startTopologyY = 2;
  const startTopologyX = 2;
  const nodeLinesGap = 4;

  const drawPolyLineToRightChild = (
    ctx,
    startX,
    startY,
    endX,
    endY,
    verticalLineX,
  ) => {
    svgMethods.drawLine(ctx, startX, startY, verticalLineX, startY, {});
    svgMethods.drawLine(ctx, verticalLineX, startY, verticalLineX, endY, {});
    svgMethods.drawLine(ctx, verticalLineX, endY, endX, endY, {});
  };

  const drawLeftBracketPolyLine = (
    ctx,
    startX,
    startY,
    endX,
    endY,
    leftGap,
  ) => {
    svgMethods.drawLine(ctx, startX, startY, startX - leftGap, startY, {});
    svgMethods.drawLine(
      ctx,
      startX - leftGap,
      startY,
      startX - leftGap,
      endY,
      {},
    );
    svgMethods.drawLine(ctx, startX - leftGap, endY, endX, endY, {});
  };

  const drawZone = (ctx, famStartX, famStartY, famData) => {
    let zoneHeight = famStartY || 0;
    const rightChildrenGapX = 200;
    const nodeGapY = 12;
    const bottomChildrenGapY = 100;

    // Draw Zone vertical text
    svgMethods.drawText(ctx, famStartX + 10, famStartY + 52 + 32, {
      text: famData.info,
      fz: 18,
      fw: "bold",
      color: "#4f5155",
      dominantBaseline: "central",
      textAnchor: "end",
      alignmentBaseline: "alphabetic",
      transform: `rotate(-90, ${famStartX + 10}, ${famStartY + 52 + 32})`,
    });

    svgMethods.drawNode(ctx, famStartX, famStartY, {
      ...famData,
      title: famData.title,
      subTitle: famData.presentation,
      iconName: famData.iconName,
    });

    const famChildrenBottomCount =
      (famData.bottomPlacedChildren?.length || 0) +
      (famData.chainedChildren?.length || 0);

    const famChildrenBottomLineStartX =
      famStartX + 52 - (52 - famChildrenBottomCount * nodeLinesGap) / 2;
    const famChildrenLineStartY = famStartY + 52;

    // Draw FAM and children
    if (famData.rightPlacedChildren) {
      const childrenCount = famData.rightPlacedChildren.length;

      if (famData.rightPlacedChildren) {
        famData.rightPlacedChildren.forEach((famChild, famChildIdx) => {
          const childStartX = famStartX + (328 + rightChildrenGapX);
          const childStartY = zoneHeight + 52 - 2;

          svgMethods.drawNode(
            ctx,
            famStartX + (328 + rightChildrenGapX),
            zoneHeight,
            {
              ...famChild,
              title: famChild.title,
              subTitle: famChild.presentation,
              iconName: famChild.iconName,
            },
          );

          const lineStartY =
            famStartY +
            (52 - nodeLinesGap * childrenCount) / 2 +
            nodeLinesGap * famChildIdx;
          const verticalLineX =
            childStartX -
            (childStartX - (famStartX + 328)) / 2 -
            nodeLinesGap * famChildIdx;

          drawPolyLineToRightChild(
            ctx,
            famStartX + 328,
            lineStartY,
            childStartX,
            childStartY - 52 / 2,
            verticalLineX,
          );

          if (famChild.directlyConnected) {
            famChild.directlyConnected.forEach(
              (directlyConnectedChild, directlyConnectedChildIdx) => {
                svgMethods.drawNode(
                  ctx,
                  childStartX,
                  childStartY + 52 * directlyConnectedChildIdx,
                  {
                    ...famChild,
                    title: directlyConnectedChild.title,
                    subTitle: directlyConnectedChild.presentation,
                    iconName: directlyConnectedChild.iconName,
                  },
                );
                zoneHeight += 52;
              },
            );
          }
          zoneHeight += 52 + nodeGapY;
        });
        // FAM margin bottom
        zoneHeight += bottomChildrenGapY;
      }
    }

    // Splitters and children
    if (famData.bottomPlacedChildren) {
      famData.bottomPlacedChildren.forEach((splitter, splitterIdx) => {
        const childrenCount = splitter.rightPlacedChildren.length || 0;
        const splitterStartX = famStartX + 78;
        const splitterStartY = zoneHeight;

        svgMethods.drawNode(ctx, splitterStartX, splitterStartY, {
          ...splitter,
          title: splitter.title,
          subTitle: splitter.presentation,
          iconName: splitter.iconName,
        });

        svgMethods.drawLine(
          ctx,
          famChildrenBottomLineStartX - nodeLinesGap * splitterIdx,
          famChildrenLineStartY,
          famChildrenBottomLineStartX - nodeLinesGap * splitterIdx,
          splitterStartY + 52 / 2,
          {},
        );
        svgMethods.drawLine(
          ctx,
          famChildrenBottomLineStartX - nodeLinesGap * splitterIdx,
          splitterStartY + 52 / 2,
          splitterStartX,
          splitterStartY + 52 / 2,
          {},
        );

        if (splitter?.rightPlacedChildren) {
          splitter.rightPlacedChildren.forEach((splitterChild, childIdx) => {
            const childStartX = famStartX + 328 + rightChildrenGapX;
            const childStartY = zoneHeight;

            svgMethods.drawNode(ctx, childStartX, childStartY, {
              ...splitterChild,
              title: splitterChild.title,
              subTitle: splitterChild.presentation,
              iconName: splitterChild.iconName,
            });

            const lineStartY =
              splitterStartY +
              (52 - nodeLinesGap * childrenCount) / 2 +
              nodeLinesGap * childIdx;

            const verticalLineX =
              childStartX -
              (childStartX - (famStartX + 328)) / 2 -
              nodeLinesGap * childIdx;

            drawPolyLineToRightChild(
              ctx,
              splitterStartX + 328,
              lineStartY,
              childStartX,
              childStartY + 52 / 2,
              verticalLineX,
            );

            zoneHeight += 52 + nodeGapY;
          });
        }
        // Splitter Margin bottom
        zoneHeight += bottomChildrenGapY;
      });
    }

    // Connections modules and children
    if (famData.chainedChildren) {
      // FAM margin top
      zoneHeight += bottomChildrenGapY;
      const connectionModuleStartX = famStartX + 52;
      famData.chainedChildren.forEach((chainedChild, chainedChildIdx) => {
        const connectionModuleStartY = zoneHeight;

        svgMethods.drawNode(
          ctx,
          connectionModuleStartX,
          connectionModuleStartY,
          {
            ...chainedChild,
            kind: "connectionModule", // From BE kind is "component" but on design it looks different
            title: chainedChild.title,
            subTitle: chainedChild.presentation,
            iconName: chainedChild.iconName,
          },
        );

        // Line to bottom chainedChildren
        // For Room Control
        if (chainedChildIdx === 0) {
          if (famData.chainedChildren.length === 1) {
            svgMethods.drawLine(
              ctx,
              famChildrenBottomLineStartX -
                nodeLinesGap * chainedChildIdx -
                (famData.bottomPlacedChildren?.length || 0) * nodeLinesGap,
              famChildrenLineStartY,
              famChildrenBottomLineStartX -
                nodeLinesGap * chainedChildIdx -
                (famData.bottomPlacedChildren?.length || 0) * nodeLinesGap,
              connectionModuleStartY + 52 / 2,
              {},
            );
            svgMethods.drawLine(
              ctx,
              famChildrenBottomLineStartX -
                nodeLinesGap * chainedChildIdx -
                (famData.bottomPlacedChildren?.length || 0) * nodeLinesGap,
              connectionModuleStartY + 52 / 2,
              connectionModuleStartX -
                nodeLinesGap * chainedChildIdx -
                (famData.bottomPlacedChildren?.length || 0) * nodeLinesGap,
              connectionModuleStartY + 52 / 2,
              {},
            );
          } else {
            // For Fire Protection
            svgMethods.drawLine(
              ctx,
              famStartX + (52 - nodeLinesGap) / 2,
              famStartY + 52,
              famStartX + (52 - nodeLinesGap) / 2,
              connectionModuleStartY + (52 - nodeLinesGap) / 2,
              {},
            );
            svgMethods.drawLine(
              ctx,
              famStartX + (52 - nodeLinesGap) / 2,
              connectionModuleStartY + (52 - nodeLinesGap) / 2,
              connectionModuleStartX,
              connectionModuleStartY + (52 - nodeLinesGap) / 2,
              {},
            );
          }
        }

        if (chainedChild?.rightPlacedChildren) {
          const rightPlacedChildrenCount =
            chainedChild.rightPlacedChildren.length;
          chainedChild?.rightPlacedChildren.forEach(
            (chainedChildDirectConnected, childIdx) => {
              const childStartY = zoneHeight;
              const childStartX = famStartX + (328 + rightChildrenGapX);

              svgMethods.drawNode(ctx, childStartX, childStartY, {
                ...chainedChildDirectConnected,
                title: chainedChildDirectConnected.title,
                subTitle: chainedChildDirectConnected.presentation,
                iconName: chainedChildDirectConnected.iconName,
              });

              const lineStartY =
                chainedChild.rightPlacedChildren.length > 1
                  ? connectionModuleStartY +
                    (52 - nodeLinesGap * rightPlacedChildrenCount) / 2 +
                    nodeLinesGap * childIdx
                  : connectionModuleStartY + 52 / 2;

              const verticalLineX =
                childStartX -
                (childStartX - (connectionModuleStartX + 328)) / 2 -
                nodeLinesGap * childIdx;

              drawPolyLineToRightChild(
                ctx,
                connectionModuleStartX + 328,
                lineStartY,
                childStartX,
                childStartY + 52 / 2,
                verticalLineX,
              );

              if (childIdx !== chainedChild.rightPlacedChildren.length - 1) {
                zoneHeight += 52 + nodeGapY;
              }
            },
          );
        }
        zoneHeight += 52 + nodeGapY;
        // For Fire Protection
        if (
          famData.chainedChildren.length > 1 &&
          chainedChildIdx !== famData.chainedChildren.length - 1
        ) {
          drawLeftBracketPolyLine(
            ctx,
            connectionModuleStartX,
            connectionModuleStartY + 52 / 2 + nodeLinesGap / 2,
            connectionModuleStartX,
            zoneHeight + 52 / 2 - nodeLinesGap / 2,
            14,
          );
        }
      });
    }

    // Add info about zone position for drawing zones by zones
    // zonesData.push({
    //   name: famData.info,
    //   startX: famStartX - 16,
    //   startY: famStartY - 16,
    //   height: zoneHeight,
    //   width: 328 + 328 + rightChildrenGapX + 16 + 16,
    // });

    // Calc topology width and height for set SVG size
    topologyWidth =
      famStartX + (328 + rightChildrenGapX) + 328 + rightChildrenGapX;
    if (zoneHeight > topologyHeight) {
      topologyHeight = zoneHeight;
    }
  };

  const draw = (ctx) => {
    let heigth = startTopologyY;
    const marginBottom = 100;

    if (selectedZone !== null) {
      drawZone(
        ctx,
        startTopologyX,
        startTopologyY,
        bottomPlacedChildren[0].connectedByTopBracketChildren[selectedZone - 1],
      );
    } else {
      // Draw System Controller
      svgMethods.drawNode(ctx, startTopologyX, heigth, {
        ...systemController,
        title: systemController.title,
        subTitle: systemController.presentation,
        iconName: systemController.iconName,
      });
      heigth += 52 + 32;

      // Draw MBE
      if (sameLayer) {
        sameLayer.forEach((item) => {
          svgMethods.drawNode(ctx, startTopologyX, heigth, {
            ...item,
            title: item.title,
            subTitle: item.presentation,
            kind: "switch", // From BE kind is "component" but on design it looks like "switch" (square)
            iconName: item.iconName,
          });
          svgMethods.drawLine(
            ctx,
            startTopologyX + 52 / 2,
            heigth,
            startTopologyX + 52 / 2,
            heigth - 32,
            {},
          );
          heigth += marginBottom;
        });
      }

      // Draw Switchers
      if (bottomPlacedChildren) {
        const switcherStartX = 328 + 64;
        bottomPlacedChildren.forEach((switcher) => {
          svgMethods.drawNode(ctx, switcherStartX, startTopologyY, {
            ...switcher,
            title: switcher.title,
            subTitle: switcher.presentation,
            iconName: switcher.iconName,
          });
          svgMethods.drawLine(
            ctx,
            switcherStartX,
            startTopologyY + 52 / 2,
            switcherStartX - 64,
            startTopologyY + 52 / 2,
            {},
          );

          // FAMs
          heigth += 52 + 32;
          const zonesGapX = 1025;
          if (switcher?.connectedByTopBracketChildren) {
            const splitByGroupsFAMs = convertFAMs(
              switcher.connectedByTopBracketChildren,
            );
            let startX = switcherStartX;
            const startY = 52 + 52 + 64;

            splitByGroupsFAMs.forEach((famGroup) => {
              famGroup.forEach((fam, famIdx) => {
                drawZone(ctx, startX, startY, fam);

                // Draw line at the top for connecting FAMs
                if (famIdx === 0) {
                  svgMethods.drawLine(
                    ctx,
                    startX + 52 / 2 - nodeLinesGap,
                    startY,
                    startX + 52 / 2 - nodeLinesGap,
                    startY - marginBottom,
                    {},
                  );
                } else {
                  svgMethods.drawLine(
                    ctx,
                    startX + 52 / 2 - nodeLinesGap,
                    startY,
                    startX + 52 / 2 - nodeLinesGap,
                    startY - marginBottom / 2,
                    {},
                  );
                  svgMethods.drawLine(
                    ctx,
                    startX + 52 / 2 - nodeLinesGap,
                    startY - marginBottom / 2,
                    startX + 52 / 2 - zonesGapX + nodeLinesGap,
                    startY - marginBottom / 2,
                    {},
                  );
                  svgMethods.drawLine(
                    ctx,
                    startX + 52 / 2 + nodeLinesGap - zonesGapX,
                    startY,
                    startX + 52 / 2 + nodeLinesGap - zonesGapX,
                    startY - marginBottom / 2,
                    {},
                  );
                }
                startX += zonesGapX;
              });
            });
          }

          // Draw connection bus
          svgMethods.drawRect(
            ctx,
            switcherStartX + 52 + 1,
            startTopologyY + 52,
            {
              width: topologyWidth,
              height: 20,
              stroke: "none",
              fill: "#dee0e5",
            },
          );
          // Draw text on the bus
          svgMethods.drawText(
            ctx,
            switcherStartX + 52 + 16,
            startTopologyY + 52 + 14,
            { text: switcher.info, fz: 16, fw: "bold", color: "#4f5155" },
          );

          // Draw X-Cube and etc
          if (switcher.sameLayer) {
            switcher.sameLayer.forEach((item, sameLayerIdx) => {
              const startX = (switcherStartX + 400) * (sameLayerIdx + 1);
              svgMethods.drawNode(ctx, startX, startTopologyY, {
                ...item,
                title: item.title,
                subTitle: item.presentation,
                kind: "switch", // From BE prop "kind" has value "component" but on design it looks like "switch" (square)
                iconName: item.iconName,
              });
              svgMethods.drawLine(
                ctx,
                switcherStartX + 52,
                startTopologyY + 52 / 2,
                startX,
                startTopologyY + 52 / 2,
                {},
              );
              heigth += marginBottom;
            });
          }
        });
      }
    }

    // Draw helper border around zone
    // zonesData.forEach(({ startX, startY, width, height }, idx) =>
    //   svgMethods.drawRect(ctx, startX, startY, {
    //     width,
    //     height,
    //     strokeWidth: 5,
    //     stroke: "tomato",
    //     fill: "transparent",
    //   }),
    // );
  };

  // const canvas = canvasRef.current;
  // document.getElementById("topologyId").innerHTML = "";

  draw(canvas);

  canvas.setAttribute("width", topologyWidth);
  canvas.setAttribute("height", topologyHeight);

  // return <canvas ref={canvasRef} />;
};
