import { nodeTypes } from "variables";

export const calcRowHeight = (node, showProductPictures, options) => {
  if (node.data.kind === nodeTypes.CONFIGURATION && showProductPictures) {
    return options.configurationHeight;
  }
  if (node.data.kind === nodeTypes.CONFIGURATION && !showProductPictures) {
    return options.compactConfigurationHeight;
  }

  return options.folderHeight;
};
