import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
  useRef,
} from "react";
import * as Scrivito from "scrivito";
import { useQuery } from "urql";
import { ProjectDataQry } from "api/queries";
import { useProductOverview } from "hooks/useProductOverview";
import { useOpenMap } from "hooks/useOpenMap";
import { useStateLoaderContext } from "./PageLoadStateProvider";
import { useAppBaseContext } from "./AppBaseProvider";

export const ProjectContext = createContext({});
export const ProjectTreeContext = createContext({});
export const useProjectContext = () => useContext(ProjectContext);
export const useProjectTreeContext = () => useContext(ProjectTreeContext);

export const ProjectContextProvider = ({ children }) => {
  const [projectId, setProjectId] = useState(getProjectId());
  const [copiedItem, setCopiedItem] = useState(null);
  const [showProductPictures, setShowProductPictures] = useState(true);
  const { setIsLoaderXCircle } = useStateLoaderContext();

  // ref for the tree component which gives access to the tree API from react-arborist
  const treeRef = useRef();

  const [{ data, fetching, stale, error }, fetchProjectData] = useQuery({
    query: ProjectDataQry,
    variables: {
      id: projectId || "",
    },
    pause: true,
  });

  const { openMap, setOpenMap, toggleNode } = useOpenMap(
    data?.project?.projectTree,
    fetching || stale,
    error,
  );

  const page = Scrivito.currentPage();
  const { lang } = useAppBaseContext();
  const { productConfiguratorObj } = useProductOverview({ page, lang });

  const navigateToProduct = useNavigateToProduct({
    units: data?.project?.units,
    projectId,
    productConfiguratorObj,
  });

  useEffect(() => {
    if (projectId) {
      fetchProjectData();
      setOpenMap(null);
    }
  }, [fetchProjectData, projectId, setIsLoaderXCircle, setOpenMap]);

  const handleChangeProject = useCallback((id) => {
    if (id !== getProjectId()) {
      const currentUrl = new URL(window.location.href);
      currentUrl.searchParams.set("projectId", id);
      window.history.pushState({}, "", currentUrl);
      setProjectId(id);
    }
  }, []);

  return (
    <ProjectContext.Provider
      value={{
        units: data?.project?.units || "",
        title: data?.project?.title || "",
        isLoading: fetching || stale,
        error,
        fetchProjectData,
        handleChangeProject,
        setProjectId,
        projectId,
      }}
    >
      <ProjectTreeContext.Provider
        value={{
          projectTree: data?.project?.projectTree,
          isLoading: fetching || stale || openMap === null,
          fetchProjectData,
          openMap,
          setOpenMap,
          copiedItem,
          setCopiedItem,
          toggleNode,
          showProductPictures,
          setShowProductPictures,
          projectId,
          setProjectId,
          projectName: data?.project?.title || "",
          navigateToProduct,
          treeRef,
          units: data?.project?.units || "",
          handleChangeProject,
        }}
      >
        {children}
      </ProjectTreeContext.Provider>
    </ProjectContext.Provider>
  );
};

const getProjectId = () =>
  new URLSearchParams(window.location.search).get("projectId");

const useNavigateToProduct = ({ units, projectId, productConfiguratorObj }) =>
  useMemo(() => {
    if (!productConfiguratorObj) {
      return () => {};
    }
    return ({ parentId, foldedOrderCode, orderCode, isWizard, id }) =>
      Scrivito.navigateTo(() => productConfiguratorObj, {
        params: {
          seriesId: orderCode?.seriesId?.toString(),
          foldedOrderCode,
          nodeId: parentId,
          orderCode: JSON.stringify({
            ordercodestate: {
              optionStates: orderCode?.state?.optionStates,
            },
            propertyStates: orderCode?.propertyStates,
            currentStrategyId: orderCode?.currentStrategyId,
          }),
          isWizardProduct: isWizard,
          productId: id,
          projectId,
          units,
        },
      });
  }, [productConfiguratorObj, projectId, units]);
