import React, { useState } from "react";
import cx from "classnames";
import s from "./LeftSidebar.module.scss";
import { SidebarToggle } from "./SidebarToggle";

export const SideBarWrapper = ({
  children,
  width,
  isOpenDefault = true,
  withToggle = false,
  toggleText,
  isFirstSidebar = false,
  zIndex = 0,
  toggleOnLeft = false,
  color = "primary",
}) => {
  const [show, setShow] = useState(isOpenDefault);
  return (
    <div
      className={cx("h-full-exclude-footer", s.sideBarWrapper)}
      style={{ width: show ? width : 0, zIndex }}
    >
      <div
        className={cx(
          "h-full-exclude-footer position-fixed page-start-top",
          s.sideBarWrapper,
        )}
        style={{ width: show ? width : 0 }}
      >
        <div className={cx("h-100", s.customScrollBar)}>{children}</div>
        {withToggle && (
          <SidebarToggle
            setIsOpened={setShow}
            isOpened={show}
            isFirstSidebar={isFirstSidebar}
            text={toggleText}
            onLeft={toggleOnLeft}
            color={color}
          />
        )}
      </div>
    </div>
  );
};
