import React, { useMemo } from "react";
import * as Scrivito from "scrivito";
import { priceToString } from "utils/priceToString";
import { translate } from "utils";

export const ProductListTableRow = Scrivito.connect(
  ({ product, fetchingPrices }) => {
    const {
      title,
      presentation,
      quantity,
      unitPrice,
      totalPrice = null,
      priceGroup = null,
    } = product;

    const { unitPriceString, totalPriceString } = useMemo(
      () => ({
        unitPriceString: unitPrice
          ? priceToString({
              price: unitPrice,
              noPriceText: translate("NO_PRICE"),
            })
          : null,
        totalPriceString: totalPrice
          ? priceToString({
              price: totalPrice,
              noPriceText: translate("NO_PRICE"),
            })
          : null,
      }),
      [unitPrice, totalPrice],
    );

    return (
      <>
        <td style={{ width: "20%" }}>
          <div className="text-gray-700 fw-bold">{title}</div>
        </td>
        <td style={{ width: "25%" }}>{presentation}</td>
        <td style={{ width: "10%" }}>{quantity}</td>
        {fetchingPrices ? (
          <>
            <td style={{ width: "15%" }}>
              <div className="shortloadingLine col-8 rounded"></div>
            </td>
            <td style={{ width: "15%" }}>
              <div className="shortloadingLine col-8 rounded"></div>
            </td>
            <td style={{ width: "15%" }}>
              <div className="shortloadingLine col-8 rounded"></div>
            </td>
          </>
        ) : (
          <>
            <td style={{ width: "15%" }}>{unitPriceString}</td>
            <td style={{ width: "15%" }}>{totalPriceString}</td>
            <td style={{ width: "15%" }}>{priceGroup}</td>
          </>
        )}
      </>
    );
  },
);
