import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import * as Scrivito from "scrivito";
import { usePageType } from "hooks";
import cx from "classnames";
import {
  isLeftSidebarSubNavOpened,
  isProjectSummarySidebarVisible,
  isSystemConfiguratorLayout,
} from "utils";

import { useStateLoaderContext } from "../../providers";

import { LeftSidebar } from "./LeftSidebar";
import NotFoundErrorPage from "../NotFoundErrorPage";
import { CurrentPageMetadata } from "../CurrentPageMetadata";
import { CookieConsentBanner } from "../CookieConsentBanner";
import { Footer } from "../Footer/Footer";
import { ProjectSummarySidebar } from "../ProjectSummarySidebar/ProjectSummarySidebar";
import { XCircleLoader } from "../XCircleLoader/XCircleLoader";

import s from "./LeftSidebar.module.scss";

const MemoizedCurrentPage = React.memo(() => <Scrivito.CurrentPage />);

export const Layout = Scrivito.connect(() => {
  const contentRef = useRef(null);
  const [activeButton, setActiveButton] = useState(null);
  const pageType = usePageType();

  const { isLoaderXCircle, setIsLoaderXCircle } = useStateLoaderContext();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setIsLoaderXCircle(true), []);

  const isSubNavOpened = useMemo(
    () => isLeftSidebarSubNavOpened(activeButton),
    [activeButton],
  );

  const { projectId } = Scrivito.currentPageParams();

  const isLeftSidebarVisible = isSystemConfiguratorLayout(pageType);
  const isRightSidebarVisible = isProjectSummarySidebarVisible(
    pageType,
    projectId,
  );

  const closeSubnav = useCallback(() => setActiveButton(null), []);
  const isOverlayActive =
    isSubNavOpened && activeButton && activeButton.subNavLevel?.value === 0;

  const { pathname } = window.location;
  useEffect(() => {
    contentRef?.current.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className="d-flex h-100 flex-grow-1 overflow-hidden">
      {isLeftSidebarVisible && (
        <LeftSidebar
          activeButton={activeButton}
          setActiveButton={setActiveButton}
        />
      )}

      <div id="portal_left_sidebar" className={s.leftSidebarPortal} />
      {isLoaderXCircle && <XCircleLoader classNames={s.contentWrapper} />}
      <div
        className={cx(
          "content-wrapper d-flex flex-column position-relative overflow-auto min-h-full page-start-top",
          pageType === "ProjectConfigurator" ? "bg-white" : "bg-gray-100",
          { "d-none": isLoaderXCircle },
        )}
        ref={contentRef}
        id="contentWrapper"
      >
        <MemoizedCurrentPage />
        <NotFoundErrorPage />
        <CurrentPageMetadata />
        <CookieConsentBanner />
        <Footer />
        {isLeftSidebarVisible && (
          <div
            className={cx("position-fixed", s.leftSidebarOverlay, {
              [s.leftSidebarOverlayActive]: isOverlayActive,
            })}
            onClick={closeSubnav}
          />
        )}
      </div>
      {isRightSidebarVisible && <ProjectSummarySidebar projectId={projectId} />}
    </div>
  );
});
