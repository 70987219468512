import React, { useState, useLayoutEffect, memo, useCallback } from "react";
import * as Scrivito from "scrivito";
import cx from "classnames";
import { Icon } from "components/Icon/Icon";
import NiceModal from "@ebay/nice-modal-react";
import { Product3dViewModal } from "components/_Modals/Product3dViewModal/Product3dViewModal";
import { SingleProductDownloadModal } from "components/_Modals/ProjectDownloadModal/SingleProductDownloadModal";
import { isDeleteModalDisabled } from "components/ProjectTree/utils/skipDeleteModal";
import { ConfigurationControls } from "./ConfigurationControls";

export const Configuration = ({
  node,
  showProductPictures,
  options,
  translations,
  navigateToProduct,
  data,
  nodeActions,
  handleToggleEditMode,
  setIsEditMode,
  isEditMode,
  copiedItem,
  units,
  lang,
}) => {
  const isPasteMode = !!copiedItem;

  const handleUpdate = (quantity) => {
    nodeActions.updateConfig(node, quantity);
    setIsEditMode(false);
  };

  const handleDelete = () => {
    const skipModal = isDeleteModalDisabled("productDeleteModal");
    nodeActions.deleteConfig(node, skipModal);
  };

  const handleToggle3dView = useCallback(() => {
    NiceModal.show(Product3dViewModal, {
      requestParams: {
        seriesId: data?.orderCode?.seriesId,
        state: { ordercodestate: data?.orderCode.state },
        resultUnitsSystem: units,
        languageIso2L: lang,
      },
    });
  }, [lang, data, units]);

  const openDownloadModal = useCallback(() => {
    NiceModal.show(SingleProductDownloadModal, {
      modalSubtitle: data?.productName,
      modalType: "Product",
      orderCode: data?.orderCode,
      presentation: data?.presentation,
    });
  }, [data]);

  return (
    <div className="d-flex w-100 h-100 flex-row align-items-center justify-content-between">
      <div
        className="d-flex flex-row align-items-center gap-2 h-100 flex-grow-1"
        style={{ minWidth: 0 }}
      >
        <ProductImage
          pimId={data.pimId}
          showProductPictures={showProductPictures}
          productImagesDisabled={options.productImagesDisabled}
          productName={data.productName}
          width={64}
          height={64}
        />
        <ProductDetails
          showProductPictures={showProductPictures}
          options={options}
          isWizard={data.isWizard}
          productName={data.productName}
          presentation={data.presentation}
          navigateToProduct={navigateToProduct}
          isPasteMode={isPasteMode}
        />
      </div>
      <ConfigurationControls
        isEditMode={isEditMode}
        data={data}
        options={options}
        translations={translations}
        handleToggleEditMode={handleToggleEditMode}
        handleToggle3dView={handleToggle3dView}
        openDownloadModal={openDownloadModal}
        handleDelete={handleDelete}
        handleUpdate={handleUpdate}
        onClickOutside={() => setIsEditMode(false)}
        isPasteMode={isPasteMode}
        handleCopyNode={() =>
          nodeActions.copyNode({ ...data, isConfiguration: true })
        }
      />
    </div>
  );
};

const ProductDetails = ({
  showProductPictures,
  options,
  isWizard,
  productName,
  presentation,
  navigateToProduct,
  isPasteMode,
}) => (
  <div
    className={cx("d-flex gap-2 flex-grow-1", {
      "flex-column": showProductPictures && !options.productImagesDisabled,
      "flex-row justify-content-start":
        !showProductPictures || options.productImagesDisabled,
    })}
    style={{ minWidth: 0 }}
  >
    <div
      className={cx("", {
        "d-flex flex-row align-items-center gap-2":
          !isWizard && !options.configurationLinkDisabled,
      })}
    >
      <span
        className={cx("fw-bolder fs-md cursor-pointer", {
          "cursor-not-allowed": isPasteMode,
        })}
        data-testid="folder-title"
      >
        {productName}
      </span>
      {!isWizard && !options.configurationLinkDisabled && (
        <Icon
          className={cx("text-gray-300 cursor-pointer", {
            "cursor-not-allowed": isPasteMode,
          })}
          prefix="trox"
          iconName="arrow-up-right-square-regular"
          onClick={() => !isPasteMode && navigateToProduct()}
        />
      )}
    </div>
    <span className="text-gray-400 text-truncate" style={{ minWidth: 0 }}>
      {presentation || "1234567"}
    </span>
  </div>
);

const ProductImage = memo(
  ({
    pimId,
    showProductPictures,
    productImagesDisabled,
    productName,
    width,
    height,
  }) => {
    const { productImage, fetching } = useProductImage(pimId);

    return (
      <>
        {showProductPictures && !productImagesDisabled && (
          <div className="icon-64 d-flex align-items-center justify-content-center">
            {productImage ? (
              <img
                src={productImage}
                width={width}
                height={height}
                className="w-100 h-100 object-fit-contain m-0"
                alt={productName}
              />
            ) : (
              <>
                {!pimId || fetching ? (
                  <Icon
                    className="icon-64 m-2"
                    prefix="trox"
                    iconName="squares-light"
                  />
                ) : null}
              </>
            )}
          </div>
        )}
      </>
    );
  },
);

const useProductImage = (pimId) => {
  const [productImage, setProductImage] = useState(null);
  const [fetching, setFetching] = useState(false);

  useLayoutEffect(() => {
    if (pimId && !productImage && !fetching) {
      setFetching(true);
      const cachedImage = getImageUrlFromSessionStorage(pimId);
      if (!cachedImage) {
        loadProductImage(pimId).then((image) => {
          if (image) {
            setProductImage(image);
            sessionStorage.setItem(pimId, image);
          }
          setFetching(false);
        });
      } else {
        setProductImage(cachedImage);
      }
    }
  }, [fetching, pimId, productImage]);

  return {
    productImage,
    fetching,
  };
};

async function loadProductImage(pimId) {
  try {
    // TODO: When AB will be ready and providing us img URLs (or Binaries) we may get rid of loadProductImage at all
    const obj = await Scrivito.load(() =>
      Scrivito.Obj.where("pimId", "equals", pimId)
        .and("_objClass", "equals", "Image")
        .first(),
    );

    return await Scrivito.load(() => obj.contentUrl());
  } catch (error) {
    console.error("Error loading product image:", error);
    return null;
  }
}

const getImageUrlFromSessionStorage = (pimId) => sessionStorage.getItem(pimId);
