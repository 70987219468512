export const REFRESH_DELAY = 2000;

export const dropRelations = {
  null: null,
  before: "before",
  after: "after",
  directly: "directly",
};

export const sessionStorageDisabledConfirmationsKey = "disabledConfirmations";
