import { useEffect } from "react";

export const useSyncProjectId = (projectId, setProjectId) =>
  useEffect(() => {
    const paramProjectId = new URLSearchParams(window.location.search).get(
      "projectId",
    );
    if (projectId !== paramProjectId) {
      setProjectId(paramProjectId);
    }
  }, [projectId, setProjectId]);
