/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect } from "react";
import NiceModal from "@ebay/nice-modal-react";
import { nodeTypes } from "variables";
import { ProjectOrFolderDownloadModal } from "components/_Modals/ProjectDownloadModal/ProjectOrFolderDownloadModal";
import { isDeleteModalDisabled } from "components/ProjectTree/utils/skipDeleteModal";
import { getScrollToIndex } from "components/ProjectTree/utils";
import { WizardLink } from "../WizardLink";
import { NodeTitle } from "./NodeTitle";
import { PasteButton } from "../Buttons/PasteButton";
import { LeftControls, RightControls } from "./FolderControls";

export const Folder = ({
  node,
  options,
  nodeActions,
  projectId,
  copiedItem,
  translations,
  setRedrawIndex,
  setScrollToIndex,
  willReceiveDrop,
  handleToggleNode,
  handleToggleEditMode,
  setIsEditMode,
  isEditMode,
}) => {
  // Dependancies
  const isRootNode = node.data.kind === nodeTypes.ROOT;
  const { isWizard, title, id } = node.data;
  const isWizardRoot = isWizard && !node.parent.data.isWizard;
  const nodeId = node.id;
  const nodeChildren = node.children;
  const isPasteMode = !!copiedItem;
  const isNodeOpen = node.isOpen;
  const hasChildren = nodeChildren && nodeChildren.length > 0;
  const isNodeOptimistic = nodeId.startsWith("temp__");
  const { rowIndex } = node;

  const useRedraw =
    (fn = () => {}) =>
    () => {
      setRedrawIndex(rowIndex);
      fn();
    };

  useEffect(() => {
    if (willReceiveDrop) {
      setRedrawIndex(rowIndex);
    }
  }, [willReceiveDrop]);

  const handleCopyNode = () => nodeActions.copyNode(node);
  const handleDeleteNode = useRedraw(() => {
    const skipModal = isDeleteModalDisabled("folderDeleteModal");
    nodeActions.deleteNode(node, skipModal);
  });
  const handleCreateNode = useRedraw(() => {
    const index = getScrollToIndex(node);
    nodeActions.createNode(node);
    setScrollToIndex(index);
  });
  const onToggleNode = () => {
    node.toggle();
    handleToggleNode(node.data.id);
  };
  const handleUpdateNode = (newTitle) => nodeActions.updateNode(node, newTitle);
  const handleDuplicateNode = useRedraw(() => {
    const index = getScrollToIndex(node.parent);
    nodeActions.duplicateNode(node);
    setScrollToIndex(index);
  });

  const openDownloadModal = useCallback(() => {
    NiceModal.show(ProjectOrFolderDownloadModal, {
      modalSubtitle: title,
      wizardId: id,
    });
  }, [id, title]);

  return (
    <>
      <div className="d-flex flex-row align-items-center h-100">
        <LeftControls
          isWizard={isWizard}
          isRootNode={isRootNode}
          handleToggleNode={onToggleNode}
          handleCreateNode={handleCreateNode}
          isNodeOpen={isNodeOpen}
          hasChildren={hasChildren}
          options={options}
          translations={translations}
          nodeId={node.data.id}
        />
        <NodeTitle
          title={title}
          isEditMode={isEditMode}
          setIsEditMode={setIsEditMode}
          handleToggleEditMode={handleToggleEditMode}
          isRootNode={isRootNode}
          isWizard={isWizard}
          handleUpdateNode={handleUpdateNode}
        />
        {isWizardRoot && (
          <WizardLink
            params={{
              projectId,
              nodeId: node.data.id,
            }}
            text={translations.folder.wizardLink}
            disabled={isPasteMode}
          />
        )}
        {isPasteMode && !isWizard && (
          <PasteButton nodeId={nodeId} text={translations.folder.pasteNode} />
        )}
      </div>
      {!isRootNode && (
        <RightControls
          handleToggleEditMode={handleToggleEditMode}
          handleCopyNode={handleCopyNode}
          handleDeleteNode={handleDeleteNode}
          handleDuplicateNode={handleDuplicateNode}
          isWizard={isWizard}
          isWizardRoot={isWizardRoot}
          isPasteMode={isPasteMode}
          hasChildren={hasChildren}
          options={options}
          isNodeOptimistic={isNodeOptimistic}
          translations={translations}
          nodeId={node.data.id}
          openDownloadModal={openDownloadModal}
        />
      )}
    </>
  );
};
