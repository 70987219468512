import React, { useMemo, useRef, useState } from "react";
import * as Scrivito from "scrivito";
import cx from "classnames";
import { useQuery } from "urql";
import { UncontrolledTooltip } from "reactstrap";

import { priceToString } from "utils/priceToString";
import {
  ProductListCalculationMarker,
  ProductListDownloadButton,
  ProductListFilterButton,
  ProductListSearchControl,
  ProductListSizeSwitcher,
} from "components/ProductListOfProject/SubComponents";
import { Icon } from "components/Icon/Icon";
import { TranslateWrapper } from "components/TranslateWrapper";
import {
  ProductPriceListQry,
  ProjectCommissioningCalcQry,
  UserProductListQry,
} from "api/queries";
import { PRODUCTS_PER_PAGE, SORTING_CRITERIA } from "variables";
import { useItemsPerPage } from "hooks";
import {
  getCommissionAsProduct,
  getProductListVariables,
  getTranslationTextForEditMode,
  translate,
} from "utils";
import { useStateLoaderContext } from "providers";
import { ProductListOfProject } from "./ProductListOfProject";
import { ItemsPerPageSelect } from "../ItemsPerPageSelect";

export const ProductListWithControls = Scrivito.connect(
  ({
    projectId = null,
    nodeId,
    configurationOption = null,
    hasFilter = false,
    hasSearch = false,
    hasSizeSwitcher = false,
    isInModal = false,
  }) => {
    const { setIsFetching } = useStateLoaderContext();
    const [isOrderDescending, setIsOrderDescending] = useState(false);
    const [sortCriteria, setSortCriteria] = useState(SORTING_CRITERIA.NAME);
    const tableRef = useRef(null);
    const isCommissionIncluded = useMemo(
      () =>
        configurationOption && configurationOption !== "commissionNotIncluded",
      [configurationOption],
    );

    const {
      itemsPerPage,
      handleItemsPerPageChange,
      currentPageNo,
      setCurrentPageNo,
    } = useItemsPerPage(PRODUCTS_PER_PAGE);

    const productListVariables = getProductListVariables({
      sortCriteria,
      isOrderDescending,
      projectId,
      currentPageNo,
      nodeId,
      itemsPerPage,
    });

    const [{ data: productListData, fetching, stale }] = useQuery({
      query: UserProductListQry,
      variables: productListVariables,
      pause: !projectId,
    });

    const productListFetching = fetching || stale;
    const { productList } = productListData?.project ?? {};
    const { productsCount = 0, pages: pagesCount = 0 } = productList ?? {}; // "?? {}" because sometimes productList is null
    const productsDatas = useMemo(
      () => productList?.products ?? {},
      [productList?.products],
    );

    const productsIds = useMemo(
      () => productList?.products?.map((product) => product.id),
      [productList?.products],
    );

    // TODO: This additional request for prices needs for AB
    const [{ data: priceListData, stale: priceListStale }] = useQuery({
      query: ProductPriceListQry,
      variables: {
        id: projectId,
        projectNodeId: nodeId,
        filters: {
          configurationIds: productsIds,
        },
      },
      pause: !projectId && !productListFetching,
    });
    setIsFetching(priceListStale);

    const priceOnRequest =
      priceListData?.project.productPriceList?.includesPriceOnRequest;
    const { price, productPriceList } = priceListData?.project ?? {};
    const priceListProducts = useMemo(
      () => productPriceList?.products ?? [],
      [productPriceList?.products],
    );
    const productsPrice = useMemo(
      () => productPriceList?.productsPrice ?? null,
      [productPriceList?.productsPrice],
    );

    const isDownloadAllowed = useMemo(
      () => priceListProducts.length > 0,
      [priceListProducts.length],
    );

    const productsWithPrices = useMemo(
      () =>
        productsDatas.length && priceListProducts.length
          ? productsDatas.map((prodItem) => {
              const prodPrice = priceListProducts.find(
                (priceItem) => priceItem.id === prodItem.id,
              );
              return {
                ...prodItem,
                totalPrice: prodPrice?.totalPrice || {},
                unitPrice: prodPrice?.unitPrice || {},
              };
            })
          : [],
      [productsDatas, priceListProducts],
    );

    const [{ data: commissioningData, fetching: commissionFetching }] =
      useQuery({
        query: ProjectCommissioningCalcQry,
        variables: {
          projectId,
          nodeId,
          configurationOption,
        },
        pause: !projectId || !nodeId || !isCommissionIncluded,
      });

    const commissioningCalculationData =
      commissioningData?.commissioningCalculation?.data || {};
    const {
      price: commissioningPrice = null,
      currency: commissioningCurrency = "",
    } = commissioningCalculationData;

    const commissioningProduct = useMemo(
      () =>
        nodeId && isCommissionIncluded
          ? getCommissionAsProduct({
              title: `${getTranslationTextForEditMode("WIZARD_COMMISSIONING")} 
              ${getTranslationTextForEditMode(
                configurationOption === "RoomControl_PreConf"
                  ? "WIZARD_COMMISSIONING_PRE_CONF"
                  : "WIZARD_COMMISSIONING_ON_SITE_CONF",
              )}
              `,
              centAmount: commissioningPrice,
              currency: commissioningCurrency,
            })
          : null,
      [
        commissioningCurrency,
        commissioningPrice,
        configurationOption,
        isCommissionIncluded,
        nodeId,
      ],
    );

    const totalPrice = useMemo(() => {
      if (price || productsPrice) {
        return priceToString({
          price: {
            currencyCode: productsPrice?.currencyCode || price?.currencyCode,
            fractionDigits:
              productsPrice?.fractionDigits || price?.fractionDigits,
            centAmount: productsPrice?.centAmount || price?.centAmount,
          },
          noPriceText: null,
          commissioningPrice,
        });
      }
    }, [commissioningPrice, price, productsPrice]);

    return (
      <>
        {isInModal && productList?.includesDummyProduct && (
          <ProductListCalculationMarker />
        )}
        <div
          className={cx({
            "pb-6 mt-3": !isInModal,
            "pb-0 mt-1": isInModal,
          })}
        >
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center font-bold">
              {hasFilter && <ProductListFilterButton />}
              {hasSearch && <ProductListSearchControl />}
              {hasSizeSwitcher && <ProductListSizeSwitcher />}
            </div>
            <div className="d-flex align-items-center">
              <div className="d-flex flex-column">
                <div className="me-3 d-flex align-items-center">
                  {translate("TOTAL_PRICE")}:
                  {priceListStale ? (
                    <div
                      style={{
                        width: "80px",
                        height: "18px",
                      }}
                      className="blockSkeleton ms-2_5 rounded"
                    ></div>
                  ) : (
                    <>
                      {totalPrice ? (
                        <div
                          className={cx(
                            "d-flex align-items-center flex-nowrap font-bold ms-1 ",
                            {
                              "text-warning": priceOnRequest,
                            },
                          )}
                        >
                          {totalPrice}
                          <Icon
                            className="ms-2 text-gray-600 cursor-pointer focus-ring focus-ring-light"
                            id="total_price"
                            tabIndex={-1}
                            iconName="circle-info"
                            prefix="far"
                          />
                          <UncontrolledTooltip
                            target="total_price"
                            placement="top"
                          >
                            {translate("TOTAL_PRICE_ATTENTION")}
                          </UncontrolledTooltip>
                          {typeof translate("TOTAL_PRICE_ATTENTION") ===
                            "object" && (
                            <TranslateWrapper
                              tooltipText="TOTAL_PRICE_ATTENTION"
                              tooltipId="total_price"
                              placement="top"
                            />
                          )}
                        </div>
                      ) : (
                        <>{translate("NO_PRICE")}</>
                      )}
                    </>
                  )}
                </div>
              </div>
              <ProductListDownloadButton
                projectId={projectId}
                downloadAllowed={isDownloadAllowed}
                nodeId={nodeId}
                configurationOption={configurationOption}
              />
            </div>
          </div>
          <div className="pt-2_5 bg-white">
            <ItemsPerPageSelect
              itemsPerPage={itemsPerPage}
              onChange={handleItemsPerPageChange}
            />
            <ProductListOfProject
              currentPageNo={currentPageNo}
              fetchingProductList={productListFetching}
              fetchingPrices={priceListStale}
              commissionFetching={commissionFetching}
              isOrderDescending={isOrderDescending}
              pagesCount={pagesCount}
              products={
                productsWithPrices.length ? productsWithPrices : productsDatas
              }
              commissioningProduct={commissioningProduct}
              productsCount={productsCount}
              setCurrentPageNo={setCurrentPageNo}
              setIsOrderDescending={setIsOrderDescending}
              tableRef={tableRef}
              itemsPerPage={itemsPerPage}
              setSortCriteria={setSortCriteria}
              sortCriteria={sortCriteria}
            />
          </div>
        </div>
      </>
    );
  },
);
