import axios from "axios";
import pLimit from "p-limit";
import * as Scrivito from "scrivito";

export const boardUrl = process.env.TROX_BOARD_URL;

export const determineCategoryFromTitle = (title) => {
  let category = "Improvement";

  if (/bug|bugfix|fix/i.test(title) || /hotfix/i.test(title)) {
    category = "Bug";
  } else if (/task/i.test(title) || /feat/i.test(title)) {
    category = "New";
  }

  return category;
};

const AZURE_DEVOPS_ORG = "DEVLY-IT-Software-Development";
const AZURE_PROJECT_NAME = "TROX-EB-Website_v2";
const repositoryId = "4d710736-01df-40dd-919e-b2bc5be42906";
const token = process.env.AZURE_TOKEN;

const encodedToken = btoa(`:${token}`);
const getFileNameFromUrl = (url) => url.substring(url.lastIndexOf("/") + 1);

const getContentType = (filename) => {
  const extension = filename.split(".").pop().toLowerCase();

  switch (extension) {
    case "png":
      return "image/png";
    case "jpg":
    case "jpeg":
      return "image/jpeg";
    case "gif":
      return "image/gif";
    default:
      return "application/octet-stream";
  }
};

const attachImageToObjectClass = async (objectId, imageBlobs) => {
  const existingImages = await Scrivito.load(() =>
    Scrivito.getClass("Image")
      .where("pimId", "equals", String(objectId))
      .toArray(),
  );

  if (existingImages.length > 0) {
    return existingImages;
  }

  if (imageBlobs.length === 0) {
    return [];
  }
  const results = await Promise.all(
    imageBlobs.map(async ({ imageBlob, fileName }) => {
      const file = new File([imageBlob], fileName, { type: imageBlob.type });

      const imageObject = await Scrivito.getClass("Image").createFromFile(
        file,
        {
          title: fileName,
          tags: ["changelog"],
          pimId: String(objectId),
        },
      );

      return imageObject;
    }),
  );

  return results;
};

const uploadImagesToScrivito = async (pr) => {
  const attachments = pr?.attachments || [];
  const bloBarray = [];

  if (attachments.length === 0) {
    return [];
  }
  const existingImages = await attachImageToObjectClass(pr.pullRequestId, []);
  if (existingImages.length > 0) {
    return existingImages;
  }

  const uploadPromises = attachments.map(async (attachment) => {
    const imageUrl = attachment.url;

    const fileName = getFileNameFromUrl(imageUrl);
    try {
      const responseBlob = await axios.get(imageUrl, {
        responseType: "arraybuffer",
        headers: {
          Authorization: `Basic ${encodedToken}`,
        },
      });

      const imageBlob = new Blob([responseBlob.data], {
        type: getContentType(fileName),
      });
      bloBarray.push({ imageBlob, fileName });
    } catch (error) {
      console.error("Error uploading image:", error);
      return null;
    }
  });

  await Promise.all(uploadPromises);

  return attachImageToObjectClass(pr.pullRequestId, bloBarray);
};

export const fetchPrsAndAttachments = async (loader) => {
  const labels = [];
  const limit = pLimit(10);

  const currentDate = new Date().toISOString();
  const lastYearDate = "2024-06-01T00:00:00Z";
  const uri = `https://dev.azure.com/${AZURE_DEVOPS_ORG}/${AZURE_PROJECT_NAME}/_apis/git/repositories/${repositoryId}/pullrequests?searchCriteria.status=completed&searchCriteria.targetRefName=refs/heads/develop&searchCriteria.queryTimeRangeType=created&searchCriteria.minTime=${lastYearDate}&searchCriteria.maxTime=${currentDate}&api-version=7.1-preview.1`;

  let pullRequests = [];
  let skipCount = 0;
  const pageSize = 100;

  try {
    loader(true);
    let hasMoreResults = true;
    while (hasMoreResults) {
      // eslint-disable-next-line no-await-in-loop
      const response = await axios.get(uri, {
        headers: {
          Authorization: `Basic ${encodedToken}`,
          "Content-Type": "application/json",
        },
        params: {
          $top: pageSize,
          $skip: skipCount,
        },
      });

      pullRequests = pullRequests.concat(response.data.value);

      if (response.data.value.length < pageSize) {
        hasMoreResults = false;
      } else {
        skipCount += pageSize;
      }
    }

    const pullRequestsWithAttachments = await Promise.all(
      pullRequests.map((pr) =>
        limit(async () => {
          const attachmentsUri = `https://dev.azure.com/${AZURE_DEVOPS_ORG}/${AZURE_PROJECT_NAME}/_apis/git/repositories/${repositoryId}/pullRequests/${pr.pullRequestId}/attachments?api-version=7.1-preview.1`;

          try {
            const attachmentsResponse = await fetch(attachmentsUri, {
              method: "GET",
              headers: {
                Authorization: `Basic ${encodedToken}`,
                "Content-Type": "application/json",
              },
            });

            if (attachmentsResponse.ok) {
              const commitData = await attachmentsResponse.json();
              pr.attachments = commitData.value;

              pr.scrivitoAttachments = await uploadImagesToScrivito(pr);

              if (Array.isArray(pr?.labels) && pr.labels.length > 0) {
                labels.push(...pr.labels);
              }
            } else {
              console.error(
                `Error fetching commits for PR ${pr.pullRequestId}: ${attachmentsResponse.status}`,
              );
              pr.attachments = [];
            }
          } catch (error) {
            console.error(
              `Error fetching attachments for PR ${pr.pullRequestId}: ${error.message}`,
            );
            pr.attachments = [];
          }

          return pr;
        }),
      ),
    );
    loader(false);
    return [pullRequestsWithAttachments, labels];
  } catch (error) {
    console.error(error);
    return [];
  }
};
export const parseDescription = (description) => {
  let remainingDescription = description;

  const imageUrlRegex =
    /https:\/\/dev\.azure\.com\/[^\s)]+(?:\.(jpg|jpeg|png|gif))(?![^\s]*[)\]]*[^/])/gi;

  const imageMarkdownRegex = /!\[.*?\]\(https:\/\/dev\.azure\.com\/[^)\s]*\)/g;
  const emptyMarkdownRegex = /!\[.*?\]\(.*?\)/g;
  const attachmentsRegex =
    /!\[.*?\]\(https:\/\/dev\.azure\.com\/[^)\s]*\/attachments\/\)/g;
  const regex =
    /https:\/\/dev\.azure\.com\/DEVLY-IT-Software-Development\/[a-f0-9-]+\/_apis\/git\/repositories\/[a-f0-9-]+\/pullRequests\/\d+\/attachments\//g;
  const urlRegex = /https?:\/\/[^\s]+/g;
  remainingDescription = remainingDescription
    .replace(imageUrlRegex, "")
    .replace(imageMarkdownRegex, "")
    .replace(emptyMarkdownRegex, "")
    .replace(attachmentsRegex, "")
    .replace(regex, "")
    .replace(urlRegex, "")
    .replace(/^#### Images\s*$/gm, "")
    .replace(
      /\[\s*x\s*\]/gi,
      '<span class="checked-symbol"><input type="checkbox" checked readonly /></span>',
    )
    .replace(/!\[.*?\]\(.*?\)?/g, "");

  remainingDescription = remainingDescription.replace(
    /\*\*(NOTASK|TASK|Bug|New|New Feature|Fixed:)\*\*/g,
    "",
  );

  return remainingDescription.trim();
};
