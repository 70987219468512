// Project tree node manipulations
import { ProjectDataQry } from "api/queries";
import { nodeCreate } from "api/cacheUtils/treeNodeUpdates/nodeCreate";
import { nodeDelete } from "api/cacheUtils/treeNodeUpdates/nodeDelete";
import { nodeCopy } from "api/cacheUtils/treeNodeUpdates/nodeCopy";
import { nodeUpdate } from "api/cacheUtils/treeNodeUpdates/nodeUpdate";
import { addConfig } from "api/cacheUtils/treeNodeUpdates/addConfig";
import { moveConfig } from "./treeNodeUpdates/moveConfig";
import { deleteConfig } from "./treeNodeUpdates/deleteConfig";
import { updateConfig } from "./treeNodeUpdates/updateConfig";

const configCopy = (projectTree, _copiedConfig, _args) => projectTree;

export const refreshCacheProjectTree = (action, result, args, cache, info) => {
  let callback = null;
  switch (action) {
    case "createNode":
      callback = nodeCreate;
      break;
    case "updateNode": // updates and/or moves node
      callback = nodeUpdate;
      break;
    case "deleteNode":
      callback = nodeDelete;
      break;
    case "copyNode":
      callback = nodeCopy;
      break;
    case "updateConfig":
      callback = updateConfig;
      break;
    case "deleteConfig":
      callback = deleteConfig;
      break;
    case "copyConfig":
      callback = configCopy;
      break;
    case "moveConfig":
      callback = moveConfig;
      break;
    case "addConfig":
      callback = addConfig;
      break;
    default:
  }
  if (!callback) {
    return;
  }

  cache.updateQuery(
    {
      query: ProjectDataQry,
      variables: {
        id:
          args.projectId ||
          new URLSearchParams(document.location.search)?.get("projectId"),
      },
    },
    (data) => ({
      ...data,
      project: data?.project && {
        ...data.project,
        updated: true,
        projectTree: callback(
          info?.optimistic
            ? structuredClone(data.project.projectTree)
            : data.project.projectTree,
          result,
          args,
          info,
        ),
      },
    }),
  );
};
