import React, { useCallback, useState, useEffect } from "react";
import * as Scrivito from "scrivito";
import cx from "classnames";
import {
  useAppBaseContext,
  useConfiguratorPopupContext,
  useStateLoaderContext,
  useUserContext,
} from "providers";
import { useProjectContext } from "providers/ProjectProvider";
import { Icon } from "../../Icon/Icon";
import { NavRightSidebar } from "./NavRightSidebar";
import { LoginButton } from "../LoginButton";
import { Logo } from "../Logo";

import s from "./navConfigurator.module.scss";

export const NavConfigurator = Scrivito.connect(({ homepage }) => {
  if (!homepage) {
    return null;
  }
  const { isSameProj } = useAppBaseContext();
  const { isUpdateButtonActive, showConfiguratorPopup } =
    useConfiguratorPopupContext();
  const { isLoaderLine } = useStateLoaderContext();
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const { projectId } = Scrivito.currentPageParams();
  const { isUserLoggedIn } = useUserContext();

  const { title, setProjectId } = useProjectContext();

  const onToggleSideBar = useCallback(() => {
    setSidebarOpen((val) => !val);
  }, []);

  useEffect(() => {
    setProjectId(projectId);
  }, [projectId, setProjectId]);

  const goHome = useCallback(() => {
    if (isUserLoggedIn) {
      const home = homepage.get("welcomePage");
      const navigate = () => Scrivito.navigateTo(home);
      if (isUpdateButtonActive) {
        showConfiguratorPopup(navigate);
      } else {
        navigate();
      }
    }
  }, [isUserLoggedIn, isUpdateButtonActive, showConfiguratorPopup, homepage]);

  return (
    <>
      <header
        data-testid="nav-configurator"
        className={cx(
          "py-3",

          s.configuratorHeader,
          s.configuratorHeaderWithGrayBar,
          {
            [s.loader]: isLoaderLine,
          },
        )}
      >
        <div className="d-flex justify-content-between px-4">
          <div className="d-flex align-items-center">
            <Logo
              homepage={homepage}
              linkClass="me-4"
              imgClass="m-0 icon-32 w-auto"
              isConfigLog
            />
            <h3 className="mb-0 text-uppercase text-gray-500">
              {homepage.get("configuratorNavTitle")}
            </h3>
            <div
              className={cx(
                "d-flex align-items-center mx-2 px-2 border-start border-gray-400",
                { "d-none": !projectId },
              )}
            >
              <Scrivito.LinkTag
                to={homepage.get("projectConfiguratorPage")}
                params={{ projectId }}
                className="d-flex flex-no-wrap"
              >
                <Icon
                  prefix="trox"
                  iconName="folder-regular"
                  className="icon-16 text-gray-500 me-2"
                />
                <div className="font-bold text-gray-500">{title}</div>
              </Scrivito.LinkTag>
            </div>
          </div>

          <div className="d-flex align-items-center">
            {!isSameProj && (
              <div className="position-relative me-6">
                {/* TODO: Rows below are for future when a shop there's in configurator app */}
                {/* <div */}
                {/*  className={cx( */}
                {/*    ` */}
                {/*  position-absolute */}
                {/*  d-flex */}
                {/*  justify-content-center */}
                {/*  align-items-center */}
                {/*  w-20 */}
                {/*  h-20 */}
                {/*  rounded-circle */}
                {/*  bg-blue-300 */}
                {/* `, */}
                {/*    s.cartBubble */}
                {/*  )} */}
                {/* > */}
                {/*  <span className={cx("d-flex align-items-center text-white", s.cartNumber)}>18</span> */}
                {/* </div> */}
                <button
                  data-testid="NavConfigurator_shopping-cart-btn"
                  className="p-0 bg-transparent border-0"
                >
                  <a
                    className="text-decoration-none"
                    href="https://shop.trox.de/"
                  >
                    {/* TODO: forthcoming feature (hidden acc US-19953) hidden with className='d-none' */}
                    <Icon
                      prefix="trox"
                      iconName="shopping-cart-light"
                      className="icon-32 text-blue-800 d-none"
                      title="shopping-cart"
                    />
                  </a>
                </button>
              </div>
            )}
            <LoginButton />

            {!isSameProj && (
              <>
                <Scrivito.LinkTag
                  onClick={goHome}
                  className="bg-transparent p-0 ms-6 text-decoration-none"
                  data-testid="home-icon"
                >
                  <Icon
                    prefix="trox"
                    iconName="home-header"
                    className="icon-32"
                    title="home"
                    color="white"
                  />
                </Scrivito.LinkTag>

                <button
                  data-testid="hamburger-icon"
                  onClick={onToggleSideBar}
                  className="bg-transparent border-0 p-0 ms-4"
                  title="bars-button"
                >
                  <Icon
                    prefix="trox"
                    iconName="bars-regular"
                    className="icon-32 text-gray-400"
                    title="bars"
                  />
                </button>
              </>
            )}
          </div>
        </div>
      </header>
      {isSidebarOpen && (
        <NavRightSidebar
          onToggleSideBar={onToggleSideBar}
          isOpen={isSidebarOpen}
        />
      )}
    </>
  );
});
