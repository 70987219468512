const getDataByFieldType = ({
  field,
  parentIdentifier,
  initialFieldsRelations,
  parentFieldInputType,
  isDefaultValues,
}) => {
  if (parentFieldInputType === "tableheader") {
    return;
  }
  const formikFieldNameBase =
    `${parentIdentifier}/#${field.fieldKey}/#${field.fieldType}`.replaceAll(
      ".",
      ",",
    );

  switch (field.fieldInputType) {
    case "radiobutton":
      initialFieldsRelations[field.fieldKey] = {
        fieldName: `${parentIdentifier}/#radiobutton`.replaceAll(".", ","),
        fieldValue: formikFieldNameBase,
        onAction: field.onAction,
      };
      if (field.fieldValue) {
        return {
          // TODO we might get rid of .replaceAll(".", ","), when Pentalog API will provide a small patch
          [`${parentIdentifier}/#radiobutton`.replaceAll(".", ",")]:
            formikFieldNameBase,
        };
      }
      break;
    case "label":
      break;
    default:
      initialFieldsRelations[field.fieldKey] = {
        ...initialFieldsRelations[field.fieldKey],
        name: formikFieldNameBase,
        enabled:
          initialFieldsRelations[field.fieldKey]?.enabled || field.enabled,
        onAction: field.onAction,
        fieldDefaultValue: field.fieldDefaultValue,
      };
      if (field.fieldValue && field.onAction?.fieldValueChange) {
        const onAction = field.onAction.fieldValueChange[0];
        if (onAction.newValue) {
          onAction.states.forEach((onActionItem) => {
            if (initialFieldsRelations[onActionItem.fieldKey]) {
              initialFieldsRelations[onActionItem.fieldKey] = {
                ...initialFieldsRelations[onActionItem.fieldKey],
                enabled:
                  initialFieldsRelations[onActionItem.fieldKey].enabled ||
                  onActionItem.enabled,
                affectedBy:
                  onActionItem.enabled === undefined
                    ? initialFieldsRelations[onActionItem.fieldKey].affectedBy
                    : [
                        ...initialFieldsRelations[onActionItem.fieldKey]
                          .affectedBy,
                        field.fieldKey,
                      ],
              };
            } else {
              initialFieldsRelations[onActionItem.fieldKey] = {
                ...initialFieldsRelations[onActionItem.fieldKey],
                enabled: onActionItem.enabled,
                affectedBy:
                  onActionItem.enabled === undefined
                    ? initialFieldsRelations[onActionItem.fieldKey]
                        ?.affectedBy || []
                    : [field.fieldKey],
              };
            }
          });
        }
      }

      return {
        [formikFieldNameBase]: isDefaultValues
          ? field.fieldDefaultValue
          : field.fieldValue,
      };
  }
};

// should be removed once BE fix typing issues
function parseBooleanStrings(data) {
  if (typeof data === "string") {
    if (data === "true") {
      return true;
    }
    if (data === "false") {
      return false;
    }
  } else if (Array.isArray(data)) {
    return data.map(parseBooleanStrings);
  } else if (typeof data === "object" && data !== null) {
    const newData = {};
    // eslint-disable-next-line guard-for-in
    for (const key in data) {
      newData[key] = parseBooleanStrings(data[key]);
    }
    return newData;
  }
  return data;
}

export const getFormInitialValues = (questions, isDefaultValues = false) => {
  const initialFieldsRelations = {};
  const typeFixedQuestions = parseBooleanStrings(questions);
  const initialValues = Object.assign(
    {},
    ...typeFixedQuestions
      .map(({ fields, identifier: questionId }) =>
        fields.map((parentField) => {
          const { fieldInputType, fieldKey, fieldValue, values } =
            parentField || {};
          if (fieldInputType === "dropdown") {
            const selectedItem =
              values?.find((item) => item.fieldKey === fieldValue) || {};
            return {
              // TODO we might get rid of .replaceAll(".", ","), when Pentalog API will provide a small patch
              [`${questionId}/#${fieldKey}/#dropdown`.replaceAll(".", ",")]: {
                value: selectedItem.fieldKey,
                label: selectedItem.fieldValue,
              },
            };
          }
          return values
            ? values.map((field) =>
                getDataByFieldType({
                  field,
                  parentIdentifier: `${questionId}/#${fieldKey}`,
                  initialFieldsRelations,
                  fieldInputType,
                  isDefaultValues,
                }),
              )
            : getDataByFieldType({
                field: parentField,
                parentIdentifier: questionId,
                initialFieldsRelations,
                isDefaultValues,
              });
        }),
      )
      .flat(3),
  );

  return {
    ...(!isDefaultValues && { fieldsRelation: initialFieldsRelations }),
    ...initialValues,
  };
};

export const getAnswersFromValues = (questions, values) => {
  const result = {};

  // Build answer obj based on questions
  questions.forEach((question) => {
    result[question.identifier] = {
      identifier: question.identifier,
      fields: question.fields.map((field) => ({
        fieldKey: field.fieldKey,
        fieldType: field.fieldType,
        fieldInputType: field.fieldInputType,
        fieldValue: field.fieldValue,
        ...(field.values
          ? {
              values: field.values.map((value) => ({
                fieldKey: value.fieldKey,
                fieldType: value.fieldType,
                fieldValue: value.fieldValue,
                // TODO should be unkomment once BE added it to GQL schema
                // ...(value.fieldInputType
                //   ? { fieldInputType: value.fieldInputType }
                //   : {}),
              })),
            }
          : {}),
      })),
    };
  });

  for (const [key, value] of Object.entries(values)) {
    // TODO we might get rid of .replaceAll(".", ","), when Pentalog API will provide a small patch
    const splitKeys = key.replaceAll(",", ".").split("/#");
    const type = splitKeys.pop();
    if (type === "radiobutton") {
      // TODO we might get rid of .replaceAll(".", ","), when Pentalog API will provide a small patch
      const splitValue = value.replaceAll(",", ".").split("/#");
      // set selected radiobtn to true and all other to false
      result[splitKeys[0]].fields.forEach((item) => {
        // we need that if cause we have some cases when we have different types of inputs together with radiobuttons
        if (item.fieldInputType === "radiobutton") {
          item.fieldValue = (item.fieldKey === splitValue[1]).toString();
        }
      });
    }

    if (splitKeys.length === 2) {
      // update fieldValue based on answers in general case
      const fieldToModify = result[splitKeys[0]].fields.filter(
        (field) => field.fieldKey === splitKeys[1],
      )[0];

      fieldToModify.fieldValue = type === "dropdown" ? value?.value : value;
    }

    if (splitKeys.length === 3) {
      // update fieldValue based on answer in cases when we have values inside ( compositeField/table with fields & dropdowns)
      const searchedFieldIndex = result[splitKeys[0]].fields.findIndex(
        (fieldItem) => fieldItem.fieldKey === splitKeys[1],
      );

      if (searchedFieldIndex === -1) {
        console.error("something goes wrong in fields");
        return;
      }
      const searchedValuesIndex = result[splitKeys[0]].fields[
        searchedFieldIndex
      ].values.findIndex((valueItem) => valueItem.fieldKey === splitKeys[2]);

      if (searchedValuesIndex === -1) {
        console.error("something goes wrong in values");
        return;
      }

      result[splitKeys[0]].fields[searchedFieldIndex].values[
        searchedValuesIndex
      ].fieldValue = type === "dropdown" ? value?.value : value;
    }
  }

  const resultArray = [];
  // removing identifiers from answer since answer wait just an array, and it's match simpler to get access through named identifiers when we set values
  for (const [_key, value] of Object.entries(result)) {
    resultArray.push(value);
  }

  return resultArray;
};

export const getSplitByTypeFields = (data) => {
  const result = [];
  let currentFieldType = "";
  let currentArray = [];

  for (let i = 0; i < data.length; i += 1) {
    if (data[i].fieldType !== currentFieldType) {
      // start a new array if the field type has changed
      if (currentArray.length > 0) {
        result.push(currentArray);
      }
      currentFieldType = data[i].fieldType;
      currentArray = [data[i]];
    } else {
      // add the element to the current array if the field type is the same
      currentArray.push(data[i]);
    }
  }

  // add the last array to the result
  if (currentArray.length > 0) {
    result.push(currentArray);
  }

  return result;
};

export const groupFieldsByFieldGroups = (fieldGroups, fields) => {
  if (!fieldGroups || fieldGroups?.length === 0) {
    return fields;
  }

  return fieldGroups.map((fieldGroup) => ({
    title: fieldGroup.title,
    fieldGroupId: fieldGroup.key,
    fields: fields
      .flat()
      .filter((field) => fieldGroup.key === field.fieldGroupId),
  }));
};

// It was written to avoid eval
export const compareValues = (number, compareWith) => {
  const comparisonRegex = /^(>=|<=|>|<|=)/;
  const comparisonMatch = compareWith.match(comparisonRegex);

  const comparisonOperator = comparisonMatch[0];
  const comparisonValue = Number(
    compareWith.substring(comparisonOperator.length),
  );

  switch (comparisonOperator) {
    case ">=":
      return number >= comparisonValue;
    case "<=":
      return number <= comparisonValue;
    case ">":
      return number > comparisonValue;
    case "<":
      return number < comparisonValue;
    case "=":
      return number === comparisonValue;
    default:
      console.error("undefined comparisonOperator");
  }
};

export const getValuesArr = (values) =>
  Object.entries(values).filter(([key, _]) => key.includes("#tableRow"));

export function groupObjectsByTitle(arr, cn) {
  const grouped = {};

  arr.forEach((item) => {
    item.values.slice(cn - 1).forEach((value) => {
      const { title } = value;
      if (title) {
        if (grouped[title]) {
          grouped[title] += 1;
        } else {
          grouped[title] = 1;
        }
      }
    });
  });

  return grouped;
}

export function calculateSumAndConcatenate(obj, entries) {
  const result = [];

  for (const key of Object.keys(obj)) {
    const sum = entries.reduce((acc, entry) => {
      const [entryKey, entryValue] = entry;
      if (
        entryKey.includes(`#${key.replace(/-/g, "_")}`) &&
        !isNaN(entryValue)
      ) {
        return acc + parseInt(entryValue, 10);
      }
      return acc;
    }, 0);

    if (sum > 0) {
      result.push(`${key}: ${sum}`);
    }
  }

  return result.join(" | ");
}
