import React, { useEffect, useState } from "react";
import * as Scrivito from "scrivito";
import NiceModal from "@ebay/nice-modal-react";
import cx from "classnames";
import { Modal, ModalBody } from "reactstrap";
import { translate } from "utils";
import { Icon } from "../Icon/Icon";
import { Button } from "../Button";
import s from "./ProductConfiguratorModal/ConfiguratorModal.module.scss";

export const SpecificationTextModal = NiceModal.create(
  Scrivito.connect(({ specificationText, productImage, productName }) => {
    const modal = NiceModal.useModal();
    const [copied, setCopied] = useState(false);
    const accessoireText = "Dummy Text";
    const [activeText, setActiveText] = useState("specification");

    useEffect(() => {
      let clearClipboardTimeout;

      if (copied) {
        clearClipboardTimeout = setTimeout(() => {
          navigator.clipboard
            .writeText("")
            .catch((error) => {
              console.error("Failed to clear clipboard: ", error);
            })
            .finally(() => setCopied(false));
        }, 5000);
      }

      return () => {
        clearTimeout(clearClipboardTimeout);
      };
    }, [copied]);

    const copyToClipboard = async () => {
      const textToCopy =
        activeText === "specification" ? specificationText : accessoireText;
      const htmlContent = `<pre style="font-family: Courier, monospace;">${textToCopy}</pre>`;
      if (
        navigator.clipboard &&
        navigator.clipboard.write &&
        typeof ClipboardItem !== "undefined"
      ) {
        await navigator.clipboard
          .write([
            new ClipboardItem({
              "text/html": new Blob([htmlContent], { type: "text/html" }),
            }),
          ])
          .then(() => {
            setCopied(true);
          })
          .catch((error) => {
            console.error("Failed to copy text: ", error);
          });
      } else {
        const tempElement = document.createElement("div");
        tempElement.innerHTML = htmlContent;
        document.body.appendChild(tempElement);

        const range = document.createRange();
        range.selectNodeContents(tempElement);
        const selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);

        try {
          document.execCommand("copy");
          setCopied(true);
        } catch (error) {
          console.error("Failed to copy text: ", error);
        }

        selection.removeAllRanges();
        document.body.removeChild(tempElement);
      }
    };

    return (
      <Modal
        isOpen={modal.visible}
        centered={true}
        size="xl"
        toggle={modal.hide}
        className={"text"}
      >
        <div className="modal-header align-items-start w-100 pt-0 mt-6_5">
          <div className="row w-100">
            <div className="col-12 col-md-2">
              {productImage && (
                <Scrivito.ImageTag
                  className="object-fit-contain mb-0"
                  width="110"
                  content={productImage}
                  height="110"
                />
              )}
            </div>
            <div className="col-12 col-md-6">
              <h1 className="h5 mb-1 d-inline-block">{productName}</h1>
              <Icon
                iconName="fa-regular fa-circle-exclamation"
                /* TODO: forthcoming feature (hidden acc US-19953) icon was hidden with 'className="d-none"' */
                className="ms-2 d-none"
              />
              <h4>
                {translate("PRODUCT_CONFIGURATOR_MODAL_SPECIFICATION_TEXT")}
              </h4>
              {/* TODO: forthcoming feature (hidden acc US-19953) icon was hidden with 'className="d-none"'  */}
              <div className="border border-info p-1 rounded d-none">
                <Button
                  data-testid="SpecificationTextModal_accessoire-btn"
                  color={activeText === "accessoire" ? "info" : "link"}
                  className="rounded-0 w-50 m-0 p-1"
                  onClick={() => setActiveText("accessoire")}
                >
                  {translate("PRODUCT_CONFIGURATOR_MODAL_ACCESSOIRE_TEXT")}
                </Button>
                <Button
                  data-testid="SpecificationTextModal_specification-btn"
                  color={activeText === "specification" ? "info" : "link"}
                  className="rounded-0 w-50 m-0 p-1"
                  onClick={() => setActiveText("specification")}
                >
                  {translate("PRODUCT_CONFIGURATOR_MODAL_SPECIFICATION_TEXT")}
                </Button>
              </div>
            </div>
          </div>
          <button className="btn-close" onClick={modal.hide} />
        </div>
        <ModalBody
          style={{
            whiteSpace: "pre-wrap",
          }}
          className={cx(
            "specification-modal-font pb-0 pt-0",
            s.specficationBody,
          )}
        >
          {activeText === "specification" ? specificationText : accessoireText}
        </ModalBody>
        <div className="modal-footer align-items-start w-100 mt-3">
          <Button onClick={copyToClipboard} disabled={copied}>
            {!copied ? translate("COPY_TEXT") : translate("COPIED")}
          </Button>
        </div>
      </Modal>
    );
  }),
);
