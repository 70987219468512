import { useEffect } from "react";
import {
  addScrivitoDocumentToSublist,
  formatDisplayedName,
  formatFileDescription,
} from "utils";
import { items } from "variables";

export const useUpdatedItemsForProductDownload = ({
  documents,
  presentation,
  setItemsData,
}) => {
  useEffect(() => {
    const updateItemsData = async () => {
      const updatedItems = await Promise.all(
        items.map(async (item) => {
          // PRODUCT_INFORMATION
          if (
            item.title === "PRODUCT_INFORMATION" &&
            documents.productInfo.length > 0
          ) {
            const newSublist = await addScrivitoDocumentToSublist(
              documents.productInfo,
            );

            return {
              ...item,
              checkboxContent: [...item.checkboxContent, ...newSublist].map(
                (contentItem) => ({
                  ...contentItem,
                  fileName: formatDisplayedName(item, presentation),
                  fileDescription: formatFileDescription(contentItem),
                }),
              ),
            };
          }

          // PRODUCT_CONFIGURATOR_CERTIFICATE
          if (
            item.title === "PRODUCT_CONFIGURATOR_CERTIFICATE" &&
            documents.certificates.length > 0
          ) {
            const newSublist = await addScrivitoDocumentToSublist(
              documents.certificates,
            );

            return {
              ...item,
              checkboxContent: [...item.checkboxContent, ...newSublist].map(
                (contentItem) => ({
                  ...contentItem,
                  fileName: formatDisplayedName(item, presentation),
                  fileDescription: formatFileDescription(contentItem),
                }),
              ),
            };
          }

          // PRODUCT_CONFIGURATOR_MANUALS
          if (
            item.title === "PRODUCT_CONFIGURATOR_MANUALS" &&
            documents.manuals.length > 0
          ) {
            const newSublist = await addScrivitoDocumentToSublist(
              documents.manuals,
            );

            return {
              ...item,
              checkboxContent: [...item.checkboxContent, ...newSublist].map(
                (contentItem) => ({
                  ...contentItem,
                  fileName: formatDisplayedName(item, presentation),
                  fileDescription: formatFileDescription(contentItem),
                }),
              ),
            };
          }

          // Default case
          return {
            ...item,
            checkboxContent: [...item.checkboxContent].map((contentItem) => ({
              ...contentItem,
              fileName: formatDisplayedName(item, presentation),
              fileDescription: formatFileDescription(item),
            })),
          };
        }),
      );

      setItemsData(updatedItems);
    };

    updateItemsData();
  }, [documents, presentation, setItemsData]);
};
