import React from "react";
import { Provider } from "urql";
import * as Scrivito from "scrivito";
import { useCreateUrqlClient } from "api/urqlClient";
import { translate } from "utils";

const ClientContext = React.createContext({});

export const UrqlProvider = Scrivito.connect(({ children }) => {
  const isLoadingText = translate("IS_LOADING_TEXT");
  const result = useCreateUrqlClient({ isLoadingText });
  const [clientObj, setClientObj] = React.useState(result);

  return (
    <ClientContext.Provider
      value={{
        resetUrqlClient: () => {
          setClientObj(result);
        },
        client: clientObj.client,
      }}
    >
      <Provider value={clientObj.client}>{children}</Provider>
    </ClientContext.Provider>
  );
});

export const useUrqlContext = () => React.useContext(ClientContext);
