import { SORTING_CRITERIA } from "variables";

export const getCommissionAsProduct = ({ title, centAmount, currency }) => ({
  title,
  totalPrice: {
    centAmount,
    fractionDigits: 0,
    currencyCode: currency,
  },
  unitPrice: {
    centAmount,
    fractionDigits: 0,
    currencyCode: currency,
  },
});

export const getProductListVariables = ({
  sortCriteria,
  isOrderDescending,
  projectId,
  currentPageNo,
  nodeId,
  itemsPerPage,
}) => {
  switch (sortCriteria) {
    case SORTING_CRITERIA.PRESENTATION:
    case SORTING_CRITERIA.NAME:
      return {
        sort: {
          sortBy: sortCriteria,
          sortOrder: isOrderDescending ? "desc" : "asc",
        },
        id: projectId,
        productsLimit: itemsPerPage,
        productsPage: currentPageNo,
        projectNodeId: nodeId,
      };
    default:
      return {
        id: projectId,
        productsLimit: itemsPerPage,
        projectNodeId: nodeId,
        productsPage: currentPageNo,
      };
  }
};
