import React, { useCallback } from "react";
import * as Scrivito from "scrivito";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import cx from "classnames";
import { Icon } from "../../Icon/Icon";

export const ZonesPagination = Scrivito.connect(
  ({ pagesAround = 4, pagesNumbersArr, currentPageIdx, onChangePage }) => {
    const startIdx = useCallback(
      (pageIdx) => {
        if (
          pageIdx > pagesAround + 1 &&
          pageIdx < pagesNumbersArr.length - pagesAround - 1
        ) {
          return pageIdx - pagesAround - 1;
        }
        if (pageIdx <= pagesAround + 1) {
          return 0;
        }
        if (pageIdx >= pagesNumbersArr.length - pagesAround - 1) {
          return pagesNumbersArr.length - pagesAround * 2 - 2;
        }
      },
      [pagesAround, pagesNumbersArr.length],
    );

    const endIdx = useCallback(
      (pageIdx) => {
        if (pageIdx > pagesAround) {
          return pageIdx + pagesAround + 2;
        }
        if (pageIdx <= pagesAround * 2 + 2) {
          return pagesAround * 2 + 3;
        }
        return pagesNumbersArr.length - 1;
      },
      [pagesAround, pagesNumbersArr.length],
    );

    const handlePageChange = (pageIdx) => {
      onChangePage(pageIdx);
    };

    return (
      <Pagination aria-label="Zones navigation">
        <PaginationItem disabled={currentPageIdx === 1}>
          <PaginationLink onClick={() => handlePageChange(currentPageIdx - 1)}>
            <Icon prefix="trox" iconName="chevron-left-regular" />
          </PaginationLink>
        </PaginationItem>

        {pagesNumbersArr.map(
          (pageNum, idx) =>
            idx > 0 && (
              <PaginationItem
                key={idx}
                active={pageNum === pagesNumbersArr[currentPageIdx]}
                className={cx({
                  "d-none":
                    idx <= startIdx(currentPageIdx) ||
                    idx >= endIdx(currentPageIdx),
                })}
              >
                <PaginationLink onClick={() => handlePageChange(idx)}>
                  {pageNum}
                </PaginationLink>
              </PaginationItem>
            ),
        )}

        <PaginationItem
          disabled={currentPageIdx === pagesNumbersArr.length - 1}
        >
          <PaginationLink onClick={() => handlePageChange(currentPageIdx + 1)}>
            <Icon prefix="trox" iconName="chevron-right-regular" />
          </PaginationLink>
        </PaginationItem>
      </Pagination>
    );
  },
);
