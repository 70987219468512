import React, { useCallback, useEffect, useState } from "react";
import * as Scrivito from "scrivito";
import cx from "classnames";
import NiceModal from "@ebay/nice-modal-react";
import { useAppBaseContext, useConfiguratorPopupContext } from "providers";
import { translate } from "utils";
import { useUserContext } from "providers/UserContextProvider/UserContextProvider";
import { UncontrolledTooltip } from "reactstrap";
import { Icon } from "../../Icon/Icon";
import { LinkButtonWithChevron } from "../../LinkButtonWithChevron";
import { LeftSidebarSubNavHeader } from "../LeftSidebarSubNavHeader";
import { AddWizardToProject } from "../../_Modals/AddWizardToProject";

import s from "../LeftSidebar.module.scss";

export const LeftSidebarWizardsSubnavFirst = Scrivito.connect(
  ({ references: [wizardListObjRef, wizardObjRef], setActiveButton }) => {
    const { projectId, subType } = Scrivito.currentPageParams();
    const [isWizardListItemActive, setIsWizardListItemActive] = useState(false);
    const { isProd } = useAppBaseContext();
    const { isUpdateButtonActive, showConfiguratorPopup } =
      useConfiguratorPopupContext();
    const { scrivitoTenant } = useUserContext();

    useEffect(
      () =>
        setIsWizardListItemActive(
          Scrivito.currentPage().objClass() === "WizardList",
        ),
      [subType],
    );

    const navigateToWizardPage = (wizardSubtype) => {
      setActiveButton(null);
      Scrivito.navigateTo(() => wizardObjRef, {
        params: {
          subType: wizardSubtype,
          projectId,
          step: "0",
        },
      });
    };

    const wizardListNav = useCallback(() => {
      setActiveButton(null);
      Scrivito.navigateTo(wizardListObjRef, {
        params: { projectId },
      });
    }, [projectId, wizardListObjRef, setActiveButton]);

    const navigateToWizardList = useCallback(() => {
      if (isUpdateButtonActive) {
        showConfiguratorPopup(wizardListNav);
      } else {
        wizardListNav();
      }
    }, [isUpdateButtonActive, showConfiguratorPopup, wizardListNav]);

    return (
      <>
        <LinkButtonWithChevron
          onClick={navigateToWizardList}
          className={cx(s.leftSidebarSubNavLink, {
            [s.leftSidebarSubNavLinkActive]: isWizardListItemActive,
          })}
        >
          <LeftSidebarSubNavHeader
            referenceObj={wizardListObjRef}
            title={translate("WIZARDS_OVERVIEW_TITLE")}
            subtitle={translate("WIZARDS_OVERVIEW_SUBTITLE")}
          />
        </LinkButtonWithChevron>
        {wizardListObjRef?.get("wizardItem")?.map((wizard) => {
          const isProdReady = wizard.get("isProdReady");
          if (!isProdReady && isProd) {
            return null;
          }
          const wizardSubtype = wizard.get("subType");
          if (
            wizardSubtype === "roomcontrolwireless" &&
            scrivitoTenant !== "NO"
          ) {
            return null;
          }
          const linkForMenuItem = wizard.get("linkForMenuItem");
          const wizardTitle = translate(
            `WIZARDS_OVERVIEW_${wizardSubtype}_TITLE`,
          );
          const wizardSubtitle = translate(
            `WIZARDS_LEFTSIDEBAR_MENU_${wizardSubtype}_SUBTITLE`,
          );

          const navigateToWizard = () => {
            if (linkForMenuItem) {
              window.open(linkForMenuItem.url(), "_blank").focus();
            } else if (!projectId) {
              NiceModal.show(AddWizardToProject, {
                wizardItem: wizard,
                setActiveButton,
              });
            } else {
              navigateToWizardPage(wizardSubtype);
            }
          };
          return (
            <div
              key={wizardSubtype}
              className={cx(
                "px-4 py-3 d-flex justify-content-between align-items-center gap-2 text-nowrap text-decoration-none cursor-pointer",
                s.leftSidebarSubNavLink,
                {
                  [s.leftSidebarSubNavLinkActive]: subType === wizardSubtype,
                },
              )}
              onClick={() => {
                if (isUpdateButtonActive) {
                  showConfiguratorPopup(navigateToWizard);
                  return;
                }
                navigateToWizard();
              }}
            >
              <div id={wizardTitle.replace(/[^a-zA-Z0-9]/g, "")}>
                <h4 className={cx("title mb-0", s.leftSidebarSubNavLinkTitle)}>
                  {wizardTitle}
                </h4>
                <span
                  className={cx("fw-normal", s.leftSidebarSubNavLinkSubtitle)}
                >
                  {wizardSubtitle}
                </span>
              </div>
              <UncontrolledTooltip
                target={wizardTitle.replace(/[^a-zA-Z0-9]/g, "")}
                placement="top"
              >
                {wizardTitle} <br />
                {wizardSubtitle}
              </UncontrolledTooltip>
              <Icon
                className="d-flex align-items-center"
                prefix="trox"
                iconName="chevron-right-regular"
              />
            </div>
          );
        })}
      </>
    );
  },
);
