import * as Scrivito from "scrivito";
import React, { useCallback, useMemo } from "react";
import { Tree } from "react-arborist";
import { useProjectTreeContext } from "providers/ProjectProvider";
import useResizeObserver from "use-resize-observer";
import { useAppBaseContext } from "providers";
import { projectTreeContentTranslations } from "variables/projectTreeContentTranslations";
import { Node } from "./components/Node";
import { calcRowHeight, isDragDisabled, disableDrop } from "./utils";
import { defaultOptions } from "./defaultOptions";
import {
  useNodeActions,
  useSyncProjectId,
  useSmoothScroll,
  useRedraw,
} from "./hooks";

export const ProjectTree = Scrivito.connect(({ options = {} }) => {
  const {
    treeRef,
    units,
    projectTree,
    error,
    openMap,
    copiedItem,
    setCopiedItem,
    projectId,
    projectName,
    setProjectId,
    toggleNode,
    showProductPictures,
    navigateToProduct,
    fetchProjectData,
  } = useProjectTreeContext();
  const { lang } = useAppBaseContext();
  // Translations needed for the project tree, fetched here instead of in the Node component to avoid unnecessary translate calls.
  const translations = useMemo(() => projectTreeContentTranslations(), []);
  // Default options for the project tree, which can be overridden by the options prop.
  const treeOptions = useMemo(
    () => ({ ...defaultOptions, ...options }),
    [options],
  );

  // resize observer for providing the correct width and height to the tree component.
  const { ref, width, height } = useResizeObserver();

  // Custom hook for animating the smooth scroll-to behaviour
  const { setScrollToIndex, setScrollOffset } = useSmoothScroll({
    treeRef,
    projectTree,
    windowHeight: height,
  });
  // Custom hook for node actions, which provides the actions for the nodes in the project tree.
  const nodeActions = useNodeActions(projectId, translations);
  // This custom hook ensures that the project tree is in sync with the projectId in the URL.
  useSyncProjectId(projectId, setProjectId);
  // This custom hook sets the copied item in the project tree context when the copiedItemArg is passed.

  // Function for ensuring that the node heights are correct after the project tree is updated.
  // It redraws the list, recalculating the heights of the nodes from the index that is passed to it.
  const { setRedrawIndex } = useRedraw({
    projectTree,
    showProductPictures,
    treeRef,
  });

  // Tree props
  const onMove = useCallback(
    (args) => nodeActions.moveNode(args, setRedrawIndex),
    [nodeActions, setRedrawIndex],
  );
  const childrenAccessor = useCallback((d) => d.nodes, []);
  const rowHeight = useCallback(
    (node) => calcRowHeight(node, showProductPictures, treeOptions),
    [showProductPictures, treeOptions],
  );

  // This boolean is used to determine if the project tree should be shown.
  const showTree = projectTree && openMap && !error && height && width;

  return (
    <div className="w-100 h-100" ref={ref}>
      {showTree && (
        <Tree
          data={projectTree}
          initialOpenState={openMap}
          childrenAccessor={childrenAccessor}
          onMove={onMove}
          indent={treeOptions.indentSize}
          width={width}
          height={height}
          ref={treeRef}
          rowHeight={rowHeight}
          disableDrag={
            treeOptions.dndDisabled ? true : (node) => isDragDisabled(node)
          }
          disableDrop={disableDrop}
          overscanCount={treeOptions.overscanCount}
          paddingBottom={32}
          onScroll={({ scrollOffset }) => setScrollOffset(scrollOffset)}
        >
          {(props) => (
            <Node
              {...props}
              options={treeOptions}
              nodeActions={nodeActions}
              projectId={projectId}
              projectName={projectName}
              copiedItem={copiedItem}
              setCopiedItem={setCopiedItem}
              showProductPictures={showProductPictures}
              translations={translations}
              setRedrawIndex={setRedrawIndex}
              setScrollToIndex={setScrollToIndex}
              handleToggleNode={toggleNode}
              navigateToProduct={navigateToProduct}
              fetchProjectData={fetchProjectData}
              units={units}
              lang={lang}
            />
          )}
        </Tree>
      )}
    </div>
  );
});
