import React from "react";
import * as Scrivito from "scrivito";
import { translate } from "utils";
import cx from "classnames";
import { ProjectTree } from "components/ProjectTree/ProjectTree";
import { ToggleShowPictures } from "components/ProjectTree/ToggleShowPictures";

export const ProjectStructure = Scrivito.connect(({ projectTitle }) => (
  <div className="mb-3 d-flex flex-column flex-grow-1">
    <div className="mb-3 flex-shrink-0">
      <h5 className="text-uppercase">
        {translate("ADD_PRODUCT_MODAL_PROJECT_STRUCTURE_TITLE")} |{" "}
        {projectTitle}
      </h5>
      <div className="d-flex flex-row justify-content-between align-items-center">
        <span className="text-gray-500">
          {translate("ADD_PRODUCT_MODAL_PROJECT_STRUCTURE_SUBTITLE")}
        </span>
        <ToggleShowPictures />
      </div>
    </div>
    <div className={cx("w-100 border rounded-1 p-3 d-flex flex-grow-1")}>
      <ProjectTree
        options={{
          dndDisabled: true,
          copyNodeDisabled: true,
          deleteNodeDisabled: true,
          duplicateNodeDisabled: true,
          editNodeDisabled: true,
          overscanCount: 10,
          ModelViewDisabled: true,
          downloadModalDisabled: true,
          resetOnPaste: false,
          productAddingModal: true,
        }}
      />
    </div>
  </div>
));
