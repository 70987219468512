import { useEffect, useState } from "react";
import * as Scrivito from "scrivito";
import { useAppBaseContext } from "providers/AppBaseProvider";
import { useUserContext } from "providers/UserContextProvider/UserContextProvider";

export const useFetchScrivitoDocumentsForProduct = ({ seriesId = null }) => {
  const [documents, setDocuments] = useState({
    certificates: [],
    productInfo: [],
    manuals: [],
  });

  const { lang } = useAppBaseContext();
  const { scrivitoTenant } = useUserContext();

  useEffect(() => {
    async function loadManuals() {
      const obj = await Scrivito.load(() =>
        Scrivito.Obj.where("_objClass", "equals", "ProductSeries")
          .and("epfId", "equals", seriesId?.toString())
          .and("categoryLanguages", "equals", lang)
          .and("categoryClients", "equals", scrivitoTenant)
          .and("categoryOwner", "equals", "SC")
          .first(),
      );

      if (obj) {
        const docs = await Scrivito.load(() => obj.get("documents"));
        const categorizedDocs = {
          certificates: [],
          productInfo: [],
          manuals: [],
        };
        docs.forEach((doc) => {
          const category = doc.get("categoryDownloadType");
          const categoryLanguages = doc.get("categoryLanguages");
          const categoryClients = doc.get("categoryClients");

          if (
            categoryLanguages.includes(lang) &&
            categoryClients.includes(scrivitoTenant)
          ) {
            if (category === "certificates") {
              categorizedDocs.certificates.push(doc);
            } else if (category === "product_info") {
              categorizedDocs.productInfo.push(doc);
            } else if (category === "manuals") {
              categorizedDocs.manuals.push(doc);
            }
          }
        });

        setDocuments(categorizedDocs);
      } else {
        setDocuments({
          certificates: [],
          productInfo: [],
          manuals: [],
        });
      }
    }

    loadManuals();
  }, [seriesId, lang, scrivitoTenant]);

  return { documents };
};
