import React from "react";
import * as Scrivito from "scrivito";
import cx from "classnames";

import { LeftSidebarSubnavFirst } from "./SubnavsFirst/LeftSidebarSubnavFirst";
import { LeftSidebarWizardsSubnavFirst } from "./SubnavsFirst/LeftSidebarWizardsSubnavFirst";
import s from "./LeftSidebar.module.scss";

export const LeftSidebarSubnav = Scrivito.connect(
  ({ activeButton, btn, references, setActiveButton }) => {
    const isOpened = activeButton?.title === btn.title;
    const isFirstLevel = activeButton?.subNavLevel.value === 0;

    const SubnavFirstComponent =
      btn.title === "LEFT_SIDEBAR_WIZARDS"
        ? LeftSidebarWizardsSubnavFirst
        : LeftSidebarSubnavFirst;

    return (
      <div
        data-testid="LeftSidebarSubnav_menu"
        className={cx(s.leftSidebarSubNav, "flex-shrink-0", {
          "position-fixed": isFirstLevel,
          [s.leftSidebarSubNavDark]: isFirstLevel,
          [s.leftSidebarSubNavOpened]: isOpened,
          [s.zIndex5]: references?.length > 0 && isFirstLevel,
        })}
      >
        <div
          className={cx({
            "px-1": references?.length > 0 && isFirstLevel,
          })}
        >
          {!activeButton ||
            (activeButton.subNavLevel.value === 0 && (
              <SubnavFirstComponent
                btn={btn}
                references={references}
                setActiveButton={setActiveButton}
              />
            ))}
        </div>
      </div>
    );
  },
);
