import { useLayoutEffect, useRef, useCallback } from "react";

export const useRedraw = ({ treeRef, showProductPictures, projectTree }) => {
  const redrawIndex = useRef(0);
  const setRedrawIndex = useCallback((index) => {
    redrawIndex.current = index;
  }, []);

  // This layout effect is used to redraw the list when the project tree is updated.
  useLayoutEffect(() => {
    const treeApi = treeRef.current;
    // Redraw logic
    if (!treeApi) {
      return;
    }
    treeApi.redrawList(redrawIndex.current);
    setRedrawIndex(0);
  }, [projectTree, setRedrawIndex, treeRef, showProductPictures]);

  return {
    setRedrawIndex,
  };
};
