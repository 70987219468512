import React from "react";
import { Icon } from "components/Icon/Icon";
import { Progress } from "reactstrap";

export const ProgressBar = ({ percentage }) => (
  <div
    className="position-absolute w-auto text-blue-300 d-flex flex-column"
    style={{ right: "2rem", top: "0" }}
  >
    <div>
      <Icon
        prefix="trox"
        iconName="arrow-down-bracket-light"
        className="me-2"
      />
      Processing for Download
    </div>
    <Progress
      value={percentage || 2}
      className="bg-gray-300 rounded-3 mt-1"
      color="info"
      style={{ height: "0.375rem" }}
    />
  </div>
);
