import { translate } from "./localsUtils";

export const translationHelpers = () => ({
  PROJECT_EDITED_BY: (fullName) =>
    translate("PROJECT_EDITED_BY", fullName || translate("UNKNOWN")),
  UNKNOWN: translate("UNKNOWN"),
  SHARED: translate("SHARED"),
  NOT_SHARED: translate("NOT_SHARED"),
  PROJECT_STATUS_UPDATE: translate("PROJECT_STATUS_UPDATE"),
  DOWNLOAD: translate("DOWNLOAD"),
  DUPLICATE: translate("DUPLICATE"),
  SHARE: translate("SHARE"),
  DELETE: translate("DELETE"),
  DETAILS: translate("DETAILS"),
  PROJECT_MODAL_DELETE_TEXT: translate("PROJECT_MODAL_DELETE_TEXT"),
  PROJECT_MODAL_DELETE_WARNING_TEXT_WITH_TITLE: (title) =>
    translate("PROJECT_MODAL_DELETE_WARNING_TEXT_WITH_TITLE", title),
  PROJECT_LIST_LAST_MODIFICATION: translate("PROJECT_LIST_LAST_MODIFICATION"),
  PROJECT_LIST_PROJECT: translate("PROJECT_LIST_PROJECT"),
  ERROR_OCCURED: translate("ERROR_OCCURED"),
  PROJECT_LIST_NO_DATA: translate("PROJECT_LIST_NO_DATA"),
  PROJECT_LIST_STATUS: translate("PROJECT_LIST_STATUS"),
  PROJECT_SHARING_SETTINGS: translate("PROJECT_SHARING_SETTINGS"),
});
