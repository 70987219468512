import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Input, InputGroup, InputGroupText } from "reactstrap";
import cx from "classnames";
import { useClickOutside } from "hooks";
import { debounce } from "lodash-es";
import { Icon } from "../Icon/Icon";

export const NumberInput = ({
  initialValue,
  onSave,
  allowOutsidePropagation = true,
  min = 0,
  max,
  disabled,
  onClickOutside = () => {},
  classNames,
  updateOnChange = false,
}) => {
  if (updateOnChange) {
    return (
      <NumberInputSaveOnChange
        initialValue={initialValue}
        onSave={onSave}
        min={min}
        max={max}
        disabled={disabled}
        classNames={classNames}
      />
    );
  }

  return (
    <NumberInputWithClickOutside
      initialValue={initialValue}
      onSave={onSave}
      allowOutsidePropagation={allowOutsidePropagation}
      min={min}
      max={max}
      disabled={disabled}
      onClickOutside={onClickOutside}
      classNames={classNames}
    />
  );
};

const NumberInputSaveOnChange = ({
  initialValue,
  onSave,
  min = 0,
  max,
  disabled,
  classNames,
}) => {
  const [inputValue, setInputValue] = useState(initialValue || 0);

  const handleMinusClick = useCallback(() => {
    setInputValue((val) => constrainValue(+val - 1, min, max));
  }, [max, min]);

  const handlePlusClick = useCallback(() => {
    setInputValue((val) => constrainValue(+val + 1, min, max));
  }, [max, min]);

  const handleChange = useCallback((e) => {
    setInputValue(e.target.value);
  }, []);

  const debounceOnSave = debounce(() => onSave(inputValue), 200);

  useEffect(() => {
    debounceOnSave();
    return () => debounceOnSave.cancel();
  }, [inputValue, max, min, debounceOnSave]);

  return (
    <InputComponent
      classNames={classNames}
      value={inputValue}
      min={min}
      max={max}
      disabled={disabled}
      handleMinusClick={handleMinusClick}
      handlePlusClick={handlePlusClick}
      handleChange={handleChange}
      onEnterPressed={(e) => e.target.blur()}
    />
  );
};

const NumberInputWithClickOutside = ({
  initialValue,
  onSave,
  allowOutsidePropagation = true,
  min = 0,
  max,
  disabled,
  onClickOutside = () => {},
  classNames,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef(null);
  const initialValueRef = useRef(initialValue); // Store initial value in a ref

  const [inputValue, setInputValue] = useState(initialValue || 0);

  const handlePlusClick = useCallback(() => {
    if (disabled) {
      return;
    }
    const valueToSave = constrainValue(+inputValue + 1, min, max);
    setInputValue(valueToSave);
  }, [disabled, inputValue, min, max]);

  const handleMinusClick = useCallback(() => {
    if (disabled) {
      return;
    }
    const valueToSave = constrainValue(+inputValue - 1, min, max);
    setInputValue(valueToSave);
  }, [disabled, inputValue, min, max]);

  const handleChange = useCallback((e) => {
    setInputValue(e.target.value);
  }, []);

  const handleSave = useCallback(() => {
    if (inputValue !== initialValueRef.current) {
      const valueToSave = constrainValue(+inputValue, min, max);
      setInputValue(valueToSave);
      onSave(valueToSave);
    }
    onClickOutside();
    setIsFocused(false);
  }, [inputValue, max, min, onSave, onClickOutside]);

  // Update initialValueRef if initialValue changes
  useEffect(() => {
    initialValueRef.current = initialValue;
  }, [initialValue]);

  const handleEnterPressed = useCallback(
    (e) => {
      if (e.key === "Enter") {
        handleSave();
      }
    },
    [handleSave],
  );

  useEffect(() => {
    document.addEventListener("keydown", handleEnterPressed);
    return () => document.removeEventListener("keydown", handleEnterPressed);
  }, [handleEnterPressed]);

  useClickOutside(inputRef, handleSave, isFocused, allowOutsidePropagation);

  return (
    <InputComponent
      ref={inputRef}
      classNames={classNames}
      value={inputValue}
      min={min}
      max={max}
      disabled={disabled}
      handleMinusClick={handleMinusClick}
      handlePlusClick={handlePlusClick}
      handleChange={handleChange}
      onBlur={(e) => !e.target.value && setInputValue(min)}
      onMouseEnter={() => setIsFocused(true)}
    />
  );
};

const InputComponent = forwardRef(
  (
    {
      min,
      max,
      value,
      disabled = false,
      classNames,
      onMouseEnter = () => {},
      handleMinusClick,
      handlePlusClick,
      handleChange = () => {},
      onBlur = () => {},
      onEnterPressed = () => {},
    },
    ref,
  ) => (
    <div ref={ref} className={classNames} onMouseEnter={onMouseEnter}>
      <InputGroup className="d-flex flex-nowrap">
        <InputGroupText
          className="g-gray-100 p-1"
          onClick={handleMinusClick}
          data-testid="-"
        >
          <Icon
            iconName="minus"
            className={cx("icon-12", {
              "cursor-not-allowed opacity-20 disabled":
                +min >= value || disabled,
            })}
          />
        </InputGroupText>
        <Input
          className={cx("rounded-0 text-center appearance-none fs-md p-0")}
          style={{ width: "40px", opacity: disabled ? 0.5 : 1 }}
          bsSize="sm"
          type="number"
          min={0}
          onChange={handleChange}
          disabled={disabled}
          value={value}
          data-testid="number-input"
          onKeyDown={(e) => e.key === "Enter" && onEnterPressed(e)}
          onBlur={onBlur}
        />
        <InputGroupText
          className="bg-gray-100 p-1"
          onClick={handlePlusClick}
          data-testid="+"
        >
          <Icon
            iconName="plus"
            className={cx("icon-12", {
              "cursor-not-allowed opacity-20 disabled":
                +max === value || disabled,
            })}
          />
        </InputGroupText>
      </InputGroup>
    </div>
  ),
);

const constrainValue = (value, min, max) => {
  const val = +value;
  if (isNaN(val)) {
    return min;
  }
  if (value < min) {
    return min;
  }
  if (max && value > max) {
    return max;
  }
  return value;
};
