import React, { useCallback, useRef, useState } from "react";
import { Tooltip } from "reactstrap";
import { formatDate, getCategoryLanguage, translate } from "utils";
import * as Scrivito from "scrivito";
import { SORTING_CRITERIA } from "variables";
import { VirtualizedModalTable } from "components/VirtualizedTable/VirtualizedModalTable";
import { ProductListTableRow } from "./ProductListTableRow";
import { SortButton } from "../../SortButton/SortButton";

export const ProductListTable = Scrivito.connect(
  ({
    products = [],
    isOrderDescending,
    setIsOrderDescending,
    skeletonCount,
    fetching,
    fetchingPrices,
    tableRef,
    commissionFetching,
    isLastPage,
    commissioningProduct,
    setSortCriteria,
    sortCriteria,
    setCurrentPageNo,
  }) => {
    const priceColRef = useRef(null);
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const toggleTooltip = useCallback(
      () => setTooltipOpen(!tooltipOpen),
      [tooltipOpen],
    );

    const currentLang = getCategoryLanguage();
    const currentData = formatDate(new Date(), "YYYY/MM");

    const columns = [
      {
        label: (
          <div className="d-flex justify-content-between">
            {translate("NAME")}
            <SortButton
              sortCriteria={SORTING_CRITERIA.NAME}
              currentSort={sortCriteria}
              setSortCriteria={setSortCriteria}
              isOrderDescending={isOrderDescending}
              setIsOrderDescending={setIsOrderDescending}
              setCurrentPageNo={setCurrentPageNo}
            />
          </div>
        ),
        width: "20%",
      },
      {
        label: (
          <div className="d-flex justify-content-between">
            {translate("PRESENTATION_ORDER_CODE")}
            <SortButton
              sortCriteria={SORTING_CRITERIA.PRESENTATION}
              currentSort={sortCriteria}
              setSortCriteria={setSortCriteria}
              isOrderDescending={isOrderDescending}
              setIsOrderDescending={setIsOrderDescending}
              setCurrentPageNo={setCurrentPageNo}
            />
          </div>
        ),
        width: "25%",
      },
      { label: translate("QUANTITY"), width: "10%" },
      { label: translate("UNIT_PRICE"), width: "15%" },
      { label: translate("TOTAL_PRICE"), width: "15%" },
      {
        label: (
          <>
            <span ref={priceColRef}>{translate("PRICE_GROUP")}</span>
            <Tooltip
              target={() => priceColRef.current}
              placement="top"
              isOpen={tooltipOpen}
              toggle={toggleTooltip}
            >
              {translate("PRODUCT_PRICE_LIST")}{" "}
              <span className="fw-normal text-gray-400">
                <span className="text-uppercase">{currentLang}</span>{" "}
                {currentData} {translate("PRODUCT_PRICE_WITHOUT_VAT")}
              </span>
            </Tooltip>
          </>
        ),
        width: "15%",
      },
    ];

    const renderRow = (product, fetchingPrice) => (
      <ProductListTableRow
        key={product.key}
        product={product}
        fetchingPrices={fetchingPrice}
      />
    );

    return (
      <VirtualizedModalTable
        columns={columns}
        products={products}
        renderRow={renderRow}
        fetchingProductList={fetching}
        skeletonCount={skeletonCount}
        isLastPage={isLastPage}
        commissioningProduct={commissioningProduct}
        commissionFetching={commissionFetching}
        tableRef={tableRef}
        fetchingPrices={fetchingPrices}
      />
    );
  },
);
