import { useCallback, useRef, useEffect } from "react";

export const useSmoothScroll = ({ treeRef, windowHeight, projectTree }) => {
  const scrollToIndex = useRef(null);
  const scrollOffsetRef = useRef(0);
  const setScrollToIndex = useCallback((index) => {
    scrollToIndex.current = index;
  }, []);

  const setScrollOffset = useCallback((offset) => {
    scrollOffsetRef.current = offset;
  }, []);

  useEffect(() => {
    if (scrollToIndex.current !== null) {
      const index = scrollToIndex.current;
      if (!treeRef?.current) {
        return;
      }

      const { list: listRef } = treeRef.current;
      if (!listRef || !listRef.current) {
        return;
      }

      const itemOffset = listRef?.current?._getItemStyle(index).top;
      const itemHeight = listRef?.current?._getItemStyle(index).height;

      const scrollOffset = scrollOffsetRef.current;
      if (
        itemOffset >= scrollOffset &&
        itemOffset + itemHeight <= scrollOffset + windowHeight
      ) {
        return;
      }

      const top = itemOffset + itemHeight - windowHeight / 2;

      if (top < 0) {
        return;
      }

      listRef.current._outerRef.scrollTo({
        top,
        behavior: "smooth",
      });
      scrollToIndex.current = null;
    }
  }, [windowHeight, projectTree, treeRef, scrollToIndex]);

  return {
    setScrollToIndex,
    setScrollOffset,
  };
};
