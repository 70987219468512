import { createContext, useContext } from "react";

export const SharedWorkerContext = createContext({
  messages: {},
  activeModal: null,
  setActiveModal() {},
  getMessageByNodeId: () => {},
  startPolling: () => {},
});

export const useSharedWorker = () => useContext(SharedWorkerContext);

export const pollingRequestStatuses = {
  inProgress: "inProgress",
  error: "InError",
  done: "Done",
};
