import {
  addNodeByParentId,
  findNodeById,
  updateNodeIds,
} from "api/cacheUtils/treeNodeUpdates/utils";
import { nodeTypes } from "variables";
import { mapChildren } from "./mapTree";

export const nodeCopy = (projectTree, { copiedFrom, ...result }, _args) => {
  let newNode = result;

  if (newNode.id.startsWith("temp_") && copiedFrom) {
    const sourceNode = findNodeById({
      nodes: projectTree,
      parentId: copiedFrom,
    });
    if (!sourceNode) {
      console.warn(structuredClone(projectTree));
      throw new Error(`Could not find node with id ${copiedFrom}`);
    }
    const sourceNodeClone = structuredClone(sourceNode);
    newNode = { ...sourceNodeClone, ...result };
    if (newNode.parentId && checkIsWizard(newNode)) {
      newNode = deWizardify(newNode);
    }
    if (newNode.nodes?.length > 0) {
      updateNodeIds({ nodes: newNode.nodes, parentId: newNode.id });
    }
  } else {
    newNode = mapChildren(newNode);
  }

  const { parentId, referencePosition } = newNode;

  addNodeByParentId({
    nodes: projectTree,
    parentId,
    newNode,
    referencePosition,
  });
  return projectTree;
};

const checkIsWizard = (node) => {
  const WIZARD_PREFIX = "wizard";
  const isWizard = node.kind.startsWith(WIZARD_PREFIX);
  return isWizard;
};

const deWizardify = (node) => ({
  ...node,
  isWizard: false,
  kind:
    node.kind === nodeTypes.CONFIGURATION
      ? nodeTypes.CONFIGURATION
      : nodeTypes.ANY,
  nodes: node.nodes && node.nodes.map((n) => deWizardify(n)),
});
