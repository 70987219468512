import React, { useMemo, useCallback, useState } from "react";
import { useMutation } from "urql";

import {
  formatPresentation,
  getProductData,
  getSelectedItems,
  handleMultipleFilesDownload,
  handleSingleFileDownload,
  translate,
} from "utils";
import { useAppBaseContext } from "providers";
import { useProjectContext } from "providers/ProjectProvider";
import {
  createProductMut,
  createProductReport,
  createProductXBIMReport,
} from "api/mutations";
import { Button } from "./Button";
import { Icon } from "./Icon/Icon";

export const ProductReportsDownloadButtonsBlock = ({
  itemsData,
  setItemsData,
  documents,
  orderCode,
  productDataFromProductConfigurator,
  requestParams,
}) => {
  const [reportsInProgress, setReportsInProgress] = useState(false);
  const { lang } = useAppBaseContext();
  const { units = requestParams.resultUnitsSystem } = useProjectContext();

  const [, exeCreateProduct] = useMutation(createProductMut);
  const [, executeCreateProductReport] = useMutation(createProductReport);
  const [, executeCreateProductXBIMReport] = useMutation(
    createProductXBIMReport,
  );

  const resetCheckedItems = itemsData.map((item) => ({
    ...item,
    checked: false,
    checkboxContent: item.checkboxContent
      ? item.checkboxContent.map((subItem) => ({
          ...subItem,
          checked: false,
        }))
      : item.checkboxContent,
  }));

  const handleUpdateAll = useCallback(
    (checked) => {
      setItemsData((prevData) =>
        prevData?.map((item) => {
          const newChecked = item.checkboxContent.length ? checked : false;
          const updatedSublist = item.checkboxContent
            ? item.checkboxContent.map((subItem) => ({
                ...subItem,
                checked: newChecked,
              }))
            : item.checkboxContent;

          return {
            ...item,
            checked: newChecked,
            checkboxContent: updatedSublist,
          };
        }),
      );
    },
    [setItemsData],
  );

  const handleSelectAll = useCallback(
    () => handleUpdateAll(true),
    [handleUpdateAll],
  );

  const handleClearAll = useCallback(
    () => handleUpdateAll(false),
    [handleUpdateAll],
  );

  const handleSubmit = useCallback(async () => {
    setReportsInProgress(true);

    const productData =
      productDataFromProductConfigurator ||
      (await getProductData(exeCreateProduct, orderCode, lang));
    const formattedPresentation = formatPresentation(
      productData.product.ordercode.presentation,
    );
    const selectedItems = getSelectedItems(itemsData);
    if (selectedItems.length === 1) {
      await handleSingleFileDownload(
        selectedItems[0],
        productData,
        formattedPresentation,
        units,
        lang,
        orderCode,
        executeCreateProductXBIMReport,
        executeCreateProductReport,
        documents,
        requestParams,
      );
    } else {
      await handleMultipleFilesDownload(
        selectedItems,
        productData,
        formattedPresentation,
        units,
        lang,
        orderCode,
        executeCreateProductXBIMReport,
        executeCreateProductReport,
        documents,
        requestParams,
      );
    }
    setReportsInProgress(false);
    setItemsData(resetCheckedItems);
  }, [
    exeCreateProduct,
    itemsData,
    lang,
    units,
    orderCode,
    resetCheckedItems,
    documents,
    executeCreateProductReport,
    executeCreateProductXBIMReport,
    setItemsData,
    requestParams,
    productDataFromProductConfigurator,
  ]);

  const checked = useMemo(
    () =>
      itemsData.reduce((acc, item) => {
        if (item.checked) {
          acc.push(item);
        }

        if (item.checkboxContent) {
          const checkedSublistItems = item.checkboxContent.filter(
            (subItem) => subItem.checked,
          );
          acc.push(...checkedSublistItems);
        }

        return acc;
      }, []),
    [itemsData],
  );

  return (
    <div className="d-flex justify-content-end">
      <Button
        data-testid="DownloadModal_select-all-btn"
        onClick={handleSelectAll}
        className="me-3"
        color="secondary"
        outline
      >
        {translate("SELECT_ALL")}
      </Button>
      <Button
        data-testid="DownloadModal_clear-selection-btn"
        onClick={handleClearAll}
        className="me-3"
        color="secondary"
        outline
        disabled={!checked.length}
      >
        {translate("CLEAR_SELECTION")}
      </Button>
      <Button
        data-testid="DownloadModal_download-btn"
        className="d-inline-block text-white"
        onClick={handleSubmit}
        color="primary"
        disabled={checked.length === 0 || reportsInProgress}
      >
        <Icon
          prefix="trox"
          iconName="arrow-down-bracket-regular"
          className="me-1"
        />
        {translate("DOWNLOAD_DATA")}
      </Button>
    </div>
  );
};
