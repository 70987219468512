import React, { memo } from "react";
import cx from "classnames";
import { NumberInput } from "components/Inputs/NumberInput";
import { IconButton } from "../Buttons/IconButton";
import s from "../Styles.module.scss";

export const ConfigurationControls = memo(
  ({
    isPasteMode,
    handleToggleEditMode,
    isEditMode,
    handleCopyNode,
    handleDelete,
    handleUpdate,
    openDownloadModal,
    options,
    isNodeOptimistic,
    translations,
    data,
    handleToggle3dView,
    onClickOutside,
  }) => (
    <div className="d-flex flex-row align-items-center justify-content-end">
      <Quantity
        isEditMode={isEditMode}
        handleToggleEditMode={handleToggleEditMode}
        quantity={data.quantity}
        translations={translations}
        onClickOutside={onClickOutside}
        handleUpdate={handleUpdate}
        options={options}
      />
      <div
        className={cx(
          "d-flex flex-row align-items-center justify-content-end flex-shrink-0",
          s.borderBetween,
        )}
      >
        {!options.copyNodeDisabled && (
          <IconButton
            id={`copyConfig${data.id}`}
            tooltipText={translations.folder.copyNode}
            testId="copyIcon"
            icon="copy-light"
            iconClassName="icon-16"
            onClick={handleCopyNode}
            className={cx("px-2 border-0", {
              "cursor-not-allowed opacity-20 pe-none": isPasteMode,
            })}
            disabled={isPasteMode}
          />
        )}
        {!options.ModelViewDisabled && (
          <IconButton
            id={`modelView${data.id}`}
            tooltipText={translations.product.modelView}
            testId="3dViewIcon"
            iconPrefix="trox"
            icon="3-d-symbol-regular"
            iconClassName="icon-16"
            onClick={handleToggle3dView}
            className={cx("px-2 border-0", {
              "cursor-not-allowed opacity-20 pe-none": isPasteMode,
            })}
            disabled={isPasteMode}
          />
        )}
        {!options.downloadModalDisabled && !data.isWizard && (
          <IconButton
            id={`downloadModal${data.id}`}
            tooltipText={translations.product.download}
            testId="downloadIcon"
            iconPrefix="trox"
            icon="arrow-down-bracket-light"
            iconClassName="icon-16"
            onClick={openDownloadModal}
            className={cx("px-2 border-0", {
              "cursor-not-allowed opacity-20 pe-none": isPasteMode,
            })}
            disabled={isPasteMode}
          />
        )}
        {!data.isWizard &&
          (!options.deleteNodeDisabled || options.productAddingModal) && (
            <IconButton
              id={`deleteConfig${data.id}`}
              tooltipText={translations.folder.deleteNode}
              testId="deleteIcon"
              icon="trash-can-light"
              iconClassName="icon-16"
              onClick={handleDelete}
              className={cx("px-2 border-0", {
                "cursor-not-allowed opacity-20 pe-none":
                  (isPasteMode && !options.productAddingModal) ||
                  isNodeOptimistic,
              })}
              disabled={
                (isPasteMode && !options.productAddingModal) || isNodeOptimistic
              }
            />
          )}
      </div>
    </div>
  ),
);

const Quantity = ({
  options,
  isEditMode,
  quantity,
  handleUpdate,
  translations,
  handleToggleEditMode,
  onClickOutside,
}) => {
  if (options.hideProductQuantity) {
    return <></>;
  }
  if (options.simpleQuantityView) {
    return <>{quantity}</>;
  }
  return (
    <div className="d-flex align-items-center me-6">
      <span onClick={handleToggleEditMode}>
        {translations.product.productQuantity}:
      </span>
      <div data-testid="product-quantity-input">
        {isEditMode ? (
          <NumberInput
            initialValue={quantity}
            onSave={handleUpdate}
            onClickOutside={onClickOutside}
            min={1}
          />
        ) : (
          quantity
        )}
      </div>
    </div>
  );
};
